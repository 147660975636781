
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
  TablePagination, TableRow, TableSortLabel,
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';




const headCells = [
  {
    id: 'ticketno',
    label: 'Ticket NO',
  },
  {
    id: 'site name',
    label: 'Site Name',
  },
  {
    id: 'report type',
    label: 'Report Type',
  },
  {
    id: 'report category',
    label: 'Report Category',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'state',
    label: 'State',
  },
  {
    id: 'Action',
    label: 'Action',
  },

]; function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead stickyHeader>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            sortDirection={orderBy === headCell.id ? order : false} >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function Site(props) {

  /*------------state-----------------*/


  const { SiteReport, filteredSiteReportData, tableData, handleTableRefresh,handleEditOpen,handleDownloadOpen } = props
  const [order, setOrder] = React.useState('asc');
  const [Reportvalue, setReportvalue] = useState(SiteReport)
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
 
  console.log(Reportvalue, 'Reportvalue');

  /*------------api call-----------------*/

  const sortData = (data, sortOrder, field) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];

      if (sortOrder === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else if (sortOrder === 'desc') {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
      return 0;
    });
    setReportvalue(sortedData);
  };



  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }



  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }


  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    sortData(Reportvalue, newOrder, property); // Use SiteReport here
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, (Reportvalue?.length || 0) - page * rowsPerPage);
  const [searchInput, setSearchInput] = useState('');


  return (

    <div >
      <div >

        <Box sx={{
          display: 'flex', flexWrap: 'wrap', '& > :not(style)': {
            mr: 0, mb: 2, mt: 2, width: '100%',
            minHeight: '7vh',
            maxHeight: 'max-content',
            m: 0,
            padding: '0'
          },
        }}>


        </Box >

        <div>

          <Box sx={{

            width: '100%', minHeight: '65vh',

            maxHeight: 'max-content',

          }}>



            <Paper sx={{ width: '100%', mb: 2, marginTop: "0.5%" }} elevation={0}>



              <TableContainer style={{ height: '65vh' }}>

                <Table

                  sx={{ minWidth: 750, }}

                  aria-labelledby="tableTitle"

                  stickyHeader aria-label="sticky table"

                >

                  <TableHead>

                    <TableRow>
                      <TableCell align="center">

                        <TableSortLabel

                          // active={orderBy === 'totalEnergy' ? order : 'desc'}

                          direction={orderBy === 'totalEnergy' ? order : 'asc'}

                          onClick={() => handleRequestSort('totalEnergy')} // Use 'status' here

                        >
                          Site Name
                        </TableSortLabel>
                      </TableCell>

                      <TableCell align="center">
                        <TableSortLabel

                          // active={orderBy === 'specificYiled' ? order : 'desc'}

                          direction={orderBy === 'specificYiled' ? order : 'asc'}

                          onClick={() => handleRequestSort('specificYiled')} // Use 'siteName' here

                        >

                          Report Name

                        </TableSortLabel>

                      </TableCell>



                      <TableCell align="center">
                        <TableSortLabel

                          // active={orderBy === 'lastDataReceived' ? order : 'desc'}

                          direction={orderBy === 'lastDataReceived' ? order : 'asc'}

                          onClick={() => handleRequestSort('lastDataReceived')} // Use 'status' here

                        >Email
                        </TableSortLabel>
                      </TableCell>
                      
                      <TableCell align="center">
                        <TableSortLabel

                          // active={orderBy === 'lastDataReceived' ? order : 'desc'}

                          direction={orderBy === 'lastDataReceived' ? order : 'asc'}

                          onClick={() => handleRequestSort('lastDataReceived')} // Use 'status' here

                        >Timeperiod
                        </TableSortLabel>
                      </TableCell>

                      <TableCell align="center">
                        <TableSortLabel
                          direction={orderBy === 'lastDataReceived' ? order : 'asc'}
                        >Status
                        </TableSortLabel>
                      </TableCell>
                    

                      <TableCell align="center">

                        <TableSortLabel

                          // active={orderBy === 'status' ? order : 'desc'}

                          direction={orderBy === 'status' ? order : 'asc'}

                          onClick={() => handleRequestSort('status')} // Use 'status' here

                        >

                          Action

                        </TableSortLabel>

                      </TableCell>

                    </TableRow>

                  </TableHead>



                  <TableBody>
                    {filteredSiteReportData
                      .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      .map((row, index) => (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.siteName)}
                          tabIndex={-1}
                          key={row.userid}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell
                            align="left"
                            style={{ cursor: "pointer", width: '20%', textDecoration: 'underline' }}

                          >
                            {row.siteName}
                          </TableCell>

                          <TableCell align="left" style={{ cursor: "default" }}>
                            {row.reportName}
                          </TableCell>

                          <TableCell align="left" style={{ cursor: "default" }}>
                            {row.mailId}
                          </TableCell>

                          <TableCell align="center" style={{ cursor: "default" }}>
                          {row.timePeriod}
                          </TableCell>

                          <TableCell align="center" style={{ cursor: "default" }}>
                          {row.activeFlag === 1 ? "Active" : row.activeFlag === 0 ? "Inactive" : null}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ cursor: "default", maxWidth: '100px', overflow: 'hidden' }}
                          >
                          <EditIcon onClick={() => handleEditOpen(row)} />

                            <FileDownloadOutlinedIcon onClick={()=>handleDownloadOpen(row)} />

                          </TableCell>

                        </TableRow>
                      ))}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                </Table>

              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredSiteReportData?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

            </Paper>

          </Box>

        </div>



      </div >
    </div >

  );

}