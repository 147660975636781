import React,{useMemo} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
const Chart = (props) => {
  const {tableValue}=props
  const getChartValue=useMemo(()=>{
    let chartValue={
        todayValue:[],
        totalValue:[],
        customerNaming:[]
        }
      tableValue && tableValue?.equipmentList?.forEach(value=>{
        chartValue={...chartValue,todayValue:[...chartValue?.todayValue,Number(value?.todayEnergy)],
           customerNaming:[...chartValue?.customerNaming,value?.customerNaming]
            }
             })
           return chartValue
        },[tableValue])
  const columnChartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Energy Generation (kWh)',
    },
    xAxis: {
        categories: getChartValue?.customerNaming,
    },
    yAxis: {
      title: {
        text: ' Energy Generation (kWh)',
      },
    },
    credits: {
         enabled: false 
       },
tooltip: {
  useHTML: true,
  formatter: function() {
    return `<div class="custom-tooltip">
              <p> ${this.point.category}</p>
              <p> Today Energy: ${this.y} (kWh)</p>
            </div>`;
  },
},
    series: [
      {
        data:getChartValue?.todayValue,
        showInLegend: false,
      },
    ],
  };
  console.log(getChartValue?.todayValue,'chart')
  return(
  <div>
    <HighchartsReact highcharts={Highcharts} options={columnChartOptions} />
  </div>
)
};
export default Chart;