import React, { useState, useEffect } from "react";
import "./SitesData.css";
import { Box, Grid } from "@mui/material";
import TotalSites from "./TotalSites";
import Alarms from "./Alarms";
import TotalEmission from "./TotalEmission";
import TodaysEnergy from "./TodaysEnergy";
import Skeleton from '@mui/material/Skeleton';

const SiteDataGrid = (props) => {
  const { SiteEnergy, SiteStatistics, loading } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
          minHeight: "20vh",
          maxHeight: "max-content",
          m: 0,
          padding: "0",
        },
      }}
    >
      <Grid container columnSpacing={1.5} rowSpacing={1.5}>
        {loading ? (

          <React.Fragment>
            <Skeleton variant="rectangular" width="23%" height={200} sx={{ marginTop: '1.3%' }} />
          </React.Fragment>
        ) : (
          <Grid item lg={3} md={6} xs={12}>
            <TotalSites SiteStatistics={SiteStatistics} />
          </Grid>)}
        {loading ? (

          <React.Fragment>
            <Skeleton variant="rectangular" width="23%" height={200} sx={{ marginTop: '1%',marginLeft:"1.3%" }} />
          </React.Fragment>
        ) : (
          <Grid item lg={3} md={6} xs={12}>
            <Alarms SiteEnergy={SiteEnergy} />
          </Grid>)}
        {loading ? (

          <React.Fragment>
            <Skeleton variant="rectangular" width="23%" height={200} sx={{ marginTop: '1%',marginLeft:"1.2%" }} />
          </React.Fragment>
        ) : (
          <Grid item lg={3} md={6} xs={12}>
            <TotalEmission SiteEnergy={SiteEnergy} />
          </Grid>)}
        {loading ? (
          <React.Fragment>
            <Skeleton variant="rectangular" width="24%" height={200} sx={{ marginTop: '1%',marginLeft:"1%" }} />
          </React.Fragment>
        ) : (
          <Grid item lg={3} md={6} xs={12}>
            <TodaysEnergy SiteEnergy={SiteEnergy} />
          </Grid>)}
      </Grid>
    </Box>
  );
};

export default SiteDataGrid;