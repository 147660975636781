import React, { useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import AccessibilityModule from 'highcharts/modules/accessibility';
import HighchartsBoost from 'highcharts/modules/boost';
import { fetchEquipmentIdDetails } from '../../../Api/ExcelUploadAPi';
HighchartsBoost(Highcharts);
AccessibilityModule(Highcharts);
Highcharts.time.useUTC = false;
const ApexChart = (props) => {
  const {
    tableData,
    chartName,
    widgetName,
    chartValue,
    range,
    multiChartData,
    name,
    timeValue,
    siteListAPI,
    equipmentListAPI,
  } = props;
  console.log(props, 'props');
  const [timeData, setTimedata] = React.useState([]);
  const [multiChartDatas, setMultichartData] = React.useState([]);
  const [paramter, setParamter] = React.useState([]);
  const [equipmentsData, setEquimentData] = React.useState([]);
  useEffect(() => {
    if (typeof Highcharts === 'object') {
      HighchartsExporting(Highcharts);
      HighchartsExportData(Highcharts);
    }
  }, []);
  const seriesData = [];
  const key = generateUUID();
  useEffect(() => {
    console.log(chartName, '', range);
    if (chartName === 'Energy Performance' && range === 'daily') {
      chartValue?.map(async (data) => {
        const equipmentMap = {};
        let equipments = equipmentListAPI;
        equipments.forEach((equipment) => {
          equipmentMap[equipment.equipmentid] = equipment.customernaming;
        });
        const seriesData = [];
        equipments.forEach((equipment) => {
          const series = {
            name: equipment.customernaming,
            data: [],
          };
          const equipmentData = data['energyPerformanceChartData'].filter(
            (data) => data.equipmentId === equipment.equipmentid
          );
          equipmentData.forEach((data) => {
            const timestamp = new Date(data.timestamp).getTime();
            const value = data.todayEnergy !== null ? data.todayEnergy : 0;
            series.data.push([timestamp, value]);
          });
          seriesData.push(series);
        });
        let finalArray = seriesData?.filter((value) => {
          return value.data.length > 0;
        });
        setMultichartData(finalArray);
        setTimedata(tableData);
      });
    } else if (chartName === 'Parameter Comparision' && range === 'daily') {
      console.log(chartName, range);
      let equipments;

      chartValue?.map(async (data) => {
        equipments = equipmentListAPI;
        const dataFields = chartValue[0].measureParameter;
        const seriesData = [];
        dataFields.forEach((field) => {
          chartValue[0].parameterList.forEach((data) => {
            let equipmentName = equipments?.filter((dataValue) => {
              return dataValue.equipmentid === Number(data.equipmentid);
            });
            const timestamp = new Date(data.timestamp).getTime();
            const value = data[field] !== null ? Number(data[field]) : 0;

            const seriesName = `${equipmentName[0]?.customernaming} - ${field}`;
            const existingSeries = seriesData.find(
              (series) => series.name === seriesName
            );
            if (existingSeries) {
              existingSeries.data.push([timestamp, value]);
            } else {
              const newSeries = {
                name: seriesName,
                data: [[timestamp, value]],
              };

              seriesData.push(newSeries);
              let seriesDataFilter = seriesData.filter((obj) => {
                return !obj.data.every(([_, value]) => isNaN(value));
              });
              // setMultichartData(seriesDataFilter);
              seriesDataFilter.sort((a, b) => a.name.localeCompare(b.name));
              console.log(seriesDataFilter, 'seriesDataFilter');
              setParamter(seriesDataFilter);
              setTimedata(tableData);
            }
          });
        });
      });
    } else if (name === 'paramter' || name === 'chartData') {
      if (chartName === 'Parameter Comparision') {
        console.log(chartName, name);
        setParamter(multiChartData);
        setTimedata(tableData);
      } else if (name === 'chartData') {
        setMultichartData(multiChartData);
        setTimedata(tableData);
      } else {
        setTimedata(tableData);
      }
    } else if (chartName === 'Daily Generation' && range === 'daily') {
      let seriesData = [];
      const irradiationSeries = {
        name: 'irradiation',
        data: [],
      };
      const todayEnergySeries = {
        name: 'todayenergy',
        data: [],
      };
      tableData?.forEach((dataPoint) => {
        const timestamp = new Date(dataPoint.timestamp).getTime();
        irradiationSeries.data.push([timestamp, dataPoint.irradiation || 0]);
        todayEnergySeries.data.push([timestamp, dataPoint.todayEnergy || 0]);
      });
      seriesData.push(irradiationSeries);
      seriesData.push(todayEnergySeries);
      setMultichartData(seriesData);
      setTimedata(tableData);
    }
  }, [tableData, chartName, range]);
  // const dailyGenChartData = useMemo(() => {
  //   if (!timeData) return [];
  //   return timeData.map((item) => ({
  //     x: new Date(item.timestamp),
  //     y: item.todayEnergy !== null ? parseFloat(item.todayEnergy) : null,
  //   }));
  // }, [timeData]);

  const dailyGenChartData = useMemo(() => {
    if (!timeData) return [];
    return multiChartDatas;
  }, [multiChartDatas]);
  const energyPerformChartData = useMemo(() => {
    if (!timeData) return [];
    return multiChartDatas;
  }, [multiChartDatas]);
  const parameterComparisonData = useMemo(() => {
    if (!timeData) return [];
    return paramter;
  }, [paramter]);
  const isEnergyGenerationVisible =
    chartName === 'Energy Performance' || chartName === 'Daily Generation';
  const isDailyIrradiationVisible =
    (chartName === 'Daily Generation' || chartName === 'Energy Performance') &&
    dailyGenChartData.some((series) => series.name.includes('Irradiation'));
  const isActivePowerVisible =
    chartName === 'Parameter Comparision' &&
    paramter.some((series) => series.name.includes('ActivePower'));
  const isIrradiationVisible =
    chartName === 'Parameter Comparision' &&
    paramter.some((series) => series.name.includes('Irradiation'));
  const isVoltageVisible =
    chartName === 'Parameter Comparision' &&
    paramter.some((series) => series.name.includes('Voltage'));
  const isCurrentVisible =
    chartName === 'Parameter Comparision' &&
    paramter.some((series) => series.name.includes('Current'));
  const isTemperatureVisible =
    chartName === 'Parameter Comparision' &&
    paramter.some((series) => series.name.includes('ModuleTemperature'));
  const isPowerfactorVisible =
    chartName === 'Parameter Comparision' &&
    paramter.some((series) => series.name.includes('PowerFactor'));
  const isIsolationVisible =
    chartName === 'Parameter Comparision' &&
    paramter.some((series) => series.name.includes('Isolationresistance'));
  const isReactivePowerVisible =
    chartName === 'Parameter Comparision' &&
    paramter.some((series) => series.name.includes('ReactivePower'));
  const isTodayVisible =
    chartName === 'Parameter Comparision' &&
    paramter.some((series) => series.name.includes('TodayEnergy'));
  const isTotalVisible =
    chartName === 'Parameter Comparision' &&
    paramter.some((series) => series.name.includes('TotalEnergy'));
  const isFrequencyVisible =
    chartName === 'Parameter Comparision' &&
    paramter.some((series) => series.name.includes('Frequency'));
  const isInputPower =
    chartName === 'Parameter Comparision' &&
    paramter.some((series) => series.name.includes('InputPower'));

  //   if(chartName === "Parameter Comparision" ) {
  //     return  [
  //       {
  //         title: {
  //           text: "Active Power (W)",
  //           style: {
  //             color: "#666666",
  //             fontSize: "0.8em",
  //             fontFamily: "Inter",
  //           },
  //         },
  //         opposite: false,
  //       },
  //       {
  //         min: 0,
  //         max:1000,
  //         title: {
  //           text: "Irradiation (Wh/m2)",
  //           style: {
  //             color: "#666666",
  //             fontSize: "0.8em",
  //             fontFamily: "Inter",
  //           },
  //         },
  //         opposite: true,
  //       },
  //     ]
  //   } else {
  //     return {
  //       min: 0,
  //       title: {
  //         text:  "Energy Generation (kWh)",
  //         style: {
  //           color: "#666666",
  //           fontSize: "0.8em",
  //           fontFamily: "Inter",
  //         },
  //       },
  //     }
  //   }
  // });
  function getUnit(parameterName) {
    switch (true) {
      case parameterName.includes('InputCurrent'):
        return 'A';
      case parameterName.includes('InputVoltage'):
        return 'V';
      case parameterName.includes('InputPower'):
        return 'W';
      case parameterName.includes('InputEnergy'):
        return 'kWh';
      case parameterName.includes('PhaseCurrent'):
        return 'A';
      case parameterName.includes('PhaseVoltage'):
        return 'V';
      case parameterName.includes('ApparentPower'):
        return 'kVA';
      case parameterName.includes('ActivePower'):
        return 'kW';
      case parameterName.includes('ReactivePower'):
        return 'kVar';
      case parameterName.includes('PowerFactor'):
        return '';
      case parameterName.includes('TodayEnergy'):
        return 'kWh';
      case parameterName.includes('TotalEnergy'):
        return 'kWh';
      case parameterName.includes('IsolationResistance'):
        return 'Ω';
      case parameterName.includes('OutputFrequency'):
        return 'Hz';
      case parameterName.includes('AmbientTemperature'):
      case parameterName.includes('ModuleTemperature'):
      case parameterName.includes('InverterTemperature'):
      case parameterName.includes('HeatSinkTemp'):
      case parameterName.includes('SMBTemparature'):
        return 'ºC or K';
      case parameterName.includes('WindSpeed'):
        return 'm/s';
      case parameterName.includes('Rainfall'):
        return 'mm';
      case parameterName.includes('TotalHoursOn'):
      case parameterName.includes('TodayHoursOn'):
        return 'HH:MM:SS';
      case parameterName.includes('PhasePowerBalancer'):
        return '';
      case parameterName.includes('DifferentialCurrent'):
        return 'mA';
      case parameterName.includes('Status'):
      case parameterName.includes('ErrorCode'):
        return '';
      case parameterName.includes('PhaseToPhaseVoltage'):
        return 'V';
      case parameterName.includes('SerialNumber'):
        return '';
      case parameterName.includes('ACBreakerCount'):
      case parameterName.includes('DCBreakerCount'):
        return '';
      case parameterName.includes('ExportTotalEnergy'):
      case parameterName.includes('ImportTotalEnergy'):
      case parameterName.includes('ExportActiveEvergy'):
      case parameterName.includes('ImportActiveEnergy'):
      case parameterName.includes('MonthlyEnergy'):
        return 'kWh';
      case parameterName.includes('ReactiveEnergyLAG'):
      case parameterName.includes('ReactiveEnergyLEAD'):
        return 'kVAR';
      case parameterName.includes('WindDirection'):
        return '°';
      case parameterName.includes('Irradiance'):
      case parameterName.includes('GlobalIrradiance'):
        return 'W/m²';
      case parameterName.includes('GlobalHorizantalIrradiation'):
      case parameterName.includes('Irradiation'):
        return 'Wh/m²';
      case parameterName.includes('Humidity'):
        return '%';
      case parameterName.includes('TrackerAngle'):
      case parameterName.includes('SunAngle'):
        return 'º';
      case parameterName.includes('Batteryvoltage'):
        return 'V';
      case parameterName.includes('StartRoboPosition'):
      case parameterName.includes('MidRoboPosition'):
      case parameterName.includes('EndRoboPosition'):
        return '';
      case parameterName.includes('PhaseFrequency'):
        return 'Hz';
      case parameterName.includes('PhasePower'):
        return 'kW';
      case parameterName.includes('PhaseEnergy'):
        return 'kWh';
      case parameterName.includes('Total Specific Yeild'):
        return 'Rs';
      case parameterName.includes('CO2Avioded'):
        return 'T';
      case parameterName.includes('Import/Export Status'):
        return '0 or 1';
      case parameterName.includes('SunRiseTime'):
      case parameterName.includes('SunSetTime'):
        return 'HH:MM';
      case parameterName.includes('TrackerMode'):
      case parameterName.includes('TrackerState'):
      case parameterName.includes('TrackerStatus'):
        return '';
      default:
        return '';
    }
  }
  const minMaxTimeStamp = useMemo(() => {
    if (!timeData || timeData.length === 0) return {};

    let minTimestamp = Infinity;
    let maxTimestamp = -Infinity;

    timeData.forEach((item) => {
      const timestamp = new Date(item.timestamp).getTime();
      minTimestamp = Math.min(minTimestamp, timestamp);
      maxTimestamp = Math.max(maxTimestamp, timestamp);
    });

    minTimestamp -= 30 * 60 * 1000;

    return { minTimestamp, maxTimestamp };
  }, [timeData]);

  let data =
    chartName === 'Parameter Comparision' &&
    paramter.map((series) => {
      return series.name.includes('irradiation');
    });

  const options = {
    chart: {
      zoomType: 'x',
    },
    boost: {
      useGPUTranslations: true,
      // Chart-level boost when there are more than 1 series in the chart
      seriesThreshold: 1,
    },

    accessibility: {
      enabled: true,
    },
    title: {
      text: widgetName !== undefined ? widgetName : '',
      style: {
        textTransform: 'captalize',

        fontWeight: 'normal',
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'separator',
            'downloadPNG',
            'downloadSVG',
            'downloadPDF',
            'separator',
            'downloadCSV',
            'downloadXLS',
          ],
        },
      },
      enabled: true,
      csv: {
        itemDelimiter: ',',
        lineDelimiter: '\n',
        beforeExport: function (e) {
          const yAxisTitle = 'Y-Axis';
          e.csv = `Time,${yAxisTitle}\n${e.csv}`;
        },
      },
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        // hour: "%H:%M",
        day: '%Y-%m-%d',
      },
      min: minMaxTimeStamp.minTimestamp - 60 * 60 * 1000,
      max: minMaxTimeStamp.maxTimestamp + 60 * 60 * 1000,
      tickInterval: 60 * 60 * 1000,
      labels: {
        rotation: -45,
        formatter: function () {
          const localDate = new Date(this.value);
          const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            day: 'numeric',
            month: 'short',
          };
          return new Intl.DateTimeFormat('en-US', options).format(localDate);
        },
      },
      title: {
        text: 'Time (HH:MM)',
        style: {
          color: '#666666',
          // fontSize: "0.8em",
          fontFamily: 'Inter',
        },
      },
    },
    // yAxis: getChartYAxis,
    yAxis: [
      {
        //0
        title: {
          text: 'Energy  Generation (kWh)',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        visible: isEnergyGenerationVisible,
      },
      {
        //1
        title: {
          text: 'Active Power (kW)',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        opposite: false,
        visible: isActivePowerVisible,
      },
      {
        //2
        // min: 0,
        // max: 1000,
        title: {
          text: 'Irradiation (Wh/m2)',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        opposite: true,
        visible: isIrradiationVisible,
      },
      {
        //3
        min: 0,
        // max: 500,
        title: {
          text: 'Voltage (v) ',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        opposite: false,
        visible: isVoltageVisible,
        yAxis: {
          tickInterval: 100,
        },
      },
      {
        //4
        // min: 0,
        // max: 50,
        title: {
          text: 'Current (I) ',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        opposite: true,
        visible: isCurrentVisible,
      },
      {
        //5
        // min: 0,
        // max: 100,
        title: {
          text: 'Temperature (°C) ',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        opposite: false,
        visible: isTemperatureVisible,
      },
      {
        //6 powerfactor
        min: 0,
        max: 3,
        title: {
          text: 'Power Factor ',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        opposite: true,
        visible: isPowerfactorVisible,
      },
      {
        //7 isolationResistance
        // min: 25000,
        title: {
          text: 'Isolation Resistance (ohm) ',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        opposite: false,
        visible: isIsolationVisible,
      },
      {
        //8 reactivepower
        // min: 100,
        title: {
          text: 'Reactive Power ',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        opposite: true,
        visible: isReactivePowerVisible,
      },
      {
        //9 todayenergy
        title: {
          text: 'Today Energy (kWh) ',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        opposite: false,
        visible: isTodayVisible,
      },
      {
        //10 totalenergy
        min: 2500,
        title: {
          text: 'Total Energy (MWh) ',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        opposite: true,
        visible: isTotalVisible,
      },
      {
        //11 output frequency
        title: {
          text: 'Output Frequency (Hz)',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        opposite: false,
        visible: isFrequencyVisible,
      },
      {
        //12

        title: {
          text: 'Irradiation (Wh/m2)',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        min: 0,
        opposite: true,
        visible: isDailyIrradiationVisible,
      },
      {
        //13

        title: {
          text: ' Power (W)',
          style: {
            color: '#666666',
            fontSize: '0.8em',
            fontFamily: 'Inter',
          },
        },
        opposite: true,
        visible: isInputPower,
      },
    ],
    legend: {
      maxHeight: 100,
      navigation: {
        enabled: true,
        animation: true,
        arrowSize: 11,
        style: {
          fontWeight: 'bold',
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false, // Disable markers for all series
        },
      },
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get('rgba'),
            ],
          ],
        },
        marker: {
          radius: 0,

          enabled: false,
        },
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
    tooltip: {
      useHTML: true,
      shared: true,
      formatter: function () {
        const options = {
          weekday: 'long',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        };

        function getColorIndicator(color) {
          return `<span style="width: 10px; height: 10px; display: inline-block; background-color: ${color}; margin-right: 5px;"></span>`;
        }

        const tooltipHeader = `<div style="font-size: 12px;"><b>${new Intl.DateTimeFormat(
          'en-US',
          options
        ).format(this.x)}</b></div>`;
        let tooltipContent = '';

        this.points.forEach((point) => {
          if (!Number.isNaN(point.y)) {
            tooltipContent += `<div style="font-size: 14px;">${getColorIndicator(
              point.series.color
            )}${point.series.name}: ${point.y} </div>`;
          }
        });

        // Unique ID for the tooltip container
        const tooltipContainerId = 'custom-tooltip-container';

        // Disable document overflow scrolling initially
        document.body.style.overflow = 'hidden';

        const apexChart = document.getElementById(
          `apexChart-${widgetName}-${key}`
        );
        if (apexChart) {
          apexChart.addEventListener('mouseout', (event) => {
            if (!apexChart.contains(event.relatedTarget)) {
              // Enable document overflow scrolling
              document.body.style.overflow = '';
            }
          });
        }

        // Ensure only one scroll listener is attached
        if (!document.hasScrollListener) {
          document.hasScrollListener = true;
          document.addEventListener('wheel', (event) => {
            const tooltipContainer =
              document.getElementById(tooltipContainerId);
            if (tooltipContainer) {
              tooltipContainer.scrollTop += event.deltaY;
              event.preventDefault();
            }
          });
        }

        return `<div id="${tooltipContainerId}" style="max-height: 180px; min-width: 180px; overflow-y: auto; pointer-events: auto;">${
          tooltipHeader + tooltipContent
        }</div>`;
      },
      style: {
        pointerEvents: 'auto',
      },
    },

    navigation: {
      buttonOptions: {
        align: 'right',
        verticalAlign: 'top',
        y: 0,
      },
    },
    credits: {
      enabled: false,
    },
    series:
      chartName === 'Energy Performance'
        ? energyPerformChartData?.map((series) => {
            series.yAxis = 0;
            series.boostThreshold = 1;
            return series;
          })
        : chartName === 'Parameter Comparision'
        ? paramter !== undefined
          ? paramter.map((series) => {
              if (series.name.includes('Irradiation')) {
                series.yAxis = 2;

                return series;
              } else if (series.name.includes('Voltage')) {
                series.yAxis = 3;
                return series;
              } else if (series.name.includes('Current')) {
                series.yAxis = 4;
                return series;
              } else if (series.name.includes('Temperature')) {
                series.yAxis = 5;
                return series;
              } else if (series.name.includes('PowerFactor')) {
                series.yAxis = 6;
                return series;
              } else if (series.name.includes('Isolationresistance')) {
                series.yAxis = 7;
                return series;
              } else if (series.name.includes('ReactivePower')) {
                series.yAxis = 8;
                return series;
              } else if (series.name.includes('TodayEnergy')) {
                series.yAxis = 9;
                return series;
              } else if (series.name.includes('TotalEnergy')) {
                series.yAxis = 10;
                return series;
              } else if (series.name.includes('Frequency')) {
                series.yAxis = 11;
                return series;
              } else if (series.name.includes('InputPower')) {
                series.yAxis = 13;
                return series;
              } else {
                series.yAxis = 1;
                series.boostThreshold = 1;
                return series;
              }
              // return series;
            })
          : seriesData
        : chartName === 'Daily Generation'
        ? dailyGenChartData?.map((series) => {
            if (
              series.name.includes('todayenergy') ||
              series.name.includes('Today Energy')
            ) {
              series.name = 'Today Energy';
              series.yAxis = 0;
            } else if (
              series.name.includes('irradiation') ||
              series.name.includes('Irradiation')
            ) {
              series.name = 'Irradiation';
              series.boostThreshold = 1;
              series.yAxis = 12;
            }
            return series;
          })
        : '',
  };
  console.log(options.series, 'series');

  function generateUUID() {
    // Generate random numbers and place them in the correct positions
    const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return template.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0; // Generate a random number between 0 and 15
      const v = c === 'x' ? r : (r & 0x3) | 0x8; // Use 'r' for 'x' and a modified version for 'y'
      return v.toString(16); // Convert to hexadecimal
    });
  }

  return (
    <div id={`apexChart-${widgetName}-${key}`}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        type="area"
        width="100%"
        sx={{
          '.highcharts-xaxis-labels, .highcharts-yaxis-labels, .highcharts-scale':
            {
              visibility: 'visible !important',
            },
        }}
      />
    </div>
  );
};
export default ApexChart;
