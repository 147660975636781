import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material/';
import { useNavigate} from 'react-router';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { fetchEquipmentlistTable } from "../../Api/EquipmentApi"
import { useLocation } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";

export default function Equipment() {
    const [value, setvalue] = useState([])
    const [page, setPage] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [loading,setloading]=useState(true)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [cardValue, setCardValue] = useState()
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [dense] = React.useState(false);
    const Navigate = useNavigate()
    const location = useLocation();
    const handlebackicon = () => {
        Navigate('/menu/SiteOverView')
    }
    useEffect(() => {
        fetchvalue();
    }, [])
    console.log(location, "location");
    const fetchvalue = async () => {
        try {
            const data = await fetchEquipmentlistTable(location?.state?.id)
            console.log(location, "location");
            setCardValue(data)
            setvalue(data?.eventList);
            setloading(false);
        } catch (e) {
            console.log(e)
        }
    }
    console.log(value, "value")
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const sortData = (data, sortOrder, field) => {
        // Sort the data based on the specified field and sortOrder
        const sortedData = [...data];
        // Sort the copy based on the specified field and sortOrder
        sortedData.sort((a, b) => {
            const aValue = a[field];
            const bValue = b[field];
            if (sortOrder === 'asc') {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else if (sortOrder === 'desc') {
                return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
            }
            return 0;
        });
        setvalue(sortedData);
    };
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);
        sortData(value, newOrder, property); // Use siteEnergy here
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, (value?.length || 0) - page * rowsPerPage);
    return (
        <div>
        {loading ?(
        
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20%" }}><FadeLoader color="#000440" loading={true} /></div>
        ):(
      
        <div>
            <div>
                
            </div>
            <div>
                <Typography variant="h5"> <KeyboardBackspaceIcon style={{ verticalAlign: 'middle', fontSize: '1.7rem' }} onClick={handlebackicon} /> Equipment </Typography>
            </div>
            <div>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            width: "100%",
                            height: "17vh",
                        },
                    }}
                >
                    <Paper elevation={0} >
                        <div>
                            <Grid container columns={16} style={{ padding: "1%", marginTop: "0.5vh" }}>
                                <Grid item xs={3.2}>
                                    <div style={{ fontSize: '1rem', opacity: '0.7' }}>Components  :</div>
                                    <div style={{ fontSize: '1.2rem', }}>{cardValue?.components}</div>
                                    <div style={{ fontSize: '1rem', opacity: '0.7', marginTop: "2vh" }}>Capacity (kW)    : </div>
                                    <div style={{ fontSize: '1.2rem', }}>{cardValue?.capacity}</div>
                                </Grid>
                                <Grid item xs={3.2}>
                                    <div style={{ fontSize: '1rem', opacity: '0.7' }}>Warantry Expire        : </div>
                                    <div style={{ fontSize: '1.2rem', }}>{cardValue?.warantryExpire}</div>
                                    <div style={{ fontSize: '1rem', opacity: '0.7', marginTop: "2vh" }}>Customer Reference  :</div>
                                    <div style={{ fontSize: '1.2rem', }}>{cardValue?.customerReference}</div>
                                </Grid>
                                <Grid item xs={3.2}>
                                    <div style={{ fontSize: '1rem', opacity: '0.7' }}>Equipment category  :</div>
                                    <div style={{ fontSize: '1.2rem', }}>{cardValue?.equipmentCategory}</div>
                                    <div style={{ fontSize: '1rem', opacity: '0.7', marginTop: "2vh" }}>Disconnect Type     :</div>
                                    <div style={{ fontSize: '1.2rem', }}>{cardValue?.disconnectType}</div>
                                </Grid>
                                <Grid item xs={3.2}>
                                    <div style={{ fontSize: '1rem', opacity: '0.7' }}>Equipment Type    : </div>
                                    <div style={{ fontSize: '1.2rem', }}>{cardValue?.equipmentType}</div>
                                    <div style={{ fontSize: '1rem', opacity: '0.7', marginTop: "2vh" }}>Disconnect Rating :</div>
                                    <div style={{ fontSize: '1.2rem', }}>{cardValue?.disconnectType}</div>
                                </Grid>
                                <Grid item xs={3.2}>
                                    <div style={{ fontSize: '1rem', opacity: '0.7' }}>Installation Date :</div>
                                    <div style={{ fontSize: '1.2rem', }}>{cardValue?.installationDate}</div>
                                    <div style={{ fontSize: '1rem', opacity: '0.7', marginTop: "2vh" }}>Description :</div>
                                    <div style={{ fontSize: '1.2rem', }}>{cardValue?.description}</div>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper >
                </Box>
            </div>
            <div>
                <Paper sx={{ width: '99%', mb: 2, marginLeft: "1vh" }} elevation={0}>
                    <TableContainer style={{ height: '65vh' }}>
                        <Table
                            sx={{ minWidth: 750, }}
                            aria-labelledby="tableTitle"
                            stickyHeader aria-label="sticky table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"  >
                                        <TableSortLabel
                                            active={orderBy === 'errorCode'}
                                            direction={orderBy === 'errorCode' ? order : 'asc'}
                                            onClick={() => handleRequestSort('errorCode')} // Use 'siteName' here
                                        >Error Code</TableSortLabel></TableCell>
                                    <TableCell align="center">
                                        {/* <TableSortLabel
                                            active={orderBy === 'customerNaming'}
                                            direction={orderBy === 'customerNaming' ? order : 'asc'}
                                            onClick={() => handleRequestSort('customerNaming')} // Use 'siteName' here
                                        >
                                             </TableSortLabel> */}
                                            
                                            Equipment Name 
                                        
                                       </TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            active={orderBy === 'errorMessage'}
                                            direction={orderBy === 'errorMessage' ? order : 'asc'}
                                            onClick={() => handleRequestSort('errorMessage')} // Use 'siteName' here
                                        >Error message</TableSortLabel></TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            active={orderBy === 'eventOccurrence'}
                                            direction={orderBy === 'eventOccurrence' ? order : 'asc'}
                                            onClick={() => handleRequestSort('eventOccurrence')} // Use 'siteName' here
                                        > Occurence</TableSortLabel></TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            active={orderBy === 'siteName'}
                                            direction={orderBy === 'siteName' ? order : 'asc'}
                                            onClick={() => handleRequestSort('siteName')} // Use 'siteName' here
                                        >Site Name</TableSortLabel></TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            active={orderBy === 'priority'}
                                            direction={orderBy === 'priority' ? order : 'asc'}
                                            onClick={() => handleRequestSort('priority')} // Use 'siteName' here
                                        >Priority</TableSortLabel></TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            active={orderBy === 'capacity'}
                                            direction={orderBy === 'capacity' ? order : 'asc'}
                                            onClick={() => handleRequestSort('capacity')} // Use 'siteName' here
                                        >Capacity</TableSortLabel></TableCell>
                                    {/* 
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={orderBy === 'errorCode'}
                                            direction={orderBy === 'errorCode' ? order : 'asc'}
                                            onClick={() => handleRequestSort('errorCode')} // Use 'siteName' here
                                        >Action</TableSortLabel></TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {value !== undefined
                                    ? value
                                        ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage) // Display rows based on pagination
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.siteName)}
                                                    tabIndex={-1}
                                                    key={row.siteName}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell align="center">{row.errorCode}</TableCell>
                                                    <TableCell align="center">{row.customerNaming}</TableCell>
                                                    <TableCell align="center">{row.errorMessage}</TableCell>
                                                    <TableCell align="center">{row.eventOccurrence}</TableCell>
                                                    <TableCell align="center">{row.siteName}</TableCell>
                                                    <TableCell align="center">{row.priority}</TableCell>
                                                    <TableCell align="center">{row.capacity}</TableCell>
                                                </TableRow>
                                            );
                                        }) : ""}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={value?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </div>)}
        </div>
    )
}