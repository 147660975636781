import { Card, Grid, Typography } from '@mui/material';

export const CustomList = ({ data, limit, status }) => {
  // Check if data is null or undefined
  if (!data || !Array.isArray(data) || data.length === 0) {
    return (
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px',
        }}
      >
        {status ? 'The equipment is offline' : 'No data available'}
      </Card>
    );
  }

  const [start, end] = limit;

  const slicedData = data.slice(start, end);

  return (
    <>
      <Card>
        {slicedData.map((item, index) => {
          const stringKey = Object.keys(item)[0];
          const data = item[stringKey];
          const inputCurrentKey = Object.keys(data).find((key) =>
            key.startsWith('inputCurrent')
          );
          const inputVoltageKey = Object.keys(data).find((key) =>
            key.startsWith('inputVoltage')
          );
          const inputPowerKey = Object.keys(data).find((key) =>
            key.startsWith('inputPower')
          );
          const downKey = Object.keys(data).find((key) =>
            key.startsWith('down')
          );
          const inputCurrent = data[inputCurrentKey];
          const inputVoltage = data[inputVoltageKey];
          const inputPower = data[inputPowerKey];
          const down = data[downKey];
          const style = { color: down ? 'red' : null };
          return (
            <Grid
              key={index}
              container
              spacing={2}
              columns={28}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                padding: '10px',
              }}
            >
              {index === 0 && (
                <>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={7}>
                    Current
                    <span>(A)</span>
                  </Grid>
                  <Grid item xs={7}>
                    Voltage
                    <span>(V)</span>
                  </Grid>
                  <Grid item xs={7}>
                    Power
                    <span>(W)</span>
                  </Grid>
                </>
              )}
              <Grid item xs={7} style={style}>
                <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                  String-{stringKey.slice(-2)}:
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ ...style }}>
                {inputCurrent}
              </Grid>
              <Grid item xs={6} style={style}>
                {inputVoltage}
              </Grid>
              <Grid item xs={6} style={style}>
                {inputPower}
              </Grid>
            </Grid>
          );
        })}
      </Card>
    </>
  );
};
