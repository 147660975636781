import React, { useEffect, useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import './SiteStyle.css';
//---------------------mui---------------------//
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  Button,
  TableContainer,
  TableHead,
  FormControlLabel,
  TablePagination,
  TableRow,
  Modal,
  TableSortLabel,
  Typography,
  CircularProgress,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import Switch from '@mui/material/Switch';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { Accordian } from '../util/Accordian';
import { ScbParams } from '../../Api/SiteListApi';

const style = {
  position: 'absolute',
  top: '64%',
  center: '50%',
  transform: 'translate(-50%, -50%)',
  width: '17%',
  height: '40vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(4),
}));
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}
const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3, 9),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];
const headCells = [
  {
    id: 'name',
    label: 'Site Name',
  },
  {
    id: 'name',
    label: 'Site Id',
  },
  {
    id: 'calories',
    label: 'Status',
  },
  {
    id: 'fat',
    label: 'Today Energy (kWh)',
  },
  {
    id: 'carbs',
    label: 'Equipment Type',
  },
  {
    id: 'protein',
    label: 'Status',
  },
  {
    id: 'protein',
    label: 'Today Energy',
  },
  {
    id: 'protein',
    label: 'Total Energy',
  },
  {
    id: 'protein',
    label: 'Last Data Received ',
  },
];
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead stickyHeader>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
export default function ScbTable(props) {
  const { tableValue, isModalOpen, toggleModal } = props;
  console.log(tableValue);
  const Navigate = useNavigate();
  const [filteredTableValue, setfilteredTableValue] = useState(tableValue);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('asc');
  const [siteEnergy, setSiteEnergy] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('');
  const [dense, setDense] = React.useState(false);
  const [sortedData, setSortedData] = useState();
  const [sortColumn, setSortColumn] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [ascending, setAscending] = useState(true);
  const [timeDifference, setTimeDifference] = useState(0);
  const [status, setStatus] = useState('');
  const handlebackicon = () => {
    Navigate('/menu/SiteOverView');
  };
  const [eqipmentDown, setEquipmentDown] = useState({
    id: '',
    state: false,
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(4),
  }));
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const defaultLoadingStates = {
    components: true,
    equipmentCode: true,
    timeStamp: true,
    primarySerialNumber: true,
    customerNaming: true,
    equipmentId: true,
    status: true,
  };
  const [customerNameLoading, setEquipmentNameLoading] = useState(
    defaultLoadingStates.customerNaming
  );
  const [PrimaryserialnumberLoading, setSerailNoLoading] = useState(
    defaultLoadingStates.primarySerialNumber
  );
  const [equipmentcodeLoading, setEquipmentTypeLoading] = useState(
    defaultLoadingStates.equipmentCode
  );
  const [capacityLoading, setcapacityLoading] = useState(
    defaultLoadingStates.capacityLoading
  );
  const [timestampLoading, setLastDataReceivedLoading] = useState(
    defaultLoadingStates.timeStamp
  );
  const [statusLoading, setStatusLoading] = useState(
    defaultLoadingStates.status
  );
  const [expanded, setExpanded] = useState({ open: false, id: '' });

  const handleExpand = async (id) => {
    setExpanded({
      open: expanded.id === id ? !expanded.open : true,
      id: id,
    });
  };

  const handleToggle = () => {
    const filteredValues = tableValue?.filter((row) => {
      return (
        (customerNameLoading && row.customerNaming) ||
        (PrimaryserialnumberLoading && row.primarySerialNumber) ||
        (equipmentcodeLoading && row.equipmentCode) ||
        (capacityLoading && row.capacityLoading) ||
        (timestampLoading && row.timeStamp) ||
        (statusLoading && row.status)
      );
    });
    setfilteredTableValue(filteredValues);
  };
  useEffect(() => {
    resetToDefaultColumns();
    setRowsPerPage(10);
  }, []);
  const resetToDefaultColumns = useCallback(() => {
    setEquipmentNameLoading(defaultLoadingStates.customerNaming);
    setSerailNoLoading(defaultLoadingStates.primarySerialNumber);
    setEquipmentTypeLoading(defaultLoadingStates.equipmentCode);
    setcapacityLoading(defaultLoadingStates.components);
    setLastDataReceivedLoading(defaultLoadingStates.timeStamp);
    setStatusLoading(defaultLoadingStates.status);
    handleToggle();
  }, [defaultLoadingStates]);
  const handleClickEvent = (rowid) => {
    console.log(rowid);
    Navigate('/menu/equipment', { state: { id: rowid } });
  };
  /*-------------sorting--------------*/
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    sortData(tableValue, newOrder, property);
  };
  const sortData = (data, sortOrder, field) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];
      if (sortOrder === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else if (sortOrder === 'desc') {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
      return 0;
    });
    setfilteredTableValue(sortedData);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.alerts);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  function checkNullOrUndefined(value) {
    if (value === null || value === undefined) {
      return 0;
    } else {
      return value;
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function getStatusLabel(networkStatus) {
    console.log(typeof networkStatus, 'networkStatus');
    switch (networkStatus) {
      case '1':
        console.log(networkStatus, 'networkStatus');
        return 'Active';
      case '0':
        console.log(networkStatus, 'networkStatus');
        return 'Offline';
      // case '2':
      //   return 'Warning';
      case '3':
        return 'Down';
      default:
        console.log(networkStatus, 'networkStatus');
        return 'Unknown';
    }
  }
  function getStatusIcon(status) {
    switch (status) {
      case '1':
        console.log(status, 'status');
        return '#0bdf2a';
      case '0':
        console.log(status, 'status');
        return 'grey';
      // case '2':
      //   return 'orange';
      case '3':
        return 'red';
      default:
        console.log(status, 'status');
        return 'black';
    }
  }

  let lastCallTime = null;

  function getStatus(time) {
    console.log(time);
    console.log(time);

    if (!time) {
      console.log('No time value given');
      return 'offline';
    }

    const functionTimeSet = (time) => {
      const inputDate = `${time}`;
      const [dayMonthYear, hoursMinutes] = inputDate.split(' ');
      const [day, month, year] = dayMonthYear
        .split('-')
        .map((item) => parseInt(item));
      const [hours, minutes] = hoursMinutes
        .split(':')
        .map((item) => parseInt(item));
      const dateInUTC = new Date(
        Date.UTC(year, month - 1, day, hours, minutes)
      );
      const formattedDate = dateInUTC.toISOString().slice(0, 16);
      return formattedDate;
    };

    const functionTime2 = () => {
      let currentDate = new Date();
      var year = currentDate.getFullYear();
      var month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
      var day = ('0' + currentDate.getDate()).slice(-2);
      var hours = ('0' + currentDate.getHours()).slice(-2);
      var minutes = ('0' + currentDate.getMinutes()).slice(-2);
      var seconds = ('0' + currentDate.getSeconds()).slice(-2);
      // Construct the desired format
      var formattedDate =
        year + '-' + month + '-' + day + 'T' + hours + ':' + minutes;
      return formattedDate;
    };

    let timegetvalue = functionTimeSet(time);
    let currentTime = functionTime2();

    var time1 = new Date(timegetvalue);
    var time2 = lastCallTime ? lastCallTime : new Date(currentTime);

    console.log(time1, time2);

    var differenceMs;
    if (time1 > time2) {
      // Future timeStamp, treat as "online"
      differenceMs = 0;
    } else {
      // Past timeStamp, calculate the difference
      differenceMs = time2 - time1;
    }

    // Convert milliseconds to minutes
    var differenceMinutes = Math.floor(differenceMs / (1000 * 60));

    // Display the difference
    console.log(differenceMinutes, 'minutes');

    // Update the lastCallTime
    lastCallTime = new Date(currentTime);

    if (differenceMinutes <= 30) {
      console.log('online');
      return 'online';
    } else {
      console.log('offline');
      return 'offline';
    }
  }

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (filteredTableValue?.length || 0) - page * rowsPerPage
    );

  function extractAndConvert(data, key) {
    let result = {};

    data.forEach((obj) => {
      if (obj.equipmentId !== null && typeof obj.equipmentId !== 'undefined') {
        result[obj.equipmentId] = obj[key] || {};
      }
    });

    return result;
  }

  function fetchDataAndUpdate(obj, setResult) {
    console.log(obj);
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const data = obj[key];
        const isDown = Object.values(data).some((value) => value === 0);
        data.down = isDown;
        setResult((prevState) => ({
          ...prevState,
          [key]: data,
        }));
      }
    }
  }

  function processTableValues(tableValues, setResult) {
    const data = extractAndConvert(tableValues, 'scbStringValue');
    fetchDataAndUpdate(data, setResult);
  }

  // Example of usage with React state
  const [apiResults, setApiResults] = useState({});
  console.log(apiResults);
  useEffect(() => {
    setfilteredTableValue(tableValue);
    processTableValues(tableValue, setApiResults);
  }, [tableValue]);

  console.log('Results of API calls:', apiResults);

  function isIdPresent(object, id) {
    return object.hasOwnProperty(id);
  }

  function extractAndSortString(data) {
    // Filter input entries
    const inputEntries = Object.entries(data)
      .filter(([key, value]) => key.startsWith('inputCurrent'))
      .map(([key, value]) => ({ key, value }));

    // Filter voltage entries
    const voltageEntries = Object.entries(data)
      .filter(([key, value]) => key.startsWith('inputVoltage'))
      .map(([key, value]) => ({ key, value }));

    // Sort input entries
    inputEntries.sort((a, b) => {
      const aIndex = parseInt(a.key.split('_')[1]);
      const bIndex = parseInt(b.key.split('_')[1]);
      return aIndex - bIndex;
    });

    let usedVoltageValue = null;

    // If only one voltage value is present and the rest are null, use that for all power calculations
    const nonNullVoltageEntries = voltageEntries.filter(
      ({ value }) => value !== null
    );
    if (nonNullVoltageEntries.length === 1) {
      usedVoltageValue = nonNullVoltageEntries[0].value;
    }

    // Create the sorted string entries
    const sortedString = inputEntries.map(({ key, value }, index) => {
      const inputKey = key;
      const voltageKey =
        usedVoltageValue !== null
          ? voltageEntries[0].key
          : voltageEntries[index].key;
      const inputValue = value;
      const voltageValue =
        usedVoltageValue !== null && inputValue !== 0
          ? usedVoltageValue
          : voltageEntries[index].value;
      const inputPower = `inputPower_${index + 1}`;
      const result = (inputValue * voltageValue).toFixed(2);
      console.log(inputValue, voltageValue, result, 'power');
      const stringKey = `String_${index < 9 ? '0' : ''}${index + 1}`;

      return {
        [stringKey]: {
          [inputKey]: inputValue == null ? '    -' : inputValue,
          [voltageKey]: voltageValue == null ? 0 : voltageValue,
          [inputPower]: result,
          down: inputValue === 0 || voltageValue === 0 ? true : false,
        },
      };
    });

    return sortedString;
  }

  function isAnyDown(obj, index) {
    const data = extractAndSortString(obj);
    // Loop through each object until the given index
    for (let i = 0; i <= index; i++) {
      // Get the keys of the object at index i
      let keys = Object.keys(data[i]);

      // Loop through each key in the object
      for (let key of keys) {
        // Check if the down value is true
        if (data[i][key].down === true) {
          return true;
        }
      }
    }
    return false;
  }

  return (
    <div>
      <div>
        <div>
          <div>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={0}>
              <TableContainer sx={{ height: '50vh' }} key={eqipmentDown.id}>
                <Table
                  // sx={{ minWidth: 750, }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      {customerNameLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            direction={
                              orderBy === 'customerNaming' ? order : 'asc'
                            }
                            onClick={() => handleRequestSort('customerNaming')}
                          >
                            Equipment Name
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {PrimaryserialnumberLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            direction={
                              orderBy === 'primarySerialNumber' ? order : 'asc'
                            }
                            onClick={() =>
                              handleRequestSort('primarySerialNumber')
                            }
                          >
                            Serial No
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {equipmentcodeLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            direction={
                              orderBy === 'equipmentCode' ? order : 'asc'
                            }
                            onClick={() => handleRequestSort('equipmentCode')}
                          >
                            Equipment Code
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {capacityLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            direction={orderBy === 'components' ? order : 'asc'}
                            onClick={() => handleRequestSort('components')}
                          >
                            Components
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {timestampLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            direction={orderBy === 'timeStamp' ? order : 'asc'}
                            onClick={() => handleRequestSort('timeStamp')}
                          >
                            Last Updated
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {statusLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            direction={
                              orderBy === 'networkStatus' ? order : 'asc'
                            }
                            onClick={() => handleRequestSort('networkStatus')}
                          >
                            Status
                          </TableSortLabel>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTableValue
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      // .map((index) => (
                      .map((row, index) => (
                        <>
                          <TableRow
                            key={row.siteName}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            {customerNameLoading && (
                              <TableCell align="center">
                                <span
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  onClick={() =>
                                    !isIdPresent(apiResults, row.equipmentId) &&
                                    row.equipmentId != null
                                      ? null
                                      : handleExpand(row.customerNaming)
                                  }
                                >
                                  {expanded.open &&
                                  row.customerNaming === expanded.id ? (
                                    <KeyboardArrowUpOutlinedIcon
                                      style={{
                                        padding: '0',
                                        marginRight: '2px',
                                      }}
                                    />
                                  ) : !isIdPresent(
                                      apiResults,
                                      row.equipmentId
                                    ) && row.equipmentId != null ? (
                                    <div>
                                      <KeyboardArrowDownOutlinedIcon
                                        style={{
                                          padding: '0',
                                          marginRight: '2px',
                                          color: 'darkgrey',
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <KeyboardArrowDownOutlinedIcon
                                      style={{
                                        padding: '0',
                                        marginRight: '2px',
                                      }}
                                    />
                                  )}
                                  {row.customerNaming}
                                </span>
                              </TableCell>
                            )}
                            {PrimaryserialnumberLoading && (
                              <TableCell align="center">
                                {' '}
                                {row.primarySerialNumber}
                              </TableCell>
                            )}
                            {equipmentcodeLoading && (
                              <TableCell align="center">
                                {row.equipmentCode}
                              </TableCell>
                            )}
                            {capacityLoading && (
                              <TableCell align="center">
                                {row.components}
                              </TableCell>
                            )}
                            {timestampLoading && (
                              <TableCell align="center">
                                {row.timeStamp}
                              </TableCell>
                            )}
                            {/* {statusLoading && <TableCell align="center" style={{ cursor: "default", maxWidth: '100px', overflow: 'hidden' }}>
                                                        <span style={{
                                                            backgroundColor: timeDifference <= 30 ? getStatusIcon('0') : getStatusIcon('1'),
                                                            color: 'white',
                                                            padding: '5px 10px',
                                                            borderRadius: '50px',
                                                            display: 'inline-block',
                                                            cursor: 'default',
                                                            whiteSpace: 'nowrap'
                                                        }}>   
                                                            <span style={{ color: 'black' }}>{timeDifference <= 30 ? getStatusLabel('0') : getStatusLabel('1')}</span>
                                                            </span>
                                                    </TableCell>} */}
                            {statusLoading && (
                              <TableCell
                                align="center"
                                style={{
                                  cursor: 'default',
                                  maxWidth: '100px',
                                  overflow: 'hidden',
                                }}
                              >
                                {!isIdPresent(apiResults, row.equipmentId) &&
                                row.equipmentId != null ? (
                                  <div>
                                    <CircularProgress
                                      size={15}
                                      sx={{ marginRight: 2 }}
                                    />
                                  </div>
                                ) : (
                                  <span
                                    style={{
                                      backgroundColor:
                                        getStatus(row.timeStamp) === 'online' &&
                                        isAnyDown(
                                          apiResults[row.equipmentId],
                                          Number(row.components) - 1
                                        ) === true
                                          ? getStatusIcon('3')
                                          : getStatus(row.timeStamp) ===
                                              'online' &&
                                            isAnyDown(
                                              apiResults[row.equipmentId],
                                              Number(row.components) - 1
                                            ) === false
                                          ? getStatusIcon('1')
                                          : getStatusIcon('0'),
                                      color: 'white',
                                      padding: '5px 10px',
                                      borderRadius: '50px',
                                      display: 'inline-block',
                                      cursor: 'default',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    <span style={{ color: 'white' }}>
                                      {/* {timeDifference <= 30 ? getStatusLabel('0') : 'Offline'} */}
                                      {getStatus(row.timeStamp) === 'online' &&
                                      isAnyDown(
                                        apiResults[row.equipmentId],
                                        Number(row.components) - 1
                                      ) === true
                                        ? getStatusLabel('3')
                                        : getStatus(row.timeStamp) ===
                                            'online' &&
                                          isAnyDown(
                                            apiResults[row.equipmentId],
                                            Number(row.components) - 1
                                          ) === false
                                        ? getStatusLabel('1')
                                        : getStatusLabel('0')}
                                    </span>
                                  </span>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                          {expanded.open &&
                          expanded.id === row.customerNaming ? (
                            <TableRow
                              sx={{
                                backgroundColor: '#F2F1EB',
                              }}
                            >
                              <TableCell colSpan={6}>
                                <Accordian
                                  data={
                                    extractAndSortString(
                                      apiResults[row.equipmentId]
                                    ) || {}
                                  }
                                  count={row.components}
                                  statusDown={
                                    !isIdPresent(apiResults, row.equipmentId)
                                      ? true
                                      : false
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ) : null}
                        </>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableValue?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
        <div>
          <Modal
            open={isModalOpen}
            onClose={toggleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Paper
              elevation={0}
              className="total-equip"
              sx={{
                width: '19%',
                maxWidth: '250px',
                height: 'max-content',
                overflow: 'hidden',
                backgroundColor: 'aliceblue',
                padding: '10px',
                position: 'relative',
                marginLeft: '77%',
                marginTop: '20.5%',
              }}
            >
              <div>
                <Grid container spacing={10}>
                  <Grid item xs={6} md={14}>
                    <Typography
                      style={{
                        color: 'rgb(18, 127, 216)',
                        marginLeft: '80%',
                        marginTop: '4%',
                        cursor: 'pointer',
                      }}
                      onClick={toggleModal}
                    >
                      <CloseOutlinedIcon />
                    </Typography>
                    {customerNameLoading ? (
                      <FormControlLabel
                        sx={{
                          display: 'block',
                        }}
                        control={
                          <Switch
                            checked={customerNameLoading}
                            onChange={() =>
                              setEquipmentNameLoading(!customerNameLoading)
                            }
                            color="primary"
                            disabled
                          />
                        }
                        label="Equipment Name"
                      />
                    ) : (
                      <FormControlLabel
                        sx={{
                          display: 'block',
                        }}
                        control={<Switch color="primary" />}
                        label="Equipment Name"
                      />
                    )}
                    <FormControlLabel
                      sx={{
                        display: 'block',
                      }}
                      control={
                        <Switch
                          checked={PrimaryserialnumberLoading}
                          onChange={() =>
                            setSerailNoLoading(!PrimaryserialnumberLoading)
                          }
                          color="primary"
                        />
                      }
                      label="Serial No"
                    />
                    <FormControlLabel
                      sx={{
                        display: 'block',
                      }}
                      control={
                        <Switch
                          checked={equipmentcodeLoading}
                          onChange={() =>
                            setEquipmentTypeLoading(!equipmentcodeLoading)
                          }
                          color="primary"
                        />
                      }
                      label="Equipment Type"
                    />

                    <FormControlLabel
                      sx={{
                        display: 'block',
                      }}
                      control={
                        <Switch
                          checked={capacityLoading}
                          onChange={() => setcapacityLoading(!capacityLoading)}
                          color="primary"
                        />
                      }
                      label="Capacity"
                    />

                    <FormControlLabel
                      sx={{
                        display: 'block',
                      }}
                      control={
                        <Switch
                          checked={equipmentcodeLoading}
                          onChange={() =>
                            setLastDataReceivedLoading(!equipmentcodeLoading)
                          }
                          color="primary"
                        />
                      }
                      label=" Last Data Received"
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Modal>
        </div>
      </div>
    </div>
  );
}
