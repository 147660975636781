import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  InputAdornment,
  Modal,
  Fade,
  Stack,
  Autocomplete,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Reporttable from './Reporttable';
import { useNavigate } from 'react-router';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';

// ========Modal================
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import moment from 'moment';

/*------------api call-----------------*/
import {
  fetchSiteDetails,
  putReportupdateEdit,
  fetchReportTable,
  postreportdata,
  fetchReportnamelist,
  DownloadReport,
} from '../../Api/ReportApi';

const isValidEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

export default function Reporthome() {
  /*------------state-----------------*/
  const Navigate = useNavigate();
  const [Createreport, setCreatereport] = useState(false);
  const [Refresh, setRefresh] = useState(false);

  /*------------ modal state-----------------*/
  const [Cancel, setCancel] = useState();
  const [Save, setSave] = useState();
  const [Run, setRun] = useState();
  const [timevalue, setTimeValue] = useState('');
  const [siteIdName, setSiteId] = useState([]);
  const [reportIdName, setreportId] = useState([]);
  const [site, setSite] = React.useState('');
  const [customFromDate, setFromDate] = useState('');
  const [customToDate, setToDate] = useState('');
  const [SiteReport, setSiteReport] = useState([]);
  const [activeFlag, setactiveFlag] = useState();
  const [timePeriod, settimePeriod] = useState();
  const [range, setrange] = useState();
  const [editcall, seteditcall] = useState();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [email, setEmail] = useState(sessionStorage.getItem('email'));
  const [validEmail, setValidEmail] = useState(true);
  const [reportData, setReportData] = useState();
  const [downloadPopup, setDownloadPopup] = useState(false);
  const [minToDate, setMinToDate] = useState([]);
  const [fromDownloadDate, setFromDownloadDate] = useState('');
  const [toDownloadDate, setToDownloadDate] = useState('');
  const [selectedFromDate, setSelectedFromDate] = useState('');
  const [selectedToDate, setSelectedToDate] = useState('');
  const [downloadSiteName, setDownloadSiteName] = useState('');
  const [downloadSiteId, setDownloadsiteID] = useState('');
  const [siteNameEmpty, setSiteNameEmpty] = useState(false);
  const [reportNameEmpty, setReportNameEmpty] = useState(false);
  const [timePeriodEmpty, settimePeriodEmpty] = useState(false);
  const [validEmailEmpty, setvalidEmailEmpty] = useState(false);
  const [activeFlagEmpty, setactiveFlagEmpty] = useState(false);

  const [updatecall, setupdatecall] = useState({
    siteName: '',
    reportName: '',
    email: '',
    activeFlag: '',
    timePeriod: '',
  });

  const currentURL = window.location.href;
  const urlObject = new URL(currentURL);
  const domainName = urlObject.hostname;
  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    getSiteCall();
    getreportlist();
  }, []);
  const dayjs = require('dayjs');
  /*------------------------- handle Update --------------------------------------------*/
  const handleUpdate = async () => {
    let siteName = site !== '' ? site : editcall?.siteName;
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    let id = editcall.reportMapId;
    let obj = {
      siteId: siteIdFilter[0]?.siteId,
      userId: userId,
      mailId: email !== '' ? email : editcall?.email,
      timePeriod: timePeriod !== undefined ? timePeriod : editcall?.timePeriod,
      activeFlag: activeFlag !== undefined ? activeFlag : editcall?.activeFlag,
      reportId: editcall?.reportId,
    };
    // console.log(obj);
    let responsedata = await putReportupdateEdit(obj, id);

    if (responsedata.status === 200) {
      fetchData();
      setOpen(false);
    }
  };
  const isCustomValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#\$%\^&\*]/;
    const letterRegex = /[a-zA-Z]/;

    return (
      emailRegex.test(email) &&
      numberRegex.test(email) &&
      specialCharRegex.test(email) &&
      letterRegex.test(email)
    );
  };

  /*------------------------- handle save --------------------------------------------*/
  const handleSave = async (e) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });
    let reportIdFilter = reportIdName?.filter((data) => {
      return data.reportName === reportData;
    });

    // Check if siteName is empty
    if (!site) {
      setSiteNameEmpty(true);
      return;
    }
    if (!reportData) {
      setReportNameEmpty(true);
      return;
    }
    if (!timePeriod) {
      settimePeriodEmpty(true);
      return;
    }
    if (!validEmail) {
      setvalidEmailEmpty(true);
      return;
    }
    if (!activeFlag) {
      setactiveFlagEmpty(true);
      return;
    }
    let data = {
      userId: sessionStorage.getItem('id'),
      timePeriod: timePeriod,
      activeFlag: activeFlag,
      mailId: email,
      siteId: siteIdFilter[0]?.siteId,
      reportId: reportIdFilter[0]?.reportId,
      domainName: domainName,
    };

    const isDataEmpty = Object.values(data).some((value) => !value);

    if (isDataEmpty) {
      window.alert(
        'Some data fields are empty. Please fill in all required fields.'
      );
      return;
    }
    // console.log(data);
    const responseData = await postreportdata(data);
    // console.log(responseData);
    try {
      if (responseData.status === 200) {
        fetchData();
        setOpen(false);
      }
    } catch (e) {
      console.log('error:', e.message);
    }
  };
  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    try {
      const data = await fetchReportTable();
      const reverse = [...data].reverse();
      setSiteReport(reverse);
    } catch (e) {
      console.error(e);
    }
  };
  /*------------------------- Site Name list dropdown --------------------------------------------*/
  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem('id');
      let data = await fetchSiteDetails(id);
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };
  /*-------------------------report Name list dropdown --------------------------------------------*/
  const getreportlist = async () => {
    try {
      let data = await fetchReportnamelist();
      setreportId(data);
    } catch (e) {
      console.error(e);
    }
  };
  const userId = sessionStorage.getItem('id');

  const handleactiveFlag = (data, event) => {
    setactiveFlag(event?.value);
  };
  const handletimePeriod = (data, event) => {
    if (event !== null) {
      settimePeriod(event.value);
    }
  };

  /*---------------------------------- New Report Call -----------------------*/
  const handleCrate = () => {
    setCreatereport(true);
    setOpen(true);
    setEdit(false);
    seteditcall();
  };
  const handleRefresh = () => {
    setRefresh(true);
    setEdit(false);
    setCreatereport(false);
    window.location.reload();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '40%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  };

  const Type = [{ label: 'Generation', value: 'generation' }];
  const status = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];
  const optionTime = [
    // { label: "Last 7 days", value: "last 7 days" },
    { label: 'Today', value: 'Today' },
    { label: 'Yesterday', value: 'Yesterday' },
    { label: 'Last Week', value: 'Weekly' },
    { label: 'Last Month', value: 'Monthly' },
    { label: 'Custom Range', value: 'custom' },
  ];

  function filterOptions(reportName) {
    if (reportName === 'Daily Generation Report') {
      return optionTime.filter(
        (option) =>
          option.value === 'Today' ||
          option.value === 'Yesterday' ||
          option.value === 'custom'
      );
    } else {
      return optionTime.filter(
        (option) =>
          option.value === 'Weekly' ||
          option.value === 'Monthly' ||
          option.value === 'custom'
      );
    }
  }

  useEffect(() => {
    let date = new Date();
    let fromDate = new Date();
    let responseDate = '';
    let toDate = '';
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };

    switch (timePeriod) {
      case 'Today':
        fromDate.setDate(date.getDate());
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        setFromDownloadDate(responseDate);
        setToDownloadDate(toDate);
        break;
      case 'Yesterday':
        fromDate.setDate(date.getDate() - 1);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        setFromDownloadDate(responseDate);
        setToDownloadDate(toDate);
        break;
      case 'Weekly':
        fromDate.setDate(date.getDate() - 6);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD  ');
        setFromDownloadDate(responseDate);
        setToDownloadDate(toDate);
        break;
      case 'Monthly':
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        setFromDownloadDate(formatDate(firstDayOfLastMonth));
        setToDownloadDate(formatDate(lastDayOfLastMonth));
        break;
      default:
    }
  }, [timePeriod]);

  const functionDate = (inputValue) => {
    console.log(inputValue, 'todatefun');
    let date = new Date();
    let fromDate = new Date();
    let toDateFormat = new Date();
    let responseDate = '';
    let toDate = '';
    let dateFormat = {
      initalDate: '',
      finalDate: '',
    };
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };

    switch (inputValue) {
      case 'Today':
        fromDate.setDate(date.getDate());
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        dateFormat.initalDate = formatDate(fromDate);
        dateFormat.finalDate = formatDate(fromDate);
        break;
      case 'Yesterday':
        fromDate.setDate(date.getDate() - 1);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        dateFormat.initalDate = formatDate(fromDate);
        dateFormat.finalDate = formatDate(fromDate);
        break;
      case 'Weekly':
        fromDate.setDate(date.getDate() - date.getDay() - 6);
        const firstDayOfPastWeek = new Date(fromDate);
        toDateFormat.setDate(date.getDate() - date.getDay());
        const lastDayOfPastWeek = new Date(toDateFormat);
        const formattedFirstDay = formatDate(firstDayOfPastWeek);
        const formattedLastDay = formatDate(lastDayOfPastWeek);
        dateFormat.initalDate = formattedFirstDay;
        dateFormat.finalDate = formattedLastDay;
        break;

      case 'Monthly':
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        setFromDownloadDate(formatDate(firstDayOfLastMonth));
        setToDownloadDate(formatDate(lastDayOfLastMonth));
        dateFormat.initalDate = formatDate(firstDayOfLastMonth);
        dateFormat.finalDate = formatDate(lastDayOfLastMonth);
        break;
      default:
    }

    return dateFormat;
  };

  const handleRun = () => {
    setSave(false);
    setRun(true);
    setCancel(false);
  };

  const Item = styled(Paper)(({ theme }) => ({}));

  const handleEditOpen = async (reportMapId) => {
    seteditcall(reportMapId);
    setEdit(true);
    // setbutton(true)
    setOpen(true);
  };

  const handleDownloadOpen = async (data) => {
    console.log(data, 'report>>>>>>>>>>>>>>>>');
    settimePeriod(timePeriod);
    let dates = functionDate(data.timePeriod);
    console.log(dates, 'date');
    let PostData = {
      siteId: data?.siteId,
      fromDate: dates?.initalDate,
      toDate: dates?.finalDate,
      domainId: data?.domainId,
    };
    console.log(PostData, 'PostData');
    try {
      const responseData = await DownloadReport(PostData);
      // console.log(PostData);
      setDownloadPopup(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDownloadReport = async () => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });
    let PostData = {
      siteId: siteIdFilter[0]?.siteId,
      fromDate: customFromDate,
      toDate:
        reportData === 'Asset Management Report' ? minToDate : customFromDate,
      domainId:
        domainName === 'webdyn.eira.io'
          ? 1
          : domainName === 'age.eira.io'
          ? 2
          : 3,
    };

    console.log(PostData, 'PostData');
    try {
      const responseData = await DownloadReport(PostData);
      setOpen(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSite = (data, val) => {
    if (val === null) {
      setSite('');
    } else {
      setSite(val);
    }
  };

  const handleReport = (data, value) => {
    if (value === null) {
      setReportData('');
    } else {
      setReportData(value);
    }
  };

  // ========customrange=========
  const handleChangeFromDate = (data, value) => {
    if (data !== null) {
      const formattedDatetime = data.format('YYYY-MM-DD');
      setFromDate(formattedDatetime);
      console.log(formattedDatetime, 'setFromDate ');
    }
  };
  useEffect(() => {
    setMinToDate(customFromDate);
    console.log(customFromDate);
  }, [customFromDate]);
  const handleChangeToDate = (data, value) => {
    if (data !== null) {
      const formattedDatetime = data.format('YYYY-MM-DD');
      setMinToDate(formattedDatetime);
      console.log(formattedDatetime, 'setFromDate ');
    }
  };
  const filterAlign = {
    visibility:
      timePeriod === 'custom' && reportData === 'Asset Management Report'
        ? 'visible'
        : 'hidden',
  };
  const filterAlignDaily = {
    visibility: timePeriod === 'custom' ? 'visible' : 'hidden',
  };

  const downloadENableIcon = {
    visibility: timePeriod !== 'custom' ? 'hidden' : 'visible',
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setValidEmail(isValidEmail(newEmail));
  };
  const handleEmailChangevalue = (e) => {
    setEmail(e.target.value);
    const newEmail = e.target.value;
    setEmail(newEmail);
    setValidEmail(isValidEmail(newEmail));
  };

  const buttonStyle = {
    textTransform: 'capitalize',
    fontWeight: 'bold',
  };

  const [searchInput, setSearchInput] = useState('');
  const filteredSiteReportData = SiteReport.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchInput.toLowerCase())
    )
  );
  return (
    <div>
      {/* ---------search---bar--- Card----------- */}

      <Box
        sx={{
          width: '99%',
          height: 50,
        }}
      >
        <Paper elevation={0} style={{ height: '100%', position: 'relative' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '0.2%',
              }}
            >
              <Typography>
                <Link
                  to="/menu"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <KeyboardBackspaceIcon
                    style={{
                      verticalAlign: 'middle',
                      fontSize: '1.7rem',
                      cursor: 'pointer',
                    }}
                  />
                  Report
                </Link>{' '}
              </Typography>
              <TextField
                id="standard-basic"
                placeholder="Search here..."
                variant="outlined"
                size="small"
                // sx={{ width: "100%" }}
                sx={{ flex: 1, width: '300px', marginLeft: '15%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {' '}
                      <Button>
                        <SearchOutlinedIcon />
                      </Button>
                    </InputAdornment>
                  ),
                }}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
            <div style={{ position: 'absolute', bottom: '6px', right: '50px' }}>
              <Stack spacing={3} direction="row">
                <Button
                  onClick={handleCrate}
                  variant="contained"
                  style={buttonStyle}
                >
                  Create New Report
                </Button>
              </Stack>
            </div>
          </div>
        </Paper>
      </Box>
      <div>
        {/* ---------   -----save andupdate---- popup----------- */}

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box sx={style}>
              <div>
                <div style={{ marginTop: '-1%' }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <Item>
                      <Button
                        style={{ textTransform: 'capitalize' }}
                        variant="contained"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Item>

                    <Item>
                      {edit ? (
                        <Button
                          style={{ textTransform: 'capitalize' }}
                          variant="contained"
                          disabled={timevalue === 'custom' ? true : false}
                          // onClick={handleSave}
                          onClick={handleUpdate}
                        >
                          update
                        </Button>
                      ) : timePeriod === 'custom' ? (
                        <Button
                          style={{ textTransform: 'capitalize' }}
                          variant="contained"
                          onClick={handleDownloadReport}
                        >
                          Download
                        </Button>
                      ) : (
                        <Button
                          style={{ textTransform: 'capitalize' }}
                          variant="contained"
                          disabled={timevalue === 'custom' ? true : false}
                          onClick={handleSave}
                        >
                          save
                        </Button>
                      )}
                    </Item>
                  </Stack>
                </div>

                <div style={{ marginTop: '3%' }}>
                  <Grid container spacing={3} columns={32}>
                    <Grid item xs={8}>
                      <div>
                        <Typography
                          style={{
                            textTransform: 'capitalize',
                            opacity: '0.8',
                          }}
                        >
                          Site Name
                        </Typography>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          size="small"
                          defaultValue={editcall ? editcall.siteName : null}
                          options={siteIdName.map((option) => option.siteName)}
                          onChange={(data, event) => handleSite(data, event)}
                          sx={{ width: '13vw' }}
                          renderInput={(params) => (
                            <div>
                              <TextField
                                {...params}
                                placeholder="Select Site ..."
                                helperText={
                                  siteNameEmpty ? (
                                    <span style={{ color: 'red' }}>
                                      SiteName is required
                                    </span>
                                  ) : (
                                    ' '
                                  )
                                }
                              />
                            </div>
                          )}
                          classes={{ option: 'autocomplete' }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={8}>
                      <div>
                        <Typography
                          style={{
                            textTransform: 'capitalize',
                            opacity: '0.8',
                            alignItems: 'center',
                          }}
                        >
                          Report Name
                        </Typography>
                        <Autocomplete
                          options={reportIdName.map(
                            (option) => option.reportName
                          )}
                          size="small"
                          defaultValue={editcall ? editcall.reportName : null}
                          onChange={(data, event) => handleReport(data, event)}
                          sx={{ width: '13vw' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Report ..."
                              helperText={
                                reportNameEmpty ? (
                                  <span style={{ color: 'red' }}>
                                    ReportName is required
                                  </span>
                                ) : (
                                  ''
                                )
                              }
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={8}>
                      <div>
                        <Typography
                          style={{
                            textTransform: 'capitalize',
                            opacity: '0.8',
                            alignItems: 'center',
                          }}
                        >
                          Email
                        </Typography>
                        {edit ? (
                          <TextField
                            id="outlined-basic"
                            size="small"
                            placeholder="Email"
                            variant="outlined"
                            error={!validEmail}
                            helperText={
                              !validEmail ? 'Invalid email address' : ''
                            }
                            defaultValue={editcall ? editcall.mailId : null}
                            onChange={handleEmailChange}
                          />
                        ) : (
                          <TextField
                            id="outlined-basic"
                            size="small"
                            placeholder="Email"
                            variant="outlined"
                            error={!validEmail}
                            helperText={
                              !validEmail ? 'Invalid email address' : ''
                            }
                            value={email}
                            onChange={handleEmailChangevalue}
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div>
                        <Typography
                          style={{
                            textTransform: 'capitalize',
                            opacity: '0.8',
                          }}
                        >
                          Status
                        </Typography>
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          defaultValue={
                            editcall && editcall.activeFlag === 1
                              ? 'Active'
                              : editcall && editcall.activeFlag === 0
                              ? 'Inactive'
                              : null
                          }
                          onChange={(data, event) =>
                            handleactiveFlag(data, event)
                          }
                          options={status}
                          sx={{ width: '13vw' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select ..."
                              helperText={
                                activeFlagEmpty ? (
                                  <span style={{ color: 'red' }}>
                                    Status is required
                                  </span>
                                ) : (
                                  ''
                                )
                              }
                            />
                          )}
                          classes={{ option: 'autocomplete' }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div>
                        <Typography
                          style={{
                            textTransform: 'capitalize',
                            opacity: '0.8',
                          }}
                        >
                          Time Period
                        </Typography>
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={filterOptions(reportData)}
                          defaultValue={editcall ? editcall.timePeriod : null}
                          onChange={(data, event) =>
                            handletimePeriod(data, event)
                          }
                          sx={{ width: '13vw' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select ..."
                              helperText={
                                timePeriodEmpty ? (
                                  <span style={{ color: 'red' }}>
                                    Timeperiod is required
                                  </span>
                                ) : (
                                  ''
                                )
                              }
                            />
                          )}
                          classes={{ option: 'autocomplete' }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div style={filterAlignDaily}>
                        <Typography>
                          {reportData === 'Asset Management Report'
                            ? 'From'
                            : 'Select Date'}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="From"
                            disabled={timePeriod !== 'custom' ? true : false}
                            disableFuture
                            sx={{ width: '13vw' }}
                            onChange={(data, value) =>
                              handleChangeFromDate(data, value)
                            }
                            slotProps={{ textField: { size: 'small' } }}
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div style={filterAlign}>
                        <Typography> To</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="To"
                            disabled={timePeriod !== 'custom' ? true : false}
                            sx={{ width: '13vw' }}
                            disableFuture
                            shouldDisableDate={(date) =>
                              dayjs(date).isBefore(dayjs(customFromDate), 'day')
                            }
                            onChange={(data, value) =>
                              handleChangeToDate(data, value)
                            }
                            slotProps={{ textField: { size: 'small' } }}
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <div>
                <Grid container spacing={2} columns={32}></Grid>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
      {/* ---------------pass props value----------- */}
      <div style={{ marginTop: '1%' }}>
        <Reporttable
          SiteReport={SiteReport?.SiteReport}
          filteredSiteReportData={filteredSiteReportData}
          handleEditOpen={handleEditOpen}
          // refreshFunction={handleRefresh}
          handleDownloadOpen={handleDownloadOpen}
        />
      </div>

      {/*--------------------------------------- Modal Download Popup ---------------*/}

      {/* <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={downloadPopup}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={downloadPopup}>
            <Box sx={style}>
              <div>
                <div style={{ marginTop: "-1%" }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Item>
                      <Button
                        style={{ textTransform: "capitalize" }}
                        variant="contained"
                        onClick={() => {
                          setDownloadPopup(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Item>
                    <Item>
                      <Button
                        style={{ textTransform: "capitalize" }}
                        variant="contained"
                        disabled={timevalue === "custom" ? true : false}
                        // onClick={handleDownload}
                      >
                        Download
                      </Button>
                    </Item>
                  </Stack>
                </div>

                <div style={{ marginTop: "3%" }}>
                  <Grid container spacing={3} columns={32}>
                    <Grid item xs={8}>
                      <div>
                        <Typography
                          style={{
                            textTransform: "capitalize",
                            opacity: "0.8",
                          }}
                        >
                          Site Name
                        </Typography>
                        <TextField
                          variant="outlined"
                          value={downloadSiteName}
                          size="small"
                        ></TextField>
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div>
                        <Typography
                          style={{
                            textTransform: "capitalize",
                            opacity: "0.8",
                          }}
                        >
                          Timeperiod
                        </Typography>
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={optionTime}
                          defaultValue={editcall ? editcall.timePeriod : null}
                          onChange={(data, event) =>
                            handletimePeriod(data, event)
                          }
                          sx={{ width: "13vw" }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Select ..." />
                          )}
                          classes={{ option: "autocomplete" }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div style={downloadENableIcon}>
                        <Typography> From</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="From"
                            // disabled={timevalue !== "custom" ? true : false}
                            sx={{ width: "13vw" }}
                            onChange={(data, value) =>
                              handleChangeFromDate(data, value)
                            }
                            slotProps={{ textField: { size: "small" } }}
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div style={downloadENableIcon}>
                        <Typography> To</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="To"
                            // disabled={timevalue !== "custom" ? true : false}
                            sx={{ width: "13vw" }}
                            onChange={(data, value) =>
                              handleChangeToDate(data, value)
                            }
                            slotProps={{ textField: { size: "small" } }}
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <div>
                <Grid container spacing={2} columns={32}></Grid>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div> */}
    </div>
  );
}
