import React from 'react';
import {
    Box, Button, InputAdornment, TextField, TableSortLabel, FormControlLabel, Paper, Table, TableRow, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, Typography, Grid, Modal, Switch, FormGroup, Stack, Autocomplete, FormControl, ListItemText, Select, OutlinedInput, Checkbox, MenuItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import ViewAlert from './ViewAlert';
import MuiAlert from "@mui/material/Alert";
/*------------------ Mui icons -------------------- */
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
/*---------------------------------Api------------------------------*/
import {
    fetchSiteDetails,
    fetchEquipmentIdDetails,
    fetchParameterListDetails,
} from "../../../Api/ExcelUploadAPi";
import {
    DataAnalystSubmit,
    SubmitDataAnalyst,
    equipmentApplyChanges,
    parameterComparisonApplyChanges,
} from "../../../Api/DataAnalystApi";
function createData(alerts, alerttype, errorcode, occurrence, status, sitename, emailid, equipmentname, description, smsalert, action) {
    return { alerts, alerttype, errorcode, occurrence, status, sitename, emailid, equipmentname, description, smsalert, action };
}
const rows = [
    createData('Meter Track', "Meter Track", 10, 1, "Active", "KS Site", "eira@gmail.com", "MRV-I", "Device Reset"),
    createData('Grid Failure', "System Disconnect", 10, 1, "In Active", "KB Site", "eira@gmail.com", "MRV-II", "System Disconnect",),
    createData('Energy Loss', 'Zero Generation', 10, 1, "Active", "KC Site", "eira@gmail.com", "MRV-III", "Zero Generation",),
    createData('Meter Track', "Meter Track", 10, 1, "Active", "KS Site", "eira@gmail.com", "MRV-III", "System Disconnect",),
    createData('Grid Failure', "System Disconnect", 10, 1, "In Active", "KS Site", "eira@gmail.com", "MRV-I", "System Disconnect",),
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function Site() {
    // /*--------------------------Table Value Api-----------------------*/
    // const [tableValue, setTableValue] = useState([]);
    // useEffect(() => {
    //     fetchData();
    // }, []);
    // const fetchData = async () => {
    //     setloading(false)
    //     try {
    //         const data = await fetchAlertsTable();
    //         console.log(data);
    //         setTableValue(data);
    //         setloading(true)
    //     } catch (e) {
    //         console.log(e);
    //         setloading(true)
    //     }
    // };

    const monitor_head_text = {
        fontSize: "1.2rem",
        fontWeight: "bold",
        marginTop: "1%",
        marginLeft: "2%",
    };
    const chartType = [
        { label: "Daily Generation", value: "daily_generation" },
        { label: "Energy Performance", value: "energy_performance" },
        { label: "Parameter Comparision", value: "parameter_comparison" },
    ];
    const typoStyle = {
        textTransform: "capitalize",
        opacity: "0.8",
    };
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('alerts');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [siteEnergy, setSiteEnergy] = useState([]);
    // const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    const [site, setSite] = React.useState("");
    const [equipment, setEquipmentName] = React.useState([]);
    const [equipmentParam, setEquipmentParamName] = React.useState([]);
    const [datalog, setDatalogValue] = React.useState([]);
    const [siteIdName, setSiteId] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [equipmentData, setEquipmentData] = useState([]);
    const [equipmentParameter, setParameterSite] = useState([]);
    const [chartName, setChartName] = useState("");
    const [timevalue, setTimeValue] = useState("today");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [range, setRange] = useState("");
    const [open, setOpen] = useState(false);
    const [widgetName, setWidgetName] = useState("");
    const [variantText, setVarient] = useState("");
    const [textValue, setTextValue] = useState("");
    const [barchartdata, setBarchartData] = useState([]);
    const [multiChartData, setMultichartData] = useState([]);
    const [tableData, setTableData] = useState();
    const [openToast, setOpenToast] = React.useState(false);
    const [selectAllEquipments, setSelectAllEquipments] = useState(false);
    const [selectAllEquipmentParam, setSelectAllEquipmentParam] = useState(false);
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    useEffect(() => {
        // getSiteCall();
    }, []);
    const handleOpen = () => {
        if (chartName === "" && site === "") {
            setOpenToast(true);
            setVarient("error");
            setTextValue("Kindly Select Chart Type And Site Name");
        } else if (timevalue === "custom") {
            setOpenToast(true);
            setOpen(false);
            setVarient("warning");
            setTextValue(
                "Custom Range Datas are Restricted to Store in Wizard Format"
            );
        } else {
            setOpen(true);
        }
    };
    const handleClose = () => setOpen(false);
    const handleCloseToast = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenToast(false);
    };
    /*---------------------Search Option ----------------*/
    const [tableValue, setTableValue] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const tableValues = tableValue.filter((row) =>
        Object.values(row).some((value) =>
            String(value).toLowerCase().includes(searchInput.toLowerCase())
        )
    );

    const navigate = useNavigate();
    /*---------------Redirect the page-----------------*/
    const [selectedRowValue, setSelectedRowValue] = useState('');
    const handleRowClick = (rowValue) => {
        setSelectedRowValue(rowValue);
        navigate('/menu/viewalert', { state: { selectedRow: rowValue } });
    };
    /*--------------- Settings (Optional) Modal Components-----------------*/
    const [filteredSiteEnergy, setFilteredSiteEnergy] = useState(siteEnergy);
    const defaultLoadingStates = {
        alerts: true,
        occurrence: true,
        status: true,
        sitename: true,
        emailid: true,
        equipmentname: true,
        description: true,
        smsalert: true,
        action: true,
    };
    const [alertsLoading, setalertsLoading] = useState(defaultLoadingStates.alerts);
    const [occurrenceLoading, setoccurrenceLoading] = useState(defaultLoadingStates.occurrence);
    const [statusLoading, setstatusLoading] = useState(defaultLoadingStates.status);
    const [sitenameLoading, setsitenameLoading] = useState(defaultLoadingStates.sitename);
    const [emailidLoading, setemailidLoading] = useState(defaultLoadingStates.sitename);
    const [equipmentnameLoading, setequipmentnameLoading] = useState(defaultLoadingStates.equipmentname);
    const [descriptionLoading, setdescriptionLoading] = useState(defaultLoadingStates.description);
    const [smsalertLoading, setsmsalertLoading] = useState(defaultLoadingStates.smsalert);
    const [actionLoading, setactionLoading] = useState(defaultLoadingStates.action);
    const handleToggle = () => {
        const filteredValues = siteEnergy?.filter((row) => {
            return (
                (alertsLoading && row.alerts) ||
                (occurrenceLoading && row.occurrence) ||
                (statusLoading && row.status) ||
                (sitenameLoading && row.sitename)
                    (emailidLoading && row.emailid)
                    (equipmentnameLoading && row.equipmentname) ||
                (descriptionLoading && row.description)
                    (smsalertLoading && row.smsalert)
                    (actionLoading && row.action)
            );
        });
        setFilteredSiteEnergy(filteredValues);
    };
    /*-------------sorting--------------*/
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);
        sortData(filteredSiteEnergy, newOrder, property); // Use the filtered data here
    };
    const sortData = (data, sortOrder, field) => {
        const sortedData = [...data];
        sortedData.sort((a, b) => {
            const aValue = a[field];
            const bValue = b[field];
            if (sortOrder === 'asc') {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else if (sortOrder === 'desc') {
                return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
            }
            return 0;
        });
        setSiteEnergy(sortedData);
    };
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.alerts);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const handleSetValue = (rowid) => {
        navigate('/menu/viewalert', { state: { id: rowid } })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, (siteEnergy?.length || 0) - page * rowsPerPage);
    /*------------------------- Mui IOS Switch --------------------------*/
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));
    const handleNavigate = () => {
        navigate("/menu/newalert");
    };
    const buttonStyle = {
        textTransform: "capitalize",
        fontWeight: "bold",
        backgroundColor: "#000440",
        color: "white"
        // backgroundColor:'#09001A'
    };
    const buttonStyles = {
        textTransform: "capitalize",
        boxShadow: "4px 4px 17px rgba(0, 0, 0.50, 0.50)",
        fontSize: "1.1rem",
        border: "none",
        width: "7vw",
    };
    /*--------------------------------- Modal Component ------------------------------------*/
    const stylemodal = {
        margin: "20px 0px 0px 0px",
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: "78%",
        height: "55vh",
        bgcolor: 'background.paper',
        p: 4,
        marginLeft: "5%"
    };
    // const [isCustomizeAlertModalOpen, setCustomizeAlertModalOpen] = useState(false);
    const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
    // const handleOpenCustomizeAlertModal = () => {
    //     setCustomizeAlertModalOpen(true);
    // };
    // const handleCloseCustomizeAlertModal = () => {
    //     setCustomizeAlertModalOpen(false);
    // };
    const handleOpenSettingsModal = () => {
        setSettingsModalOpen(true);
    };
    const handleCloseSettingsModal = () => {
        setSettingsModalOpen(false);
    };
    return (
        <div>
            <Box
                sx={{
                    width: "100%",
                    height: 50,
                }}
            >
                <Paper elevation={0} style={{ height: "100%", position: "relative" }}>
                    <div style={{ display: "flex", justifyContent: "flex-start" }} >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0 0 13px" }}>
                            <Typography>
                                <Link to="/menu" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                    <KeyboardBackspaceIcon
                                        style={{ verticalAlign: 'middle', fontSize: '1.7rem', cursor: 'pointer' }}
                                    />
                                    Alerts
                                </Link>  </Typography>
                            <TextField
                                id="standard-basic"
                                placeholder="Search Site Name here..."
                                variant="outlined"
                                size='small'
                                // sx={{ width: "100%" }}
                                sx={{ flex: 1, width: "350px", marginLeft: "15%" }}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start"
                                    >
                                        <Button style={{ color: "#000440" }}><SearchOutlinedIcon /></Button>
                                    </InputAdornment>),
                                }}
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </div>
                        {/* <div style={{ position: "absolute", bottom: '7px', right: "100px" }}>
                                <Stack spacing={3} direction="row">
                                    <Button
                                        variant="contained"
                                        style={buttonStyle}
                                        // onClick={handleCancel}
                                        onClick={handlemodalOpen}
                                    >
                                        Create New Alert
                                    </Button>
                                </Stack>
                            </div> */}
                        <div style={{ position: "absolute", bottom: '8px', right: '25px' }}>
                            <Button onClick={handleOpenSettingsModal} style={{ color: "#000440" }}> <SettingsOutlinedIcon /></Button>
                        </div>
                    </div>
                </Paper>
            </Box>
            <div>
                <Box sx={{
                    display: 'flex', flexWrap: 'wrap', '& > :not(style)': {
                        mr: 0, mb: 2, mt: 2, width: '100%',
                        minHeight: '7vh',
                        maxHeight: 'max-content',
                        m: 0,
                        padding: '0'
                    },
                }}>
                </Box >
                <div>
                    <Box sx={{
                        width: '100%', minHeight: '65vh',
                        maxHeight: 'max-content',
                    }}>
                        <Paper sx={{ width: '100%', mb: 2, marginTop: "0.5%" }} elevation={0}>
                            <TableContainer style={{ height: '65vh' }}>
                                <Table
                                    sx={{ minWidth: 750, }}
                                    aria-labelledby="tableTitle"
                                    stickyHeader aria-label="sticky table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            {alertsLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'siteName' ? order : 'desc'}
                                                    direction={orderBy === 'alerts' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('alerts')}
                                                >
                                                    Alerts
                                                </TableSortLabel>
                                            </TableCell>}
                                            {occurrenceLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'siteName' ? order : 'desc'}
                                                    direction={orderBy === 'occurrence' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('occurrence')}
                                                >
                                                    Occurrence
                                                </TableSortLabel>
                                            </TableCell>}
                                            {statusLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'siteName' ? order : 'desc'}
                                                    direction={orderBy === 'status' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('status')}
                                                >
                                                    Status
                                                </TableSortLabel>
                                            </TableCell>}
                                            {sitenameLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'siteName' ? order : 'desc'}
                                                    direction={orderBy === 'sitename' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('sitename')}
                                                >
                                                    Site Name
                                                </TableSortLabel>
                                            </TableCell>}
                                            {emailidLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'siteName' ? order : 'desc'}
                                                    direction={orderBy === 'emailid' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('emailid')}
                                                >
                                                    Email Id
                                                </TableSortLabel>
                                            </TableCell>}
                                            {equipmentnameLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'siteName' ? order : 'desc'}
                                                    direction={orderBy === 'equipmentname' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('equipmentname')}
                                                >
                                                    Equipment Name
                                                </TableSortLabel>
                                            </TableCell>}
                                            {descriptionLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'siteName' ? order : 'desc'}
                                                    direction={orderBy === 'description' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('description')}
                                                >
                                                    Description
                                                </TableSortLabel>
                                            </TableCell>}
                                            {smsalertLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'siteName' ? order : 'desc'}
                                                    direction={orderBy === 'smsalert' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('smsalert')}
                                                >
                                                    SMS Alert
                                                </TableSortLabel>
                                            </TableCell>}
                                            {actionLoading && <TableCell align="center"> Action</TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableValues.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => (
                                                <TableRow
                                                    key={row.alerts}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    {alertsLoading && (
                                                        <TableCell align="center"
                                                            style={{ cursor: "pointer", textDecoration: 'underline' }}
                                                            onClick={() => handleRowClick(row.alerts)}>
                                                            {row.alerts}
                                                        </TableCell>
                                                    )}
                                                    {occurrenceLoading && (<TableCell align="center">{row.occurrence}</TableCell>)}
                                                    {statusLoading && (<TableCell align="center">{row.status}</TableCell>)}
                                                    {sitenameLoading && (<TableCell align="center">{row.sitename}</TableCell>)}
                                                    {emailidLoading && (<TableCell align="center">{row.emailid}</TableCell>)}
                                                    {equipmentnameLoading && (<TableCell align="center">{row.equipmentname}</TableCell>)}
                                                    {descriptionLoading && (<TableCell align="center">{row.description}</TableCell>)}
                                                    {smsalertLoading && (<TableCell style={{ textAlign: "right", marginLeft: "30px" }}>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={<IOSSwitch sx={{ m: 1, marginLeft: "38px" }} defaultChecked />}
                                                            // label="iOS style"
                                                            />
                                                        </FormGroup>
                                                    </TableCell>)}
                                                    {actionLoading && (<TableCell align='center'> < EditIcon /> <DeleteIcon /> </TableCell>)}
                                                    {/* {actionLoading && (<TableCell style={{ textAlign: "center", fontSize: "14px" }} >< TrendingUpIcon /> <AlarmIcon /> </TableCell>)} */}
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                // count={tableValue.length}
                                count={siteEnergy?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </div>
                {/* <div>
                    <Modal
                        open={isCustomizeAlertModalOpen}
                        onClose={handleCloseCustomizeAlertModal}
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        sx={{ marginTop: "1.5%" }}
                    >
                        <div
                            className="monitoring-points"
                            style={{ marginLeft: "2%", marginTop: "1%" }}
                        >
                            <Box
                                sx={stylemodal}
                            >
                                <Paper elevation={0}>
                                    <div className="analytics">
                                        <div style={{ marginLeft: "2.5%" }}>
                                            <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                                                <KeyboardBackspaceIcon style={{ verticalAlign: 'middle', fontSize: '1.7rem', marginRight: '8px' }} onClick={handleCancel} />
                                                New Alert
                                            </Typography>
                                        </div>
                                        <div style={{ marginRight: "0.5%" }}>
                                            <Stack spacing={2} direction="row">
                                                <Button
                                                    variant="outlined"
                                                    style={buttonStyles}
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    style={buttonStyles}
                                                    onClick={handleOpen}
                                                >
                                                    Save
                                                </Button>
                                            </Stack>
                                        </div>
                                    </div>
                                    <div className="monitor-head">
                                            <Typography style={monitor_head_text}>
                                                New Alert
                                            </Typography>
                                        </div>
                                    <div style={{ margin: "80px 0 0 10px" }}>
                                        <div>
                                            <Stack spacing={0} direction="row">
                                                <div style={{ flex: 1, minWidth: 250 }}>
                                                    <Typography style={typoStyle}>Select Site </Typography>
                                                    <Autocomplete
                                                        size="small"
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={siteIdName.map((option) => option.siteName)}
                                                        onChange={(data, event) => handleSite(data, event)}
                                                        sx={{ width: "70%" }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} placeholder="Select Site ..." />
                                                        )}
                                                        classes={{ option: "autocomplete" }}
                                                    />
                                                </div>
                                                <div style={{ flex: 1, minWidth: 250 }}>
                                                    <FormControl sx={{ width: '70%' }}>
                                                        <Typography style={typoStyle}>Equipments</Typography>
                                                        <Select
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            disabled={
                                                                chartName === "Daily Generation" ? true : false
                                                            }
                                                            size="small"
                                                            value={equipment}
                                                            onChange={handleChange}
                                                            input={<OutlinedInput />}
                                                            MenuProps={MenuProps}
                                                            renderValue={(selected) => selected.join(",")}
                                                            placeholder="Select Equipments ..."
                                                        >
                                                            <MenuItem value="Select All">
                                                                <Checkbox
                                                                    checked={equipment.length === equipmentData.length}
                                                                />
                                                                <ListItemText primary="Select All" />
                                                            </MenuItem>
                                                            {equipmentData.map((name) => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                    className="autocomplete"
                                                                >
                                                                    <Checkbox checked={equipment.includes(name)} />
                                                                    <ListItemText
                                                                        primary={name}
                                                                        className="autocomplete"
                                                                    />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div style={{ flex: 1, minWidth: 250 }}>
                                                    <FormControl sx={{ width: '70%' }}>
                                                        <Typography style={typoStyle}>
                                                            Parameter
                                                        </Typography>
                                                        <Select
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            size="small"
                                                            // disabled={
                                                            //     chartName === "Parameter Comparision" ? false : true
                                                            // }
                                                            value={equipmentParam}
                                                            onChange={handleChangeEquipment}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => selected.join(",")}
                                                            MenuProps={MenuProps}
                                                        >
                                                            <MenuItem value="Select All">
                                                                <Checkbox
                                                                    checked={equipment.length === equipmentData.length}
                                                                />
                                                                <ListItemText primary="Select All" />
                                                            </MenuItem>
                                                            {equipmentParameter.map((name) => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                    className="autocomplete"
                                                                >
                                                                    <Checkbox
                                                                        checked={equipmentParam.indexOf(name) > -1}
                                                                    />
                                                                    <ListItemText
                                                                        primary={name}
                                                                        className="autocomplete"
                                                                    />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div style={{ flex: 1, minWidth: 250 }}>
                                                    <Typography style={typoStyle}>Maximum Range</Typography>
                                                    <TextField
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        size="small"
                                                        options={siteIdName.map((option) => option.siteName)}
                                                        onChange={(data, event) => handleSite(data, event)}
                                                        sx={{ width: "70%" }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} placeholder="Select  Site ..." />
                                                        )}
                                                        classes={{ option: "autocomplete" }}
                                                    />
                                                </div>
                                            </Stack>
                                        </div>
                                        <div style={{ margin: "60px 0 0 0" }} >
                                            <Stack spacing={0} direction="row">
                                                <div style={{ flex: 1, minWidth: 250 }}>
                                                    <Typography style={typoStyle}>Maximum Range</Typography>
                                                    <TextField
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        size="small"
                                                        options={siteIdName.map((option) => option.siteName)}
                                                        onChange={(data, event) => handleSite(data, event)}
                                                        sx={{ width: "70%" }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} placeholder="Select  Site ..." />
                                                        )}
                                                        classes={{ option: "autocomplete" }}
                                                    />
                                                </div>
                                                <div style={{ flex: 1, minWidth: 250 }}>
                                                    <Typography style={typoStyle}>Description </Typography>
                                                    <Autocomplete
                                                        size="small"
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={siteIdName.map((option) => option.siteName)}
                                                        onChange={(data, event) => handleSite(data, event)}
                                                        sx={{ width: "70%" }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} placeholder="Select Site ..." />
                                                        )}
                                                        classes={{ option: "autocomplete" }}
                                                    />
                                                </div>
                                                <div style={{ flex: 1, minWidth: 250 }}>
                                                    <Typography style={typoStyle}>Description </Typography>
                                                    <Autocomplete
                                                        size="small"
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={siteIdName.map((option) => option.siteName)}
                                                        onChange={(data, event) => handleSite(data, event)}
                                                        sx={{ width: "70%" }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} placeholder="Select Site ..." />
                                                        )}
                                                        classes={{ option: "autocomplete" }}
                                                    />
                                                </div>
                                                <div style={{ flex: 1, minWidth: 250 }}>
                                                    <FormControl sx={{ width: '70%' }}>
                                                        <Typography style={typoStyle}>
                                                            Parameter
                                                        </Typography>
                                                        <Select
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            size="small"
                                                            // disabled={
                                                            //     chartName === "Parameter Comparision" ? false : true
                                                            // }
                                                            value={equipmentParam}
                                                            onChange={handleChangeEquipment}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => selected.join(",")}
                                                            MenuProps={MenuProps}
                                                        >
                                                            <MenuItem value="Select All">
                                                                <Checkbox
                                                                    checked={equipment.length === equipmentData.length}
                                                                />
                                                                <ListItemText primary="Select All" />
                                                            </MenuItem>
                                                            {equipmentParameter.map((name) => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                    className="autocomplete"
                                                                >
                                                                    <Checkbox
                                                                        checked={equipmentParam.indexOf(name) > -1}
                                                                    />
                                                                    <ListItemText
                                                                        primary={name}
                                                                        className="autocomplete"
                                                                    />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </Stack>
                                        </div>
                                    </div>
                                </Paper>
                            </Box>
                        </div>
                    </Modal>
                </div> */}
                <div >
                    <Modal
                        open={isSettingsModalOpen}
                        onClose={handleCloseSettingsModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Paper elevation={0} className='total-custom' sx={{
                            width: '18%', height: 'max-content', overflow: "hidden",
                            backgroundColor: 'aliceblue', padding: '10px', marginLeft: '81%', marginTop: "9.5%"
                        }}>
                            <div >
                                <Grid container spacing={10}>
                                    <Grid item xs={6} md={14}>
                                        <Typography style={{ color: "rgb(18, 127, 216)", marginLeft: "70%", marginTop: "4%", cursor: "pointer" }} onClick={handleClose}>
                                            <CloseOutlinedIcon />
                                        </Typography>
                                        {alertsLoading ? (
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={alertsLoading}
                                                        onChange={() => setalertsLoading(!alertsLoading)}
                                                        color="primary"
                                                        disabled
                                                    />
                                                }
                                                label="Alerts"
                                            />
                                        ) : (
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={<Switch color="primary" />}
                                                label="Alerts"
                                            />
                                        )}
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={occurrenceLoading}
                                                    onChange={() => setoccurrenceLoading(!occurrenceLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Occurrence"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={statusLoading}
                                                    onChange={() => setstatusLoading(!statusLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Status"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={sitenameLoading}
                                                    onChange={() => setsitenameLoading(!sitenameLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Site Name "
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={emailidLoading}
                                                    onChange={() => setemailidLoading(!emailidLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Email Id "
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={equipmentnameLoading}
                                                    onChange={() => setequipmentnameLoading(!equipmentnameLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Equipment Name "
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={descriptionLoading}
                                                    onChange={() => setdescriptionLoading(!descriptionLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Description"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={smsalertLoading}
                                                    onChange={() => setsmsalertLoading(!smsalertLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="SMS Alert "
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={actionLoading}
                                                    onChange={() => setactionLoading(!actionLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label=" Action "
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Modal>
                </div>
            </div >
        </div>
    )
}
