import React, { useState, useEffect, useRef } from 'react'
import "./Style.css"

/*--------------------------- MUI components ------------------------*/
import {
  Paper, Box, Button, Modal, Divider, Autocomplete, TextField, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow
} from '@mui/material'
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

/*----------------------------------- MUI Icons ----------------------------*/
import CloseIcon from '@mui/icons-material/Close';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

/*-------------------------------External Componnets -------------------*/
import ExcelUplaod from '../Excel/ExcelUpload'

/*------------------------------- APi calls -------------------------*/
import { fetchSiteDetails, fetchStandardParams, fetchEquipmentIdDetails, PostdataConfig } from '../../Api/ExcelUploadAPi'

const ExcelUploadUi = () => {

  const [excelParams, setExcelParams] = useState([])
  const [equipment, setEquipment] = React.useState("")
  const [fileName, setfileName] = React.useState("")
  const [site, setSite] = React.useState("")
  const [tableData, setTableData] = useState([]);
  const [siteIdName, setSiteId] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [stdParamId, setStandardParamsId] = React.useState("")
  const [standardParams, setStandardParams] = useState([]);
  const [rows, setRows] = useState([]);
  const [coefficientValue, setCoefficientValue] = useState([]);
  const [stdValue, setStdValue] = useState('')
  const [activeInputIndex, setActiveInputIndex] = useState(null);
  const inputRefs = useRef([]);

  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'cornflowerblue',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


  useEffect(() => {
    // Initialize the inputRefs array with null values
    inputRefs.current = excelParams.map(() => null);
  }, [excelParams]);

  useEffect(() => {
    getSiteCall();
  }, [])

  const handleExcelparams = (data) => {
    setExcelParams(data)
  }


  /*-------------------------- Site Id Call --------------------------*/
  const getSiteCall = async () => {
    try {
      let data = await fetchSiteDetails();
      setSiteId(data)
    } catch (e) {
      console.log(e);
    }
  }

  /*--------------------------------Equipment Id call ----------------------------*/
  const fetchEquipmentDetails = async (event) => {
    let siteIdFilter = siteIdName?.filter((data) => { return (data.siteName === event) }) // want to change in global 
    console.log(siteIdFilter);
    try {
      let data = await fetchEquipmentIdDetails(siteIdFilter[0]?.siteId);
      setEquipmentData(data)
    } catch (e) {
      console.log(e);
    }
  }
  
  /*------------------------- standard params --------------------------*/
  const getstandardParams = async () => {
    try {
      let data = await fetchStandardParams();
      setStandardParams(data)
    } catch (e) {
      console.log(e);
    }
  }


  /*---------------------- excel upload --------------------------*/
  const handleUploadExcel = async (event) => {
    const file = event.target.files[0];
    setfileName(file.name)
    const fileData = await ExcelUplaod(event)
    handleExcelparams(fileData?.data[0])
    getstandardParams()
    event.target.value = null;

  }


  const handleEquipment = (data, event) => {
    if (event === null) {
      setEquipment("")
    }
    else {
      setEquipment(event)
    }


  }
  const handleSite = (data, event) => {
    if (event === null) {
      setSite("")
    }
    else {
      setSite(event)
      fetchEquipmentDetails(event)
    }

  }
  const handleEquipmentUnits = (index, col, value, row) => {

    let stdvalue = standardParams?.filter((data) => data.standardparametername === value.split("-")[0].trim());
    console.log(stdvalue);
    setStdValue(stdvalue[0]?.standardparameteruom)
    setStandardParamsId(stdvalue[0]?.standardid)

  }
  /*------------------------------------ Changing value  for tabel set value ----------------------------*/

  const handleChange = (index, col, value, row) => {
    const updatedValues = [...rows];
    const updatedValue = [...coefficientValue];

    if (col === "std_par") {

      console.log(value);
      updatedValues[index] = value;
      setRows(updatedValues);
      handleEquipmentUnits(index, col, value, row)


    } else {
      let validValue = value.replace(/[^0-9.]/g, '')
      const decimalIndex = validValue.indexOf('.');
      if (decimalIndex !== -1) {
        const decimalPart = validValue.substr(decimalIndex + 1, 5); // Extract up to 5 characters
        validValue = validValue.substr(0, decimalIndex + 1) + decimalPart; // Combine integer and decimal part
      }
      updatedValue[index] = validValue;
      setCoefficientValue(updatedValue);
      setActiveInputIndex(index);
      inputRefs.current[index] = inputRefs.current[index] || React.createRef();

    }
    inputRefs.current[index].focus();


    setTableData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        Parms: excelParams[index],
        [col]: value,

      };
      updatedData[index].units = stdValue
      updatedData[index].stdparamId = stdParamId
      return updatedData;
    });
  };

  console.log(tableData, "col");



  /*------------------------------------------ handle Submit ------------------------------*/

  const handleSubmit = async () => {
    let siteIdFilter = siteIdName?.filter((data) => { return (data.siteName === site) })

    let equipFilter = equipmentData?.filter((data) => { return (data.customerNaming === equipment) })


    // console.log(siteIdFilter);
    let obj = {
      siteId: siteIdFilter[0].siteId,
      equipmentId: equipFilter[0]?.equipmentId,
      excelname: fileName,
      excelparms: tableData
    }

    try {
      let data = await PostdataConfig(obj);
      console.log(data);
    } catch (e) {
      console.log(e);
    }

  }
  return (
    <div className='header'>
      <div style={{ display: 'flex' }}>
        <div className='master-screen'>
          <div>
            <p className='master-text'> Site : </p>
          </div>
          <div className='master-autocompleteExcel'>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={siteIdName.map((option) => option.siteName)}

              onChange={(data, event) => handleSite(data, event)}
              sx={{ width: "15vw" }}
              renderInput={(params) => <TextField {...params} placeholder="Select  Site ..." />}
            />
          </div>

        </div>
        <div className='master-screen'>
          <div>
            <p className='master-text' style={{ marginLeft: '2vw' }}> Equipment ID : </p>
          </div>
          <div className='master-autocompleteExcel'>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={equipmentData.map((option) => option.customerNaming)}
              onChange={(data, event) => handleEquipment(data, event)}
              sx={{ width: "15vw", }}
              renderInput={(params) => <TextField {...params} placeholder="Select Equipment ID..." />}
            />
          </div>
          <div className='file-text-button'>
            <Button
              disabled={(site !== "") ? false : true}
              variant="contained"
              component="label"
              startIcon={<FileUploadOutlinedIcon />}

              style={site !== "" ? { backgroundColor: "Darkcornflowerblue", color: 'white', textTransform: 'capitalize' } : { textTransform: 'capitalize', backgroundColor: "Crimson", color: 'white' }}
            >
              Upload File
              <input
                hidden
                type="file"

                className="custom-file-input"
                id="inputGroupFile"
                required
                onChange={(data, value) => handleUploadExcel(data, value)}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </Button>
          </div>

        </div>

      </div>

      <div>
        {
          excelParams?.length > 0 ?
            <div>
              <div className="align-excel">
                <Paper elevation={23}>
                  <TableContainer sx={{ width: '70vw', height: '65vh', }} component={Paper}>
                    <Table stickyHeader aria-label="sticky table"  >
                      <TableHead style={{ marginBottom: '9vh' }}>
                        <StyledTableRow className="no-divider" >
                          <StyledTableCell align='center'> Parameter Name </StyledTableCell>
                          <StyledTableCell align='center'> Standard Parameter</StyledTableCell>
                          <StyledTableCell align='center'> Co Efficient  </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>

                      <TableBody className='table-body'>
                        {
                          excelParams?.map((row, index) => {
                            return (
                              <StyledTableRow className="no-divider" key={index}>
                                <StyledTableCell align='center'>{row}</StyledTableCell>
                                <StyledTableCell align='center' >

                                  <Autocomplete

                                    disablePortal
                                    id="combo-box-demo"
                                    value={rows[index]}
                                    options={standardParams.map((data) => `${data.standardparametername} , -  ${data.standardparameteruom !== null ? (data.standardparameteruom) : ""} `)}
                                    onChange={(data, event) =>
                                      handleChange(index, "std_par", event, row)
                                    }
                                    renderInput={(params) => <TextField {...params} variant="standard" placeholder="standard Params" sx={{ align: 'center' }} />}
                                  />
                                </StyledTableCell>

                                <StyledTableCell align='center' >
                                  <TextField id="standard-basic"
                                    placeholder="Co-Efficient"
                                    variant="standard"
                                    value={coefficientValue[index]}
                                    autoFocus={activeInputIndex === index}
                                    inputRef={(ref) => {
                                      inputRefs.current[index] = ref;
                                    }}
                                    onChange={(e) => handleChange(index, "co_eff", e.target.value, row)} />
                                </StyledTableCell>

                              </StyledTableRow>
                            )
                          })
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>

              </div>
              <Divider />
              <div className='submit'>
                <Button variant='contained' style={{ textTransform: 'capitalize', width: '10vw', backgroundColor: "#1C243C", color: 'white' }} onClick={handleSubmit}> Submit</Button>
              </div>
            </div>
            : ''}
      </div>



    </div>
  )
}

export default ExcelUploadUi;



