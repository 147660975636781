import React, { useState, useEffect, } from "react";
import "./Alerts.css";
import moment from "moment";
import { Link } from "react-router-dom";
/*---------------------------Mui Components ----------------------------------*/
import {
    Button,
    Stack,
    Box,
    Paper,
    TextField,
    Autocomplete,
    OutlinedInput,
    Typography,
    Divider,
    CardContent,
    Card,
    FormControl,
    Select,
    MenuItem,
    ListItemText,
    Checkbox,
    Modal,
    Grid, Switch,
    TableSortLabel, FormControlLabel, Table, TableRow, TableBody, TableCell, TableContainer, TableHead, InputAdornment,
    TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
/*---------------------------Mui Icons --------------------------*/
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TimelineIcon from "@mui/icons-material/Timeline";
import BarChartIcon from "@mui/icons-material/BarChart";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
/*------------------------- External compoenents ---------------------------*/
import BarChart from "../../Charts/BarChart";
import {
    fetchSiteDetails,
    fetchEquipmentIdDetails,
    fetchParameterListDetails,
} from "../../../Api/ExcelUploadAPi";
import {
    DataAnalystSubmit,
    SubmitDataAnalyst,
    equipmentApplyChanges,
    parameterComparisonApplyChanges,
} from "../../../Api/DataAnalystApi";
const alertStyle = {
    color: "white", // Text color
    "& .MuiSvgIcon-root": {
        color: "white", // Icon color
    },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const optionTime = [
    { label: "Today", value: "today" },
    { label: "Yesterday ", value: "yesterday" },
    { label: "Last 7 days", value: "last 7 days" },
    { label: "This Month", value: "this month" },
    { label: "Last Month", value: "last month" },
    { label: "Custom Range", value: "custom" },
];
const chartType = [
    { label: "Daily Generation", value: "daily_generation" },
    { label: "Energy Performance", value: "energy_performance" },
    { label: "Parameter Comparision", value: "parameter_comparison" },
];
const buttonStyles = {
    textTransform: "capitalize",
    boxShadow: "4px 4px 17px rgba(0, 0, 0.50, 0.50)",
    fontSize: "1.1rem",
    border: "none",
    width: "7vw",
};
// const buttonStyle = {
//     textTransform: "capitalize",
//     boxShadow: "4px 4px 17px rgba(0, 0, 0.50, 0.50)",
//     fontSize: "1.1rem",
//     border: "none",
//     width: "14vw",
// };
const buttonStyle = {
    textTransform: "capitalize",
    fontWeight: "bold",
    backgroundColor: "#000440",
    color: "white"
    // backgroundColor:'#09001A'
};
const typoStyle = {
    textTransform: "capitalize",
    opacity: "0.8",
};
const monitor_head_text = {
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginTop: "1%",
    marginLeft: "2%",
};
const filterAlign = {
    marginTop: "5.5%",
};
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "25vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
const NewAlert = () => {
    const navigate = useNavigate();
    const [site, setSite] = React.useState("");
    const [equipment, setEquipmentName] = React.useState([]);
    const [equipmentParam, setEquipmentParamName] = React.useState([]);
    const [datalog, setDatalogValue] = React.useState([]);
    const [siteIdName, setSiteId] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [equipmentData, setEquipmentData] = useState([]);
    const [equipmentParameter, setParameterSite] = useState([]);
    const [chartName, setChartName] = useState("");
    const [timevalue, setTimeValue] = useState("today");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [range, setRange] = useState("");
    const [open, setOpen] = useState(false);
    const [widgetName, setWidgetName] = useState("");
    const [variantText, setVarient] = useState("");
    const [textValue, setTextValue] = useState("");
    const [barchartdata, setBarchartData] = useState([]);
    const [multiChartData, setMultichartData] = useState([]);
    const [tableData, setTableData] = useState();
    const [openToast, setOpenToast] = React.useState(false);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('alerts');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [siteEnergy, setSiteEnergy] = useState([]);
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    /*----------------------------Search Input-------------------------------*/
    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (e) => {
        const inputValue = e.target.value;
        setSearchInput(inputValue);
        const filteredData = rows.filter((row) =>
            Object.values(row).some((value) =>
                String(value).toLowerCase().includes(inputValue.toLowerCase())
            )
        );
        setSiteEnergy(filteredData);
    };
    const handleNavigate = () => {
        navigate("/menu/alerts");
    };
    const [isCreateAlertModalOpen, setCreateAlertModalOpen] = useState(false);
    const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
    const handleOpenCreateAlertModal = () => {
        setCreateAlertModalOpen(true);
    };
    const handleCloseCreateAlertModal = () => {
        setCreateAlertModalOpen(false);
    };
    const handleOpenSettingsModal = () => {
        setSettingsModalOpen(true);
    };
    const handleCloseSettingsModal = () => {
        setSettingsModalOpen(false);
    };
    /*--------------- Settings (Optional) Modal Components-----------------*/
    const [filteredSiteEnergy, setFilteredSiteEnergy] = useState(siteEnergy);
    const defaultLoadingStates = {
        selectsite: true,
        equipments: true,
        maximumrange: true,
        minimumrange: true,
        emailid: true,
        description: true,
        equipmentname: true,
        parameter: true,
        status: true,
    };
    const [selectsiteLoading, setselectsiteLoading] = useState(defaultLoadingStates.selectsite);
    const [equipmentsLoading, setequipmentsLoading] = useState(defaultLoadingStates.equipments);
    const [maximumrangeLoading, setmaximumrangeLoading] = useState(defaultLoadingStates.maximumrange);
    const [minimumrangeLoading, setminimumrangeLoading] = useState(defaultLoadingStates.minimumrange);
    const [emailidLoading, setemailidLoading] = useState(defaultLoadingStates.emailid)
    const [descriptionLoading, setdescriptionLoading] = useState(defaultLoadingStates.description);
    const [parameterLoading, setparameterLoading] = useState(defaultLoadingStates.parameter);
    const [statusLoading, setstatusLoading] = useState(defaultLoadingStates.status);
    const handleToggle = () => {
        const filteredValues = siteEnergy?.filter((row) => {
            return (
                (selectsiteLoading && row.selectsite) ||
                (equipmentsLoading && row.equipments) ||
                (maximumrangeLoading && row.maximumrange) ||
                (minimumrangeLoading && row.minimumrange)
                    (emailidLoading && row.emailid)
                    (descriptionLoading && row.description) ||
                (parameterLoading && row.parameter)
                    (statusLoading && row.status)
            );
        });
        setFilteredSiteEnergy(filteredValues);
    };
    /*-------------sorting--------------*/
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);
        sortData(filteredSiteEnergy, newOrder, property); // Use the filtered data here
    };
    const sortData = (data, sortOrder, field) => {
        const sortedData = [...data];
        sortedData.sort((a, b) => {
            const aValue = a[field];
            const bValue = b[field];
            if (sortOrder === 'asc') {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else if (sortOrder === 'desc') {
                return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
            }
            return 0;
        });
        setSiteEnergy(sortedData);
    };
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.alerts);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const handleSetValue = (rowid) => {
        navigate('/menu/viewalert', { state: { id: rowid } })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, (siteEnergy?.length || 0) - page * rowsPerPage);
    function createData(alerts, alerttype, errorcode, selectsite, equipment, maximumrange, minimumrange, emailid, description, parameter, status, sitename, equipmentname, smsalert, action) {
        return { alerts, alerttype, errorcode, selectsite, equipment, maximumrange, minimumrange, emailid, description, status, parameter, sitename, equipmentname, smsalert, action };
    }
    const rows = [
        createData('Meter Track', "Meter Track", 10, 1, "Active", "KS Site", "eira@gmail.com", "MRV-I", "Device Reset", "Active"),
        createData('Grid Failure', "System Disconnect", 10, 1, "In Active", "KB Site", "eira@gmail.com", "MRV-II", "System Disconnect", "Active"),
        createData('Energy Loss', 'Zero Generation', 10, 1, "Active", "KC Site", "eira@gmail.com", "MRV-III", "Zero Generation", "Active"),
        createData('Meter Track', "Meter Track", 10, 1, "Active", "KS Site", "eira@gmail.com", "MRV-III", "System Disconnect", "In Active"),
        createData('Grid Failure', "System Disconnect", 10, 1, "In Active", "KS Site", "eira@gmail.com", "MRV-I", "System Disconnect", "Active"),
    ];
    useEffect(() => {
        getSiteCall();
    }, []);
    const handleOpen = () => {
        if (chartName === "" && site === "") {
            setOpenToast(true);
            setVarient("error");
            setTextValue("Kindly Select Chart Type And Site Name");
        } else if (timevalue === "custom") {
            setOpenToast(true);
            setOpen(false);
            setVarient("warning");
            setTextValue(
                "Custom Range Datas are Restricted to Store in Wizard Format"
            );
        } else {
            setOpen(true);
        }
    };
    const handleClose = () => setOpen(false);
    const handleCloseToast = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenToast(false);
    };
    /*----------------------------- Paramater Get call ------------------------------*/
    const fetchParams = async (id) => {
        try {
            let data = await fetchParameterListDetails(id);
            setParameterSite(data);
        } catch (e) {
            console.log(e);
        }
    };
    const handleSite = (data, val) => {
        if (val === null) {
            setSite("");
        } else {
            setSite(val);
            fetchEquipmentDetails(val);
        }
    };
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setEquipmentName(typeof value === "string" ? value.split(",") : value);
    };
    const handleChangeEquipment = (event) => {
        const {
            target: { value },
        } = event;
        setEquipmentParamName(typeof value === "string" ? value.split(",") : value);
    };
    /*--------------------------------Equipment Get  ----------------------------*/
    const fetchEquipmentDetails = async (event) => {
        let siteIdFilter = siteIdName?.filter((data) => {
            return data.siteName === event;
        });
        try {
            let data = await fetchEquipmentIdDetails(siteIdFilter[0]?.siteId);
            setEquipments(data);
            let equipmentName = data.map((datav) => {
                return datav.customernaming;
            });
            setEquipmentData(equipmentName);
            fetchParams(siteIdFilter[0]?.siteId);
        } catch (e) {
            console.log(e);
        }
    };
    const handleCancel = () => {
        navigate("/menu/alerts");
    };
    /*-------------------------- Site Get ---------------------*/
    const getSiteCall = async () => {
        try {
            let id = sessionStorage.getItem("id");
            let data = await fetchSiteDetails(id);
            setSiteId(data);
        } catch (e) {
            console.log(e);
        }
    };
    const handleChart = (data, event) => {
        setChartName(event);
        setEquipmentName([]);
        setEquipmentParamName([]);
    };
    const handleTimeChange = (data, event) => {
        if (event === null) {
            setTimeValue("");
        } else {
            setTimeValue(event.value);
        }
    };
    /*------------------------- Custom Range Date -------------------------------*/
    const handleChangeFromDate = (data, value) => {
        if (data !== null) {
            const formattedDateTime = data.format("YYYY-MM-DD");
            setFromDate(formattedDateTime);
        }
    };
    const handleChangeToDate = (data, value) => {
        if (data !== null) {
            const formattedDateTime = data.format("YYYY-MM-DD");
            setToDate(formattedDateTime);
        }
    };
    /*--------------------- Time range ---------------------*/
    useEffect(() => {
        let date = new Date();
        let fromDate = new Date();
        let responseDate = "";
        let toDate = "";
        // console.log(timevalue, "timevalue");
        const formatDate = (inputDate) => {
            const day = String(inputDate.getDate()).padStart(2, "0");
            const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
            const year = inputDate.getFullYear();
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate;
        };
        switch (timevalue) {
            case "today":
                responseDate = moment(date).format("YYYY-MM-DD");
                toDate = moment(date).format("YYYY-MM-DD");
                setFromDate(responseDate);
                setToDate(toDate);
                setRange("daily");
                break;
            case "yesterday":
                date.setDate(date.getDate() - 1);
                responseDate = moment(date).format("YYYY-MM-DD");
                toDate = moment(date).format("YYYY-MM-DD  ");
                setFromDate(responseDate);
                setToDate(responseDate);
                setRange("daily");
                break;
            case "last 7 days":
                fromDate.setDate(date.getDate() - 7);
                responseDate = moment(fromDate).format("YYYY-MM-DD");
                toDate = moment(date).format("YYYY-MM-DD  ");
                setFromDate(responseDate);
                setToDate(toDate);
                setRange("custom");
                break;
            case "this month":
                const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
                const endDate = new Date(date);
                // console.log(startDate, "startDate", endDate, "endDate");
                fromDate.setDate(date.getDate() - 30);
                responseDate = moment(fromDate).format("YYYY-MM-DD");
                toDate = moment(date).format("YYYY-MM-DD");
                setFromDate(formatDate(startDate));
                setToDate(formatDate(endDate));
                setRange("custom");
                break;
            case "last month":
                const firstDayOfLastMonth = new Date(
                    date.getFullYear(),
                    date.getMonth() - 1,
                    1
                );
                const lastDayOfLastMonth = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    0
                );
                fromDate.setDate(date.getDate() - 30);
                responseDate = moment(fromDate).format("YYYY-MM-DD");
                toDate = moment(date).format("YYYY-MM-DD");
                setFromDate(formatDate(firstDayOfLastMonth));
                setToDate(formatDate(lastDayOfLastMonth));
                setRange("custom");
                break;
            case "custom":
                setRange("custom");
                break;
            default:
                break;
        }
    }, [timevalue]);
    /*------------------------------------------  Apply changes ----------------------*/
    const handleApplyChanges = async () => {
        function formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            const seconds = String(date.getSeconds()).padStart(2, "0");
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
        function formatDateTime(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            const seconds = String(date.getSeconds()).padStart(2, "0");
            return `${year}-${month}-${day} 20:05:00`;
        }
        setDatalogValue([site, equipment, equipmentParam]);
        let siteIdFilter = siteIdName?.filter((data) => {
            return data.siteName === site;
        });
        let filteredObjects = equipments?.filter((obj) =>
            equipment.includes(obj.customernaming)
        );
        const equipmentIds = filteredObjects?.map((data) => {
            return data.equipmentid;
        });
        let equipmentParameter = equipmentParam?.map((data) => {
            return data.toLowerCase();
        });
        console.log(equipmentParameter, "equipmentParameter");
        let dataModel = {
            SiteId: siteIdFilter[0]?.siteId,
            EquipmentIds: equipmentIds,
            Parameters: equipmentParameter,
            Range: range,
            timeperiod: timevalue,
            FromDate: fromDate,
            ToDate: toDate,
            GraphType: chartName,
        };
        if (chartName === "Daily Generation") {
            delete dataModel.Parameters;
            delete dataModel.EquipmentIds;
            let responsedata = await DataAnalystSubmit(dataModel);
            try {
                if (range === "daily" || range === "yesterday") {
                    const endTime = new Date(formatDateTime(new Date(toDate)));
                    // console.log(endTime, "formatDate");
                    let data = responsedata.slice(-1);
                    let time = data[0]?.timestamp;
                    let initialTime = new Date(time);
                    const timeStep = 15 * 60 * 1000;
                    while (initialTime.getTime() <= endTime.getTime()) {
                        responsedata?.push({
                            todayEnergy: null,
                            timestamp: formatDate(initialTime),
                        });
                        initialTime.setTime(initialTime.getTime() + timeStep);
                    }
                    console.log(responsedata);
                    setTableData(responsedata);
                } else {
                    console.log(responsedata);
                    setTableData(responsedata);
                }
            } catch (e) {
                console.log(e);
            }
        } else if (chartName === "Energy Performance") {
            delete dataModel.Parameters;
            try {
                let responseData = await equipmentApplyChanges(dataModel);
                let barChartData = responseData;
                console.log(barChartData, "barChartData");
                setTableData(responseData);
                if (range === "daily" || range === "yesterday") {
                    const equipmentMap = {};
                    equipments.forEach((equipment) => {
                        equipmentMap[equipment.equipmentid] = equipment.customernaming;
                    });
                    const seriesData = [];
                    equipments.forEach((equipment) => {
                        const series = {
                            name: equipment.customernaming,
                            data: [],
                        };
                        const equipmentData = responseData.filter(
                            (data) => data.equipmentId === equipment.equipmentid
                        );
                        console.log(equipmentData, "equipmentDatasssss");
                        equipmentData.forEach((data) => {
                            const timestamp = new Date(data.timestamp).getTime();
                            const value = data.todayEnergy !== null ? data.todayEnergy : 0;
                            series.data.push([timestamp, value]);
                        });
                        seriesData.push(series);
                    });
                    console.log(seriesData, "seriesData");
                    let finalArray = seriesData?.filter((value) => {
                        return value.data.length > 0;
                    });
                    console.log(finalArray, "finalArray");
                    setMultichartData(finalArray);
                } else {
                    const convertedData = barChartData.reduce((result, item) => {
                        console.log(result, item);
                        const found = result.find(
                            (x) => x.name === item.equipmentId.toString()
                        );
                        if (found) {
                            found.data.push(item.todayEnergy);
                        } else {
                            result.push({
                                name: item.equipmentId.toString(),
                                data: [item.todayEnergy],
                            });
                        }
                        return result;
                    }, []);
                    let converteddata = convertedData;
                    let dataMap = new Map(
                        equipments.map((item) => [
                            String(item.equipmentid),
                            item.customernaming,
                        ])
                    );
                    converteddata.forEach((item) => {
                        if (dataMap.has(item.name)) {
                            item.name = dataMap.get(item.name);
                        }
                    });
                    console.log("convertedData", convertedData);
                    setBarchartData(convertedData);
                    setTableData(responseData);
                }
            } catch (e) {
                console.log(e);
            }
        } else if (chartName === "Parameter Comparision") {
            try {
                console.log(dataModel, "dataModel");
                let responseData = await parameterComparisonApplyChanges(dataModel);
                //  let responseData = dataAddon
                setTableData(responseData);
                console.log(responseData, "responsedata ");
                const equipmentMap = {};
                const dataFields = equipmentParameter;
                console.log(dataFields, "dataFields");
                const seriesData = [];
                dataFields.forEach((field) => {
                    responseData.forEach((data) => {
                        const timestamp = new Date(data.timestamp).getTime();
                        const value = data[field] !== null ? Number(data[field]) : 0;
                        const seriesName = `${data.equipmentid} - ${field}`;
                        const existingSeries = seriesData.find(
                            (series) => series.name === seriesName
                        );
                        if (existingSeries) {
                            existingSeries.data.push([timestamp, value]);
                        } else {
                            const newSeries = {
                                name: seriesName,
                                data: [[timestamp, value]],
                            };
                            seriesData.push(newSeries);
                            console.log(seriesData, "newSeries");
                            setMultichartData(seriesData);
                        }
                    });
                });
            } catch (e) {
                console.error(e);
            }
        }
    };
    /*----------------------------- Submit Call ------------------------------*/
    const handleSubmit = async () => {
        // console.log(timevalue, "custom")
        // const siteIdFilter = siteIdName?.filter((data) => { return (data.siteName === site) })
        let siteIdFilter = siteIdName?.filter((data) => {
            return data.siteName === site;
        });
        let filteredObjects = equipments?.filter((obj) =>
            equipment.includes(obj.customernaming)
        );
        const equipmentIds = filteredObjects?.map((data) => {
            return data.equipmentid;
        });
        let equipmentParameter = equipmentParam?.map((data) => {
            return data.toLowerCase();
        });
        let obj = {
            SiteId: siteIdFilter[0]?.siteId,
            GraphType: chartName,
            UserId: sessionStorage.getItem("id"),
            EquipmentIds: [],
            Parameters: [],
            Range: range,
            WidgetName: `${widgetName} - ${site}`,
            TimePeriod: timevalue,
            ActiveFlag: 1,
        };
        try {
            if (chartName === "Daily Generation") {
            } else if (chartName === "Energy Performance") {
                obj.EquipmentIds = equipmentIds;
            } else {
                obj.EquipmentIds = equipmentIds;
                obj.Parameters = equipmentParameter;
            }
            let responsedata = await SubmitDataAnalyst(obj);
            if (responsedata == "CREATED") {
                setOpenToast(true);
                setOpen(false);
                setWidgetName("");
                setChartName("");
                setVarient("success");
                setDatalogValue([]);
                navigate("/menu/analytics?success=true");
                setTextValue("Your Wizard Saved Successfully");
            }
        } catch (e) {
            console.log(e);
        }
    };
    // console.log(tableData, "tableData");
    /*-------------------Modal Component--------------------*/
    const stylemodal = {
        margin: "20px 0px 0px 0px",
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: "78%",
        height: "55vh",
        bgcolor: 'background.paper',
        p: 4,
        marginLeft: "5%"
    };
    const [modalopen, setmodalOpen] = React.useState(false);
    const handlemodalOpen = () => {
        setmodalOpen(true);
    };
    const handlemodalClose = () => {
        setmodalOpen(false);
    };
    return (
        <div className="">
            {/* <div className="analytics">
                <div style={{ marginLeft: "2.5%" }}>
                    <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                        <KeyboardBackspaceIcon style={{ verticalAlign: 'middle', fontSize: '1.7rem', marginRight: '8px' }} onClick={handleCancel} />
                        Add Alert
                    </Typography>
                </div>
                <div style={{ marginRight: "0.5%" }}>
                    <Stack spacing={2} direction="row" >
                        <Button
                            variant="outlined"
                            style={buttonStyle}
                            // onClick={handleCancel}
                            onClick={handlemodalOpen}
                        >
                            Create New Alert
                        </Button>
                        <Button
                            variant="contained"
                            style={buttonStyle}
                            onClick={handleOpen}
                        >
                            Save
                        </Button>
                    </Stack>
                </div>
            </div> */}
            <div>
                {/* <Box
                sx={{
                    width: "100%",
                    height: 50,
                }}
            >
                <Paper elevation={0} style={{ height: "100%", position: "relative" }}>
                    <div style={{ display: "flex", justifyContent: "flex-start" }} >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "0.2%" }}>
                            <TextField
                                id="standard-basic"
                                placeholder="Search here..."
                                variant="outlined"
                                size='small'
                                // sx={{ width: "100%" }}
                                sx={{ flex: 1, width: "300px", marginLeft: "15%" }}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start"
                                    >
                                        <Button style={{ color: "#000440" }}><SearchOutlinedIcon /></Button>
                                    </InputAdornment>),
                                }}
                            // value={searchInput}
                            // onChange={handleSearchInputChange}
                            />
                        </div>
                        <div style={{ position: "absolute", bottom: '6px', right: "150px" }}>
                            <Stack spacing={3} direction="row">
                                <Button
                                    variant="contained"
                                    style={buttonStyle}
                                // onClick={handleOpenCreateAlertModal}
                                // onClick={handleNavigate}
                                >
                                    Customize Alert
                                </Button>
                            </Stack>
                        </div>
                        <div style={{ position: "absolute", bottom: '10px', right: '50px' }}>
                            <Button onClick={handleOpenSettingsModal} style={{ color: "#000440" }}> <SettingsOutlinedIcon /></Button>
                        </div>
                    </div>
                </Paper>
            </Box> */}
                <Box
                    sx={{
                        width: "100%",
                        height: 50,
                    }}
                >
                    <Paper elevation={0} style={{ height: "100%", position: "relative" }}>
                        <div style={{ display: "flex", justifyContent: "flex-start" }} >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "0.2%" }}>
                                <Typography>
                                    <Link to="/menu/alerts" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                        <KeyboardBackspaceIcon
                                            style={{ verticalAlign: 'middle', fontSize: '1.7rem', cursor: 'pointer' }}
                                        />
                                        Add Alerts
                                    </Link>  </Typography>
                                <TextField
                                    id="standard-basic"
                                    placeholder="Search here..."
                                    variant="outlined"
                                    size='small'
                                    // sx={{ width: "100%" }}
                                    sx={{ flex: 1, width: "300px", marginLeft: "15%" }}
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start"
                                        >
                                            <Button style={{ color: "#000440" }}><SearchOutlinedIcon /></Button>
                                        </InputAdornment>),
                                    }}
                                    value={searchInput}
                                    onChange={handleSearchInputChange}
                                />
                            </div>
                            <div style={{ position: "absolute", bottom: '6px', right: "150px" }}>
                                <Stack spacing={3} direction="row">
                                    <Button
                                        variant="contained"
                                        style={buttonStyle}
                                        // onClick={handleCancel}
                                        onClick={handlemodalOpen}
                                    >
                                        Create New Alert
                                    </Button>
                                </Stack>
                            </div>
                            <div style={{ position: "absolute", bottom: '10px', right: '50px' }}>
                                <Button onClick={handleOpenSettingsModal} style={{ color: "#000440" }}> <SettingsOutlinedIcon /></Button>
                            </div>
                        </div>
                    </Paper>
                </Box>
                <div>
                    <Box sx={{
                        display: 'flex', flexWrap: 'wrap', '& > :not(style)': {
                            mr: 0, mb: 2, mt: 2, width: '100%',
                            minHeight: '7vh',
                            maxHeight: 'max-content',
                            m: 0,
                            padding: '0'
                        },
                    }}>
                    </Box >
                    <div>
                        <Box sx={{
                            width: '100%', minHeight: '65vh',
                            maxHeight: 'max-content',
                        }}>
                            <Paper sx={{ width: '100%', mb: 2, marginTop: "0.5%" }} elevation={0}>
                                <TableContainer style={{ height: '65vh' }}>
                                    <Table
                                        sx={{ minWidth: 750, }}
                                        aria-labelledby="tableTitle"
                                        stickyHeader aria-label="sticky table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {selectsiteLoading && <TableCell> Select Site Alert</TableCell>}
                                                {equipmentsLoading && <TableCell align="center">Equipments</TableCell>}
                                                {maximumrangeLoading && <TableCell align="center">Maximum Range</TableCell>}
                                                {minimumrangeLoading && <TableCell align="center">Minimum Range</TableCell>}
                                                {emailidLoading && <TableCell align="center">Email Id</TableCell>}
                                                {descriptionLoading && <TableCell align="center">Description</TableCell>}
                                                {parameterLoading && <TableCell align="center">Parameter</TableCell>}
                                                {statusLoading && <TableCell align="center">Status</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {rows.map((row) => ( */}
                                            {searchInput
                                                ? siteEnergy
                                                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                                                    .map((row) => (
                                                        <TableRow
                                                            key={row.selectsite}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="center"
                                                                style={{ cursor: "pointer", textDecoration: 'underline' }}
                                                            // onClick={() => handleRowClick(row.alerts)}
                                                            >
                                                                {row.selectsite}
                                                            </TableCell>
                                                            {selectsiteLoading && (<TableCell align="center">{row.selectsite}</TableCell>)}
                                                            {equipmentsLoading && (<TableCell align="center">{row.equipment}</TableCell>)}
                                                            {maximumrangeLoading && (<TableCell align="center">{row.maximumrange}</TableCell>)}
                                                            {minimumrangeLoading && (<TableCell align="center">{row.minimumrange}</TableCell>)}
                                                            {emailidLoading && (<TableCell align="center">{row.emailid}</TableCell>)}
                                                            {descriptionLoading && (<TableCell align="center">{row.description}</TableCell>)}
                                                            {parameterLoading && (<TableCell align="center">{row.parameter}</TableCell>)}
                                                            {statusLoading && (<TableCell align="center">{row.status}</TableCell>)}
                                                            {/* <TableCell align='center'> < TrendingUpIcon /> <AlarmIcon /> </TableCell> */}
                                                            {/* {statusLoading && (<TableCell style={{ textAlign: "center", fontSize: "14px" }} >< TrendingUpIcon /> <AlarmIcon /> </TableCell>)} */}
                                                        </TableRow>
                                                    )) : rows
                                                        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                                                        .map((row) => (
                                                            <TableRow
                                                                key={row.selectsite}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell align="center"
                                                                // style={{ cursor: "pointer", textDecoration: 'underline' }}
                                                                // onClick={() => handleRowClick(row.alerts)}
                                                                >
                                                                    {row.selectsite}
                                                                </TableCell>
                                                                {selectsiteLoading && (<TableCell align="center">{row.selectsite}</TableCell>)}
                                                                {equipmentsLoading && (<TableCell align="center">{row.equipment}</TableCell>)}
                                                                {maximumrangeLoading && (<TableCell align="center">{row.maximumrange}</TableCell>)}
                                                                {minimumrangeLoading && (<TableCell align="center">{row.minimumrange}</TableCell>)}
                                                                {emailidLoading && (<TableCell align="center">{row.emailid}</TableCell>)}
                                                                {descriptionLoading && (<TableCell align="center">{row.description}</TableCell>)}
                                                                {parameterLoading && (<TableCell align="center">{row.parameter}</TableCell>)}
                                                                {statusLoading && (<TableCell align="center">{row.status}</TableCell>)}
                                                                {/* <TableCell align='center'> < TrendingUpIcon /> <AlarmIcon /> </TableCell> */}
                                                                {/* {statusLoading && (<TableCell style={{ textAlign: "center", fontSize: "14px" }} >< TrendingUpIcon /> <AlarmIcon /> </TableCell>)} */}
                                                            </TableRow>
                                                        ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    // count={tableValue.length}
                                    count={siteEnergy?.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Box>
                    </div>
                    <div>
                        <Modal
                            open={modalopen}
                            // open={handlemodalOpen}
                            onClose={handlemodalClose}
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            sx={{ marginTop: "1.5%" }}
                        >
                            <div
                                className="monitoring-points"
                                style={{ marginLeft: "2%", marginTop: "1%" }}
                            >
                                <Box
                                    sx={stylemodal}
                                >
                                    <Paper elevation={0}>
                                        <div className="analytics">
                                            <div style={{ marginLeft: "2.5%" }}>
                                                <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                                                    {/* <KeyboardBackspaceIcon style={{ verticalAlign: 'middle', fontSize: '1.7rem', marginRight: '8px' }} onClick={handleCancel} /> */}
                                                    New Alert
                                                </Typography>
                                            </div>
                                            <div style={{ marginRight: "0.5%" }}>
                                                <Stack spacing={2} direction="row">
                                                    <Button
                                                        variant="outlined"
                                                        style={buttonStyles}
                                                        onClick={handlemodalClose}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        style={buttonStyles}
                                                        onClick={handleOpen}
                                                    >
                                                        Save
                                                    </Button>
                                                </Stack>
                                            </div>
                                        </div>
                                        <div style={{ margin: "80px 0 0 10px" }}>
                                            <div>
                                                <Stack spacing={0} direction="row">
                                                    <div style={{ flex: 1, minWidth: 250 }}>
                                                        <Typography style={typoStyle}>Select Site </Typography>
                                                        <Autocomplete
                                                            size="small"
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={siteIdName.map((option) => option.siteName)}
                                                            onChange={(data, event) => handleSite(data, event)}
                                                            sx={{ width: "70%" }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} placeholder="Select Site ..." />
                                                            )}
                                                            classes={{ option: "autocomplete" }}
                                                        />
                                                    </div>
                                                    <div style={{ flex: 1, minWidth: 250 }}>
                                                        <FormControl sx={{ width: '70%' }}>
                                                            <Typography style={typoStyle}>Equipments</Typography>
                                                            <Select
                                                                id="demo-multiple-checkbox"
                                                                multiple
                                                                disabled={
                                                                    chartName === "Daily Generation" ? true : false
                                                                }
                                                                size="small"
                                                                value={equipment}
                                                                onChange={handleChange}
                                                                input={<OutlinedInput />}
                                                                MenuProps={MenuProps}
                                                                renderValue={(selected) => selected.join(",")}
                                                                placeholder="Select Equipments ..."
                                                            >
                                                                <MenuItem value="Select All">
                                                                    <Checkbox
                                                                        checked={equipment.length === equipmentData.length}
                                                                    />
                                                                    <ListItemText primary="Select All" />
                                                                </MenuItem>
                                                                {equipmentData.map((name) => (
                                                                    <MenuItem
                                                                        key={name}
                                                                        value={name}
                                                                        className="autocomplete"
                                                                    >
                                                                        <Checkbox checked={equipment.includes(name)} />
                                                                        <ListItemText
                                                                            primary={name}
                                                                            className="autocomplete"
                                                                        />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div style={{ flex: 1, minWidth: 250 }}>
                                                        <FormControl sx={{ width: '70%' }}>
                                                            <Typography style={typoStyle}>
                                                                Parameter
                                                            </Typography>
                                                            <Select
                                                                id="demo-multiple-checkbox"
                                                                multiple
                                                                size="small"
                                                                // disabled={
                                                                //     chartName === "Parameter Comparision" ? false : true
                                                                // }
                                                                value={equipmentParam}
                                                                onChange={handleChangeEquipment}
                                                                input={<OutlinedInput />}
                                                                renderValue={(selected) => selected.join(",")}
                                                                MenuProps={MenuProps}
                                                            >
                                                                <MenuItem value="Select All">
                                                                    <Checkbox
                                                                        checked={equipment.length === equipmentData.length}
                                                                    />
                                                                    <ListItemText primary="Select All" />
                                                                </MenuItem>
                                                                {equipmentParameter.map((name) => (
                                                                    <MenuItem
                                                                        key={name}
                                                                        value={name}
                                                                        className="autocomplete"
                                                                    >
                                                                        <Checkbox
                                                                            checked={equipmentParam.indexOf(name) > -1}
                                                                        />
                                                                        <ListItemText
                                                                            primary={name}
                                                                            className="autocomplete"
                                                                        />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div style={{ flex: 1, minWidth: 250 }}>
                                                        <Typography style={typoStyle}>Maximum Range</Typography>
                                                        <TextField
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            size="small"
                                                            options={siteIdName.map((option) => option.siteName)}
                                                            onChange={(data, event) => handleSite(data, event)}
                                                            sx={{ width: "70%" }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} placeholder="Select  Site ..." />
                                                            )}
                                                            classes={{ option: "autocomplete" }}
                                                        />
                                                    </div>
                                                </Stack>
                                            </div>
                                            <div style={{ margin: "60px 0 0 0" }} >
                                                <Stack spacing={0} direction="row">
                                                    <div style={{ flex: 1, minWidth: 250 }}>
                                                        <Typography style={typoStyle}>Minimum Range</Typography>
                                                        <TextField
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            size="small"
                                                            options={siteIdName.map((option) => option.siteName)}
                                                            onChange={(data, event) => handleSite(data, event)}
                                                            sx={{ width: "70%" }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} placeholder="Select  Site ..." />
                                                            )}
                                                            classes={{ option: "autocomplete" }}
                                                        />
                                                    </div>
                                                    <div style={{ flex: 1, minWidth: 250 }}>
                                                        <Typography style={typoStyle}>Email Id</Typography>
                                                        <TextField
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            size="small"
                                                            options={siteIdName.map((option) => option.siteName)}
                                                            onChange={(data, event) => handleSite(data, event)}
                                                            sx={{ width: "70%" }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} placeholder="Select  Site ..." />
                                                            )}
                                                            classes={{ option: "autocomplete" }}
                                                        />
                                                    </div>
                                                    <div style={{ flex: 1, minWidth: 250 }}>
                                                        <Typography style={typoStyle}>Description</Typography>
                                                        <TextField
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            size="small"
                                                            options={siteIdName.map((option) => option.siteName)}
                                                            onChange={(data, event) => handleSite(data, event)}
                                                            sx={{ width: "70%" }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} placeholder="Select  Site ..." />
                                                            )}
                                                            classes={{ option: "autocomplete" }}
                                                        />
                                                    </div>
                                                    <div style={{ flex: 1, minWidth: 250 }}>
                                                        <FormControl sx={{ width: '70%' }}>
                                                            <Typography style={typoStyle}>
                                                                Parameter
                                                            </Typography>
                                                            <Select
                                                                id="demo-multiple-checkbox"
                                                                multiple
                                                                size="small"
                                                                // disabled={
                                                                //     chartName === "Parameter Comparision" ? false : true
                                                                // }
                                                                value={equipmentParam}
                                                                onChange={handleChangeEquipment}
                                                                input={<OutlinedInput />}
                                                                renderValue={(selected) => selected.join(",")}
                                                                MenuProps={MenuProps}
                                                            >
                                                                <MenuItem value="Select All">
                                                                    <Checkbox
                                                                        checked={equipment.length === equipmentData.length}
                                                                    />
                                                                    <ListItemText primary="Select All" />
                                                                </MenuItem>
                                                                {equipmentParameter.map((name) => (
                                                                    <MenuItem
                                                                        key={name}
                                                                        value={name}
                                                                        className="autocomplete"
                                                                    >
                                                                        <Checkbox
                                                                            checked={equipmentParam.indexOf(name) > -1}
                                                                        />
                                                                        <ListItemText
                                                                            primary={name}
                                                                            className="autocomplete"
                                                                        />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Stack>
                                            </div>
                                        </div>
                                    </Paper>
                                </Box>
                            </div>
                        </Modal>
                    </div>
                    <div >
                        <Modal
                            open={isSettingsModalOpen}
                            onClose={handleCloseSettingsModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Paper elevation={0} className='total-custom' sx={{
                                width: '18%', height: 'max-content', overflow: "hidden",
                                backgroundColor: 'aliceblue', padding: '10px', marginLeft: '81%', marginTop: "9.5%"
                            }}>
                                <div >
                                    <Grid container spacing={10}>
                                        <Grid item xs={6} md={14}>
                                            <Typography style={{ color: "rgb(18, 127, 216)", marginLeft: "70%", marginTop: "4%", cursor: "pointer" }} onClick={handleClose}>
                                                <CloseOutlinedIcon />
                                            </Typography>
                                            {selectsiteLoading ? (
                                                <FormControlLabel
                                                    sx={{
                                                        display: 'block',
                                                    }}
                                                    control={
                                                        <Switch
                                                            checked={selectsiteLoading}
                                                            onChange={() => setselectsiteLoading(!selectsiteLoading)}
                                                            color="primary"
                                                            disabled
                                                        />
                                                    }
                                                    label="Alerts"
                                                />
                                            ) : (
                                                <FormControlLabel
                                                    sx={{
                                                        display: 'block',
                                                    }}
                                                    control={<Switch color="primary" />}
                                                    label="Alerts"
                                                />
                                            )}
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={equipmentsLoading}
                                                        onChange={() => setequipmentsLoading(!equipmentsLoading)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Equipments"
                                            />
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={maximumrangeLoading}
                                                        onChange={() => setmaximumrangeLoading(!maximumrangeLoading)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Maximum Range"
                                            />
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={minimumrangeLoading}
                                                        onChange={() => setminimumrangeLoading(!minimumrangeLoading)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Minimum Range "
                                            />
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={emailidLoading}
                                                        onChange={() => setemailidLoading(!emailidLoading)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Email Id "
                                            />
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={descriptionLoading}
                                                        onChange={() => setdescriptionLoading(!descriptionLoading)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Description"
                                            />
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={parameterLoading}
                                                        onChange={() => setparameterLoading(!parameterLoading)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Parameter"
                                            />
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={statusLoading}
                                                        onChange={() => setstatusLoading(!statusLoading)}
                                                        color="primary"
                                                    />
                                                }
                                                label=" status "
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                        </Modal>
                    </div>
                </div >
            </div>
        </div>
    );
};
export default NewAlert;
