import React, { useMemo } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const YieldCharts = (props) => {
  const { tableValue } = props
  const getYieldChartValue = useMemo(() => {
    let chartYieldValue = {
      performanceRatio: [],
      customerNaming: [],
    }
    tableValue && tableValue?.equipmentList?.forEach(value => {
      chartYieldValue = {
        ...chartYieldValue, performanceRatio: [...chartYieldValue?.performanceRatio, Number(value?.performanceRatio)],
        customerNaming: [...chartYieldValue?.customerNaming, value?.customerNaming]

      }
    })
    return chartYieldValue
  }, [tableValue])

  const lineChartOptions = {
    chart: {
      type: 'line',
   
    },
    credits: {
       enabled: false 
       },
    title: {
      text: 'Specific Yield',
    },
    xAxis: {
      categories: getYieldChartValue?.customerNaming,
    },

    yAxis: {
      min: 0, max: 10,
      title: {
        text: 'Specific Yield',
      },
    },
    series: [
      {
        showInLegend: false,
        data: getYieldChartValue?.performanceRatio,
      },
    ],
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={lineChartOptions} />
    </div>
  )
}
export default YieldCharts
