import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import {
    Box, Button, InputAdornment, TextField, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, Modal, TableSortLabel, Typography, Stack, Grid
} from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Switch from '@mui/material/Switch';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { SiteList } from '../../Api/SiteListApi'

import FadeLoader from "react-spinners/FadeLoader";
function createData(name, calories, fat, carbs, protein) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3, 9),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];
const headCells = [
    {
        id: 'name',
        label: 'Site Name',
    },
    {
        id: 'name',
        label: 'Site Id',
    },
    {
        id: 'calories',
        label: 'Status',
    },
    {
        id: 'fat',
        label: 'Today Energy (kWh)',
    },
    {
        id: 'carbs',
        label: 'Equipment Type',
    },
    {
        id: 'protein',
        label: 'Status',
    },
    {
        id: 'protein',
        label: 'Today Energy',
    },
    {
        id: 'protein',
        label: 'Total Energy',
    },
    {
        id: 'protein',
        label: 'Last Data Received ',
    },
]; function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    
    return (
        <TableHead stickyHeader>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        sortDirection={orderBy === headCell.id ? order : false} >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function Site() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [siteEnergy, setSiteEnergy] = useState([]);
    const [loading,setloading]=useState()
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [filteredSiteEnergy, setFilteredSiteEnergy] = useState(siteEnergy);
    const defaultLoadingStates = {
        siteName: true,
        status: true,
        todayEnergy: true,
        totalEnergy: true,
        specificYield: true,
        inverters: true,
        capacity: true,
        lastUpdated: true,
    };
    const [siteNameLoading, setSiteNameLoading] = useState(defaultLoadingStates.siteName);
    const [statusLoading, setStatusLoading] = useState(defaultLoadingStates.status);
    const [todayEnergyLoading, setTodayEnergyLoading] = useState(defaultLoadingStates.todayEnergy);
    const [totalEnergyLoading, setTotalEnergyLoading] = useState(defaultLoadingStates.totalEnergy);
    const [specificYieldLoading, setSpecificYieldLoading] = useState(defaultLoadingStates.specificYield);
    const [invertersLoading, setinvertersLoading] = useState(defaultLoadingStates.inverters);
    const [capacityLoading, setcapacityLoading] = useState(defaultLoadingStates.capacity);
    const [lastUpdatedLoading, setlastUpdatedLoading] = useState(defaultLoadingStates.lastUpdated);
    const handleToggle = () => {
        const filteredValues = siteEnergy?.filter((row) => {
            return (
                (siteNameLoading && row.siteName) ||
                (statusLoading && row.status) ||
                (todayEnergyLoading && row.todayEnergy) ||
                (totalEnergyLoading && row.totalEnergy) ||
                (specificYieldLoading && row.specificYield) ||
                (invertersLoading && row.inverters)
                    (capacityLoading && row.capacity) ||
                (lastUpdatedLoading && row.lastUpdated)
            );
        });
        setFilteredSiteEnergy(filteredValues);
    };
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const data = await SiteList();
            setSiteEnergy(data)
            // setSiteList(data)
            setloading(false)
            console.log(data);
        } catch (e) {
            console.log(e);
        }
    };
    console.log(siteEnergy, 'SiteEnergy');
    const sortData = (data, sortOrder, field) => {
        // Sort the data based on the specified field and sortOrder

        const sortedData = [...data];
        // Sort the copy based on the specified field and sortOrder
        sortedData.sort((a, b) => {
            const aValue = a[field];
            const bValue = b[field];
            if (sortOrder === 'asc') {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else if (sortOrder === 'desc') {
                return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
            }
            return 0;
        });
        // Update the state with the sorted data

        setSiteEnergy(sortedData);
    };
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);
        sortData(siteEnergy, newOrder, property); // Use siteEnergy here

    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0); // Reset to the first page when changing rows per page

    };
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, (siteEnergy?.length || 0) - page * rowsPerPage);
    function getStatusIcon(status) {
        switch (status) {
            case 'Active':
                return '#0bdf2a'; 

            case 'Offline':
                return 'grey'; 

            case 'Warning':
                return 'orange'; 

            case 'Down':
                return 'red'; 

            default:
                return 'black'; 

        }
    }
    console.log(siteEnergy, "siteEnergy")
    const handleSetValue = (rowid) => {
        navigate('/menu/SiteOverView', { state: { id: rowid } })
    }
    const specificYiled = (todayEnergy, installationCapacity) => {
        let data = todayEnergy / installationCapacity

        return data.toFixed(1)
    }
    const [searchInput, setSearchInput] = useState('');
    const filteredSiteEnergyData = siteEnergy.filter((row) =>
        // Check if any of the columns' values include the search input (case-insensitive)
        Object.values(row).some((value) =>
            String(value).toLowerCase().includes(searchInput.toLowerCase())
        )
    );
    return (
<div>  {loading ?
(
    <div style={{ display: "flex", justifyContent: "center", marginTop: "20%" }}><FadeLoader color="#000440" loading={true} /></div>) :
(
        <div >
           <Box
                sx={{
                    width: "100%",
                    height: 50,
                }}
            >
                <Paper elevation={0} style={{ height: "100%", position: "relative" }}>
                    <div style={{ display: "flex", justifyContent: "flex-start" }} >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0 0 13px" }}>
                            <Typography>
                                <Link to="/menu" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                    <KeyboardBackspaceIcon
                                        style={{ verticalAlign: 'middle', fontSize: '1.7rem', cursor: 'pointer' }}
                                    />
                                    Site List
                                </Link>  </Typography>
                            <TextField
                                id="standard-basic"
                                placeholder="Search Site Name here..."
                                variant="outlined"
                                size='small'
                                // sx={{ width: "100%" }}
                                sx={{ flex: 1, width: "350px", marginLeft: "15%" }}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start"
                                    >
                                        <Button style={{ color: "#000440" }}><SearchOutlinedIcon /></Button>
                                    </InputAdornment>),
                                }}
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </div>
                       
                        <div style={{ position: "absolute", bottom: '8px', right: '25px' }}>
                        <Button onClick={handleOpen} style={{ color: "#000440" }}> <SettingsOutlinedIcon /></Button>
                        </div>
                    </div>
                </Paper>
            </Box>
       
        
            <div >
                <Box sx={{
                    display: 'flex', flexWrap: 'wrap', '& > :not(style)': {
                        mr: 0, mb: 2, mt: 2, width: '100%',
                        minHeight: '7vh',
                        maxHeight: 'max-content',
                        m: 0,
                        padding: '0'
                    },
                }}>
                </Box >
                <div>
                    <Box sx={{
                        width: '100%', minHeight: '65vh',
                        maxHeight: 'max-content',
                    }}>
                        <Paper sx={{ width: '100%', mb: 2, marginTop: "0.5%" }} elevation={0}>
                            <TableContainer style={{ height: '65vh' }}>
                                <Table

                                    sx={{ minWidth: 750, }}
                                    aria-labelledby="tableTitle"
                                    stickyHeader aria-label="sticky table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            {siteNameLoading && <TableCell align="center">
                                                <TableSortLabel

                                                    // active={orderBy === 'siteName' ? order : 'desc'}
                                                    direction={orderBy === 'siteName' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('siteName')} // Use 'siteName' here

                                                >
                                                    Site Name

                                                </TableSortLabel>
                                            </TableCell>}
                                            {todayEnergyLoading && < TableCell align="center">
                                                <TableSortLabel

                                                    // active={orderBy === 'todayEnergy' ? order : 'desc'}
                                                    direction={orderBy === 'todayEnergy' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('todayEnergy')} // Use 'status' here

                                                >
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <span>Today Energy</span>
                                                        <span>(kWh)</span>
                                                    </div>
                                                </TableSortLabel>
                                            </TableCell>}
                                            {totalEnergyLoading && <TableCell align="center">
                                                <TableSortLabel

                                                    // active={orderBy === 'totalEnergy' ? order : 'desc'}
                                                    direction={orderBy === 'totalEnergy' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('totalEnergy')} // Use 'status' here

                                                >
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <span>Total Energy</span>
                                                        <span>(kWh)</span>
                                                    </div>
                                                </TableSortLabel>
                                            </TableCell>}
                                            {specificYieldLoading && <TableCell align="center">
                                                <TableSortLabel

                                                    // active={orderBy === 'specificYiled' ? order : 'desc'}
                                                    direction={orderBy === 'specificYiled' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('specificYiled')} // Use 'siteName' here

                                                >
                                                    
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <span>Specific Yield</span>
                                                        <span> (DC)</span>
                                                    </div>

                                                </TableSortLabel>
                                            </TableCell>}
                                            {capacityLoading && <TableCell align="center">
                                                <TableSortLabel

                                                    // active={orderBy === 'installationCapacity' ? order : 'desc'}
                                                    direction={orderBy === 'installationCapacity' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('installationCapacity')} // Use 'status' here

                                                >
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <span>Capacity</span>
                                                        <span>(kW)</span>
                                                    </div>
                                                </TableSortLabel>
                                            </TableCell>}
                                            {invertersLoading && <TableCell align="center">
                                                <TableSortLabel

                                                    // active={orderBy === 'invertersLoading'}
                                                    direction={orderBy === 'invertersLoading' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('invertersLoading')} // Use 'status' here

                                                >
                                                    Inverters
                                                </TableSortLabel>
                                            </TableCell>}
                                            {lastUpdatedLoading && <TableCell align="center">
                                                <TableSortLabel

                                                    // active={orderBy === 'lastDataReceived' ? order : 'desc'}
                                                    direction={orderBy === 'lastDataReceived' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('lastDataReceived')} // Use 'status' here

                                                >Last Updated
                                                </TableSortLabel>
                                            </TableCell>}
                                            {statusLoading && <TableCell align="center">
                                                <TableSortLabel

                                                    // active={orderBy === 'status' ? order : 'desc'}
                                                    direction={orderBy === 'status' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('status')} // Use 'status' here

                                                >
                                                    Status

                                                </TableSortLabel>
                                            </TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredSiteEnergyData
                                            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                                            .map((row, index) => (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.siteName)}
                                                    tabIndex={-1}
                                                    key={row.siteName}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell
                                                        align="left"
                                                        style={{ cursor: "pointer", width: '20%', textDecoration: 'underline' }}
                                                        onClick={() => handleSetValue(row.siteId)}
                                                    >
                                                        {row.siteName}
                                                    </TableCell>
                                                    {todayEnergyLoading && (
                                                        <TableCell align="center" style={{ cursor: "default" }}>
                                                            {Number(row.todayEnergy).toFixed(1)}
                                                        </TableCell>
                                                    )}
                                                    {totalEnergyLoading && (
                                                        <TableCell align="center" style={{ cursor: "default" }}>
                                                            {Number(row.totalEnergy).toFixed(1)}
                                                        </TableCell>
                                                    )}
                                                    {specificYieldLoading && (
                                                        <TableCell align="center" style={{ cursor: "default" }}>
                                                            {specificYiled(row?.todayEnergy, row?.installationCapacity)}
                                                        </TableCell>
                                                    )}
                                                    {capacityLoading && (
                                                        <TableCell align="center" style={{ cursor: "default" }}>
                                                            {row.installationCapacity}
                                                        </TableCell>
                                                    )}
                                                    {invertersLoading && (
                                                        <TableCell align="center" style={{ cursor: "default" }}>
                                                            {row.activeInverterCount} / {row.inverterCount}
                                                        </TableCell>
                                                    )}
                                                    {lastUpdatedLoading && (
                                                        <TableCell align="center" style={{ cursor: "default" }}>
                                                            {row.lastDataReceived}
                                                        </TableCell>
                                                    )}
                                                    {statusLoading && (
                                                        <TableCell
                                                            align="center"
                                                            style={{ cursor: "default", maxWidth: '100px', overflow: 'hidden' }}
                                                        >
                                                            <span
                                                                style={{
                                                                    backgroundColor: getStatusIcon(row.status),
                                                                    color: 'white',
                                                                    padding: '5px 10px',
                                                                    borderRadius: '50px',
                                                                    display: 'inline-block',
                                                                    cursor: 'default',
                                                                    whiteSpace: 'nowrap',
                                                                }}
                                                            >
                                                                {row.status}
                                                            </span>
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            ))}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={siteEnergy?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </div>
                <div >
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Paper elevation={0} className='total-custom' sx={{
                            width: '18%', height: 'max-content', overflow: "hidden",
                            backgroundColor: 'aliceblue', padding: '10px', marginLeft: '81%', marginTop: "9.5%"
                        }}>
                            <div >
                                <Grid container spacing={10}>
                                    <Grid item xs={6} md={14}>
                                        <Typography style={{ color: "rgb(18, 127, 216)", marginLeft: "80%", marginTop: "4%", cursor: "pointer" }} onClick={handleClose}>
                                            <CloseOutlinedIcon />
                                        </Typography>
                                        {siteNameLoading ? (
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={siteNameLoading}
                                                        onChange={() => setSiteNameLoading(!siteNameLoading)}
                                                        color="primary"
                                                        disabled
                                                    />
                                                }
                                                label="Site Name"
                                            />
                                        ) : (
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={<Switch color="primary" />}
                                                label="Site Name"
                                            />
                                        )}
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={statusLoading}
                                                    onChange={() => setStatusLoading(!statusLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Status"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={todayEnergyLoading}
                                                    onChange={() => setTodayEnergyLoading(!todayEnergyLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Today Energy"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={totalEnergyLoading}
                                                    onChange={() => setTotalEnergyLoading(!totalEnergyLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Total Energy"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={specificYieldLoading}
                                                    onChange={() => setSpecificYieldLoading(!specificYieldLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Specific Yield "
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={capacityLoading}
                                                    onChange={() => setcapacityLoading(!capacityLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Capacity "
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={invertersLoading}
                                                    onChange={() => setinvertersLoading(!invertersLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Inverters"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={lastUpdatedLoading}
                                                    onChange={() => setlastUpdatedLoading(!lastUpdatedLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Last Updated "
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Modal>
                </div>
            </div >
        </div >)}
        </div>
    );
}