import axios from "axios";
export const fetchEquipmentTable = async (id) => {

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_SITEOVERVIEW_SITE_DETAILS}=${id}`);
    console.log(response, "response");
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}


// ==============Equipment List API=================import axios from "axios";
export const fetchEquipmentlistTable = async (id) => {

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_SITEOVERVIEW_EQUIPMENT_DETAILS}=${id}`);
    console.log(response, "response");
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};