import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./Signup.css"
import Eira from '../../images/eiralogo.jpg';
import webdyn  from '../../images/webdyn.png';

function Copyright(props) {
  return (

    <Typography variant="body2" color="black" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" target='_blank' href="https://inspire-ce.com">
        Inspire-ce.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>

  );
}
const defaultTheme = createTheme();

export default function SignUp() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [contact, setContact] = useState('');
  const [isValidContact, setIsValidContact] = useState(true);

  // Validate the phone number using a regular expression
  const handleContactChange = (event) => {
    const newContact = event.target.value;
    setContact(newContact);
    setIsValidContact(/^\d{10}$/.test(newContact));
  };


  // Basic email validation using a regular expression
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailError(!isValidEmail(newEmail));
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className="background-container"> </div>
      <div class="overlay"></div>

      <div className="signup-container">
        <Container component="main" className='background-overlay' maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
          <img src={ webdyn} className='image' style={{width:"10vw",marginLeft:"-1.5vw",marginTop:"-6vh"}}/> 
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" gutterBottom>
                    First Name
                  </Typography>
                  <TextField
                    autoComplete="off"
                    name="firstName"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" gutterBottom>
                    Last Name
                  </Typography>
                  <TextField
                    autoComplete="off"
                    name="lastName"
                    required
                    fullWidth

                  />
                </Grid>
                <Grid item xs={12} >
                  <Typography variant="body1" gutterBottom>
                    Email-ID
                  </Typography>
                  <TextField
                    autoComplete="off"
                    name="companyName"
                    required
                    fullWidth
                    type="email" // Set input type to "email"
                    value={email}
                    onChange={handleEmailChange}
                    error={emailError}
                    helperText={
                      emailError ? (
                        <span style={{ color: '#ff0f0f' }}>Please enter a valid email address</span>
                      ) : (
                        ''
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} >
                  <Typography variant="body1" gutterBottom>
                    Phone Number
                  </Typography>
                  <TextField
                    autoComplete="off"
                    name="contact"
                    required
                    fullWidth
                    variant="outlined"
                    value={contact}
                    onChange={handleContactChange}
                    error={!isValidContact}
                    helperText={
                      !isValidContact ? (
                        <span style={{ color: '#ff0f0f' }}>Please enter a valid 10-digit phone number</span>
                      ) : (
                        ''
                      )
                    }
                  />



                </Grid>


                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    Company Name
                  </Typography>
                  <TextField
                    autoComplete="off"
                    name="emailid"
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <div className="checkbox-container">
                    <Checkbox value="allowExtraEmails" color="primary" />

                    <Typography variant="body1" gutterBottom>
                      I want to receive inspiration, marketing promotions and updates via email.
                    </Typography>
                  </div>

                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, textTransform: 'capitalize' }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/" variant="body2" className='link-signup'>
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </div>

    </ThemeProvider >
  );
}