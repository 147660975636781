import React, { useEffect, useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import './SiteStyle.css';
//---------------------mui---------------------//
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  Button,
  TableContainer,
  TableHead,
  FormControlLabel,
  TablePagination,
  TableRow,
  Modal,
  TableSortLabel,
  Typography,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import Switch from '@mui/material/Switch';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const style = {
  position: 'absolute',
  top: '64%',
  center: '50%',
  transform: 'translate(-50%, -50%)',
  width: '17%',
  height: '40vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(4),
}));
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}
const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3, 9),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];
const headCells = [
  {
    id: 'name',
    label: 'Site Name',
  },
  {
    id: 'name',
    label: 'Site Id',
  },
  {
    id: 'calories',
    label: 'Status',
  },
  {
    id: 'fat',
    label: 'Today Energy (kWh)',
  },
  {
    id: 'carbs',
    label: 'Equipment Type',
  },
  {
    id: 'protein',
    label: 'Status',
  },
  {
    id: 'protein',
    label: 'Today Energy',
  },
  {
    id: 'protein',
    label: 'Total Energy',
  },
  {
    id: 'protein',
    label: 'Last Data Received ',
  },
];
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead stickyHeader>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
export default function SensorTable(props) {
  const { tableValue, isModalOpen, toggleModal } = props;
  console.log(tableValue);
  const Navigate = useNavigate();
  const [filteredTableValue, setfilteredTableValue] = useState(tableValue);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('asc');
  const [siteEnergy, setSiteEnergy] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('');
  const [dense, setDense] = React.useState(false);
  const [sortedData, setSortedData] = useState();
  const [sortColumn, setSortColumn] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [ascending, setAscending] = useState(true);
  const [timeDifference, setTimeDifference] = useState(0);
  const [status, setStatus] = useState('');
  const handlebackicon = () => {
    Navigate('/menu/SiteOverView');
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(4),
  }));
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const defaultLoadingStates = {
    customernaming: true,
    Primaryserialnumber: true,
    equipmentcode: false,
    equipmentType: true,
    status: true,
    ambient: true,
    humidity: true,
    irradiance: true,
    irradiation: true,
    windDirection: true,
    windSpeed: true,
    lastDataReceived: true,
  };
  const [customerNameLoading, setCustomerNameLoading] = useState(
    defaultLoadingStates.customernaming
  );
  const [PrimaryserialnumberLoading, setSerialNoLoading] = useState(
    defaultLoadingStates.Primaryserialnumber
  );
  const [equipmentcodeLoading, setEquipmentCodeLoading] = useState(
    defaultLoadingStates.equipmentcode
  );
  const [timestampLoading, setLastDataReceivedLoading] = useState(
    defaultLoadingStates.timestamp
  );
  const [statusLoading, setStatusLoading] = useState(
    defaultLoadingStates.status
  );

  const [ambientLoading, setAmbientLoading] = useState(
    defaultLoadingStates.ambient
  );
  const [humidityLoading, setHumidityLoading] = useState(
    defaultLoadingStates.humidity
  );
  const [irradianceLoading, setIrradianceLoading] = useState(
    defaultLoadingStates.irradiance
  );
  const [irradiationLoading, setIrradiationLoading] = useState(
    defaultLoadingStates.irradiation
  );
  const [windDirectionLoading, setWindDirectionLoading] = useState(
    defaultLoadingStates.windDirection
  );
  const [windSpeedLoading, setWindSpeedLoading] = useState(
    defaultLoadingStates.windSpeed
  );

  const handleToggle = () => {
    const filteredValues = tableValue?.filter((row) => {
      return (
        (customerNameLoading && row.customernaming) ||
        (PrimaryserialnumberLoading && row.Primaryserialnumber) ||
        (equipmentcodeLoading && row.equipmentcode) ||
        (timestampLoading && row.timestamp)(statusLoading && row.status)(
          ambientLoading && row.ambienttemperature
        ) ||
        (humidityLoading && row.moduletemperature) ||
        (irradianceLoading && row.irradiance) ||
        (irradiationLoading && row.irradiation) ||
        (windDirectionLoading && row.winddirection) ||
        (windSpeedLoading && row.windspeed)
      );
    });
    setfilteredTableValue(filteredValues);
  };
  useEffect(() => {
    resetToDefaultColumns();
    setRowsPerPage(10);
  }, []);
  const resetToDefaultColumns = useCallback(() => {
    setCustomerNameLoading(defaultLoadingStates.customernaming);
    setSerialNoLoading(defaultLoadingStates.Primaryserialnumber);
    setEquipmentCodeLoading(defaultLoadingStates.equipmentcode);
    setLastDataReceivedLoading(defaultLoadingStates.lastDataReceived);
    setAmbientLoading(defaultLoadingStates.ambient);
    setHumidityLoading(defaultLoadingStates.humidity);
    setIrradianceLoading(defaultLoadingStates.irradiance);
    setIrradiationLoading(defaultLoadingStates.irradiation);
    setWindDirectionLoading(defaultLoadingStates.windDirection);
    setWindSpeedLoading(defaultLoadingStates.windSpeed);
    handleToggle();
  }, [defaultLoadingStates]);
  const handleClickEvent = (rowid) => {
    console.log(rowid);
    Navigate('/menu/equipment', { state: { id: rowid } });
  };
  const LoadingSwitch = ({ label, checked, onChange }) => (
    <FormControlLabel
      sx={{ display: 'block' }}
      disabled={label === 'Equipment Name'}
      control={<Switch checked={checked} onChange={onChange} color="primary" />}
      label={label}
    />
  );
  /*-------------sorting--------------*/
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    sortData(tableValue, newOrder, property);
  };
  const sortData = (data, sortOrder, field) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];
      if (sortOrder === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else if (sortOrder === 'desc') {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
      return 0;
    });
    setfilteredTableValue(sortedData);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.alerts);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function getStatusLabel(networkStatus) {
    console.log(typeof networkStatus, 'networkStatus');
    switch (networkStatus) {
      case '1':
        console.log(networkStatus, 'networkStatus');
        return 'Active';
        break;
      case '0':
        console.log(networkStatus, 'networkStatus');
        return 'Offline';
        break;
      // case '2':
      //   return 'Warning';
      // case '3':
      //   return 'Down';
      default:
        console.log(networkStatus, 'networkStatus');
        return 'Unknown';
    }
  }
  function getStatusIcon(status) {
    switch (status) {
      case '1':
        console.log(status, 'status');
        return '#0bdf2a';
        break;
      case '0':
        console.log(status, 'status');
        return 'grey';
        break;
      // case '2':
      //   return 'orange';
      // case '3':
      //   return 'red';
      default:
        console.log(status, 'status');
        return 'black';
    }
  }

  // function getStatus(time) {
  //   console.log(time);
  //   console.log(time);
  //   if (!time) {
  //     console.log('No time value given');
  //     return 'offline';
  //   }
  //   const functionTimeSet = (time) => {
  //     const inputDate = `${time}`;
  //     const [dayMonthYear, hoursMinutes] = inputDate.split(' ');
  //     const [day, month, year] = dayMonthYear
  //       .split('-')
  //       .map((item) => parseInt(item));
  //     const [hours, minutes] = hoursMinutes
  //       .split(':')
  //       .map((item) => parseInt(item));
  //     const dateInUTC = new Date(
  //       Date.UTC(year, month - 1, day, hours, minutes)
  //     );
  //     const formattedDate = dateInUTC.toISOString().slice(0, 16);
  //     return formattedDate;
  //   };
  //   const functionTime2 = () => {
  //     let currentDate = new Date();
  //     var year = currentDate.getFullYear();
  //     var month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
  //     var day = ('0' + currentDate.getDate()).slice(-2);
  //     var hours = ('0' + currentDate.getHours()).slice(-2);
  //     var minutes = ('0' + currentDate.getMinutes()).slice(-2);
  //     var seconds = ('0' + currentDate.getSeconds()).slice(-2);
  //     // Construct the desired format
  //     var formattedDate =
  //       year + '-' + month + '-' + day + 'T' + hours + ':' + minutes;
  //     return formattedDate;
  //   };
  //   let timegetvalue = functionTimeSet(time);
  //   let timecurreent = functionTime2();
  //   var time1 = new Date(timegetvalue);
  //   var time2 = new Date(timecurreent);
  //   console.log(time1, time2);
  //   var differenceMs = time2 - time1;
  //   // Convert milliseconds to hours and minutes
  //   var differenceMinutes = Math.floor(
  //     (differenceMs % (1000 * 60 * 60)) / (1000 * 60)
  //   );
  //   // Display the difference
  //   console.log(differenceMinutes, 'minutes');

  //   if (differenceMinutes <= 30) {
  //     console.log('online');
  //     return 'online';
  //   } else {
  //     console.log('offline');
  //     return 'ofline';
  //   }
  // }

  let lastCallTime = null;

  function getStatus(time) {
    console.log(time);
    console.log(time);
  
    if (!time) {
      console.log('No time value given');
      return 'offline';
    }
  
    const functionTimeSet = (time) => {
      const inputDate = `${time}`;
      const [dayMonthYear, hoursMinutes] = inputDate.split(' ');
      const [day, month, year] = dayMonthYear
        .split('-')
        .map((item) => parseInt(item));
      const [hours, minutes] = hoursMinutes
        .split(':')
        .map((item) => parseInt(item));
      const dateInUTC = new Date(
        Date.UTC(year, month - 1, day, hours, minutes)
      );
      const formattedDate = dateInUTC.toISOString().slice(0, 16);
      return formattedDate;
    };
  
    const functionTime2 = () => {
      let currentDate = new Date();
      var year = currentDate.getFullYear();
      var month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
      var day = ('0' + currentDate.getDate()).slice(-2);
      var hours = ('0' + currentDate.getHours()).slice(-2);
      var minutes = ('0' + currentDate.getMinutes()).slice(-2);
      var seconds = ('0' + currentDate.getSeconds()).slice(-2);
      // Construct the desired format
      var formattedDate =
        year + '-' + month + '-' + day + 'T' + hours + ':' + minutes;
      return formattedDate;
    };
  
    let timegetvalue = functionTimeSet(time);
    let currentTime = functionTime2();
  
    var time1 = new Date(timegetvalue);
    var time2 = lastCallTime ? lastCallTime : new Date(currentTime);
  
    console.log(time1, time2);
  
    var differenceMs;
    if (time1 > time2) {
      // Future timestamp, treat as "online"
      differenceMs = 0;
    } else {
      // Past timestamp, calculate the difference
      differenceMs = time2 - time1;
    }
  
    // Convert milliseconds to minutes
    var differenceMinutes = Math.floor(differenceMs / (1000 * 60));
  
    // Display the difference
    console.log(differenceMinutes, 'minutes');
  
    // Update the lastCallTime
    lastCallTime = new Date(currentTime);
  
    if (differenceMinutes <= 30) {
      console.log('online');
      return 'online';
    } else {
      console.log('offline');
      return 'offline';
    }
  }


  function checkNullOrUndefined(value) {
    if (value == null || value == undefined) {
      return '-';
    } else {
      if (!isNaN(value) && value % 1 !== 0) {
        return parseFloat(value.toFixed(2));
      } else {
        return value;
      }
    }
  }

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (filteredTableValue?.length || 0) - page * rowsPerPage
    );
  return (
    // <div>
    <div>
      <div>
        <div>
          <div>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={0}>
              <TableContainer sx={{ height: '50vh' }}>
                <Table
                  // sx={{ minWidth: 750, }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      {customerNameLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            // active={orderBy === 'customernaming' ? order : 'desc'}
                            direction={
                              orderBy === 'customernaming' ? order : 'asc'
                            }
                            onClick={() => handleRequestSort('customernaming')} // Use 'status' here
                          >
                            Equipment Name
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {PrimaryserialnumberLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            // active={orderBy === 'primaryserialnumber' ? order : 'desc'}
                            direction={
                              orderBy === 'primaryserialnumber' ? order : 'asc'
                            }
                            onClick={() =>
                              handleRequestSort('primaryserialnumber')
                            } // Use 'status' here
                          >
                            Serial No
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {equipmentcodeLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            // active={orderBy === 'equipmentType' ? order : 'desc'}
                            direction={
                              orderBy === 'equipmentType' ? order : 'asc'
                            }
                            onClick={() => handleRequestSort('equipmentType')}
                            here
                          >
                            Equipment Code
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {ambientLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            direction={orderBy === 'ambient' ? order : 'asc'}
                            onClick={() => handleRequestSort('ambient')}
                          >
                            Ambient Temperature (ºC)
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {humidityLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            direction={orderBy === 'humidity' ? order : 'asc'}
                            onClick={() => handleRequestSort('humidity')}
                          >
                            Module Temperature (ºC)
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {irradianceLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            direction={orderBy === 'irradiance' ? order : 'asc'}
                            onClick={() => handleRequestSort('irradiance')}
                          >
                            Irradiance (W/m²)
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {irradiationLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            direction={
                              orderBy === 'irradiation' ? order : 'asc'
                            }
                            onClick={() => handleRequestSort('irradiation')}
                          >
                            Irradiation (Wh/m²)
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {windDirectionLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            direction={
                              orderBy === 'windDirection' ? order : 'asc'
                            }
                            onClick={() => handleRequestSort('windDirection')}
                          >
                            Wind direction (°)
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {windSpeedLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            className=" mouse-over"
                            direction={orderBy === 'windSpeed' ? order : 'asc'}
                            onClick={() => handleRequestSort('windSpeed')}
                          >
                            Wind Speed (m/s)
                          </TableSortLabel>
                        </TableCell>
                      )}

                      {timestampLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            // active={orderBy === 'timestamp' ? order : 'desc'}
                            direction={orderBy === 'timestamp' ? order : 'asc'}
                            onClick={() => handleRequestSort('timestamp')}
                          >
                            Last Updated
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {statusLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            direction={
                              orderBy === 'networkStatus' ? order : 'asc'
                            }
                            onClick={() => handleRequestSort('networkStatus')}
                          >
                            Status
                          </TableSortLabel>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTableValue
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      // .map((index) => (
                      .map((row, index) => (
                        <TableRow
                          //  key={index}
                          // onClick={(event) => handleClick(event, row.siteName)}
                          key={row.siteName}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          {customerNameLoading && (
                            <TableCell align="center">
                              {' '}
                              {row.customernaming}
                            </TableCell>
                          )}
                          {PrimaryserialnumberLoading && (
                            <TableCell align="center">
                              {' '}
                              {row.primaryserialnumber}
                            </TableCell>
                          )}
                          {equipmentcodeLoading && (
                            <TableCell align="center">
                              {row.equipmentcode}
                            </TableCell>
                          )}
                          {ambientLoading && (
                            <TableCell align="center">
                              {checkNullOrUndefined(row.ambienttemperature)}
                            </TableCell>
                          )}

                          {humidityLoading && (
                            <TableCell align="center">
                              {checkNullOrUndefined(row.moduletemperature)}
                            </TableCell>
                          )}

                          {irradianceLoading && (
                            <TableCell align="center">
                              {checkNullOrUndefined(row.irradiance)}
                            </TableCell>
                          )}

                          {irradiationLoading && (
                            <TableCell align="center">
                              {checkNullOrUndefined(row.irradiation)}
                            </TableCell>
                          )}

                          {windDirectionLoading && (
                            <TableCell align="center">
                              {checkNullOrUndefined(row.winddirection)}
                            </TableCell>
                          )}

                          {windSpeedLoading && (
                            <TableCell align="center">
                              {checkNullOrUndefined(row.windspeed)}
                            </TableCell>
                          )}
                          {timestampLoading && (
                            <TableCell align="center">
                              {row.timestamp}
                            </TableCell>
                          )}
                          {statusLoading && (
                            <TableCell
                              align="center"
                              style={{
                                cursor: 'default',
                                maxWidth: '100px',
                                overflow: 'hidden',
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor:
                                    getStatus(row.timestamp) === 'online'
                                      ? getStatusIcon('1')
                                      : getStatusIcon('0'),
                                  color: 'white',
                                  padding: '5px 10px',
                                  borderRadius: '50px',
                                  display: 'inline-block',
                                  cursor: 'default',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <span style={{ color: 'white' }}>
                                  {/* {timeDifference <= 30 ? getStatusLabel('0') : 'Offline'} */}
                                  {getStatus(row.timestamp) === 'online'
                                    ? getStatusLabel('1')
                                    : getStatusLabel('0')}
                                </span>
                              </span>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableValue?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
        <div>
          <Modal
            open={isModalOpen}
            onClose={toggleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Paper
              elevation={0}
              className="total-equip"
              sx={{
                width: '19%',
                maxWidth: '250px',
                height: 'max-content',
                overflow: 'hidden',
                backgroundColor: 'aliceblue',
                padding: '10px',
                position: 'relative',
                marginLeft: '77%',
                marginTop: '20.5%',
              }}
            >
              <div>
                <Grid container spacing={10}>
                  <Grid item xs={6} md={14}>
                    <Typography
                      style={{
                        color: 'rgb(18, 127, 216)',
                        marginLeft: '80%',
                        marginTop: '4%',
                        cursor: 'pointer',
                      }}
                      onClick={toggleModal}
                    >
                      <CloseOutlinedIcon />
                    </Typography>
                    <LoadingSwitch
                      label="Equipment Name"
                      checked={customerNameLoading}
                      onChange={() =>
                        setCustomerNameLoading(!customerNameLoading)
                      }
                    />
                    <LoadingSwitch
                      label="Primary Serial Number"
                      checked={PrimaryserialnumberLoading}
                      onChange={() =>
                        setSerialNoLoading(!PrimaryserialnumberLoading)
                      }
                    />
                    <LoadingSwitch
                      label="Equipment Code"
                      checked={equipmentcodeLoading}
                      onChange={() =>
                        setEquipmentCodeLoading(!equipmentcodeLoading)
                      }
                    />
                    <LoadingSwitch
                      label="Ambient Temperature"
                      checked={ambientLoading}
                      onChange={() => setAmbientLoading(!ambientLoading)}
                    />
                    <LoadingSwitch
                      label="Module Temperature"
                      checked={humidityLoading}
                      onChange={() => setHumidityLoading(!humidityLoading)}
                    />
                    <LoadingSwitch
                      label="Irradiance"
                      checked={irradianceLoading}
                      onChange={() => setIrradianceLoading(!irradianceLoading)}
                    />
                    <LoadingSwitch
                      label="Irradiation"
                      checked={irradiationLoading}
                      onChange={() =>
                        setIrradiationLoading(!irradiationLoading)
                      }
                    />
                    <LoadingSwitch
                      label="Wind Direction"
                      checked={windDirectionLoading}
                      onChange={() =>
                        setWindDirectionLoading(!windDirectionLoading)
                      }
                    />
                    <LoadingSwitch
                      label="Wind Speed"
                      checked={windSpeedLoading}
                      onChange={() => setWindSpeedLoading(!windSpeedLoading)}
                    />
                    <LoadingSwitch
                      label="Last Data Received"
                      checked={timestampLoading}
                      onChange={() =>
                        setLastDataReceivedLoading(!timestampLoading)
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Modal>
        </div>
      </div>
    </div>
  );
}
