

import React from "react";
import { Paper, Grid, Typography } from "@mui/material";
import { IoIosCloud } from "react-icons/io";

const TotalEmission = ({ SiteEnergy }) => {
  return (
    <Paper
      className="total-sites"
      elevation={0}
      sx={{
        borderRadius: "20px",
        width: "100%",
        height: "92%",
        padding: "20px",
        borderRadius: "13px",
        display: "flex",
        flexDirection: "column", // Use flex column layout
      }}
    >
      <Grid container spacing={1}>
        {/* <Grid item md={8} lg={10} xs={10}>
          <Typography style={{ fontSize: "16px", opacity: "0.7" }}>
            Total Emission
          </Typography>
          <Typography style={{ fontSize: "18px" }}>
            {SiteEnergy?.co2}
          </Typography>
        </Grid> */}
        <Grid item md={8} lg={10} xs={10}>
          <Typography style={{ fontSize: '16px', opacity: '0.7' }}>
            Today CO<span style={{ fontSize: '0.7rem', verticalAlign: 'sub', lineHeight: '0' }}>2</span>  Avoided
          </Typography>
          <Typography style={{ fontSize: "18px" }}>
            {SiteEnergy?.todayCo2Avoided}
          </Typography>
        </Grid>
        <Grid item md={2} lg={2} xs={2}>
          <IoIosCloud style={{ fontSize: "33px", color: "#000440" }} />
        </Grid>
      </Grid>
      {/* <div style={{ margin: "25px 0 0 0" }}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6} style={{}}>
            <Typography style={{ fontSize: "16px", opacity: "0.7" }}>
              Today Co2 Avoided
            </Typography>
            <Typography style={{ fontSize: "18px" }}>
              {SiteEnergy?.todayCo2Avoided}
            </Typography>
          </Grid>
        </Grid>
      </div> */}
    </Paper>
  );
};

export default TotalEmission;
