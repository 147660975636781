import React, { useEffect, useState } from "react";
import "./AnalyticStyle.css";
/*-------------------------- MUi icons -------------------------*/
import DeleteIcon from "@mui/icons-material/Delete";
/*-----------------------------Mui components ---------------------------*/
import {
  Typography,
  Stack,
  Button,
  Grid,
  Paper,
  Snackbar,
  Autocomplete,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import FadeLoader from "react-spinners/FadeLoader";
/*----------------------------------- External Components ---------------------*/
import { getDataAnalyst, WizardDeleteCall } from "../../../Api/DataAnalystApi";
import ApexChart from "./ApexexChart";
import BarChart from "../../Charts/BarChart";
import { fetchSiteDetails } from "../../../Api/ExcelUploadAPi";
import CombinedChart from "../../Charts/CombinedChart";
import AreaChart from "../../Charts/AreaChart";
/*-------------------- Dummy data---------------------------------*/

const buttonStyle = {
  textTransform: "capitalize",
  boxShadow: "4px 4px 17px rgba(0, 0, 0.50, 0.50)",
  fontWeight: "bold",
  // backgroundColor:'#09001A'
};

const Analytics = () => {
  const alertStyle = {
    color: "white", // Text color
    "& .MuiSvgIcon-root": {
      color: "white", // Icon color
    },
  };
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const successParam = searchParams.get("success");
  const [analystData, setAnalystData] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [variantText, setVarient] = useState("");
  const [textValue, setTextValue] = useState("");
  const [analystWizardData, setAnalystWizard] = React.useState([]);
  const [siteListAPI, setSiteList] = React.useState([]);
  const [equipmentListAPI, setEquipmentList] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [reLoading, setReLoading] = useState(true);
  const [siteIdName, setSiteId] = useState([]);
  const [site, setSite] = React.useState("");
  const [customeViewData, setCustomeViewData] = useState({});

  const [openToast, setOpenToast] = React.useState(successParam === "false");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const showSuccessSnackbar = () => {
    setOpenToast(true);
  };

  const handleSite = (data, val) => {
    if (val === null) {
      setSite("");
    } else {
      setSite(val);
    }
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  useEffect(() => {
    getSiteCall();
  }, []);

  useEffect(() => {
    setAnalystData([]);
    setAnalystWizard([]);
    getAnalystCall();
  }, [site]);

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDetails(id);
      if (data.length > 0) {
        setSiteId([...new Set(data)]);
        setSite(data[0]?.siteName);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getAnalystCall = async () => {
    let id = sessionStorage.getItem("id");
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });
    setLoading(true);

    if (siteIdFilter && siteIdFilter.length > 0) {
      let obj = {
        userId: id,
        siteId: siteIdFilter[0]?.siteId,
      };
      let getanalyst = await getDataAnalyst(obj);
      setLoading(false);
      setAnalystData(getanalyst);
      // customViewFunction(getanalyst.widgetList);
      setAnalystWizard(getanalyst.widgetList);
      setSiteList(getanalyst.siteList);
      setEquipmentList(getanalyst.equipmentList);
    }
  };

  const handleNavigate = () => {
    navigate("/menu/add_analyst");
  };
  const handleDelete = async (id) => {
    searchParams.set("success", "true"); // Set the success query parameter
    navigate(location.pathname + "?" + searchParams.toString());
    let getanalyst = await WizardDeleteCall(id);
    setDeleteId("");
    setOpen(false);
    setOpenToast(true);
    setVarient("success");
    setTextValue("Your Wizard Deleted Successfully");
    getAnalystCall();
  };
  /*---------------------------------------- deleet wizard-------------*/
  const handlePopup = (id) => {
    setDeleteId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseToastsuccess = () => {
    setOpenToast(false);
  };

  function refreshPageAfterTime(timeInSeconds) {
    var timeInMilliseconds = timeInSeconds * 10000;
    setLoading(true);
    setOpen(true);
    // console.log("opened");
    setTimeout(function () {
      setOpen(false);
      setReLoading(false);
      setLoading(false);
      // console.log("closed");
    }, timeInMilliseconds);
  }
  useEffect(() => {
    if (reLoading) {
      refreshPageAfterTime(0);
    }
  }, [setLoading, reLoading]);

  return (
    <div className="">
      <div className="">
        <div>
          {/* <Typography style={{ fontSize: "1.7rem" }}>Daily Customer Energy Consumption Analysis <KeyboardArrowDownIcon style={{ verticalAlign: "bottom", fontSize: "2rem" }} /></Typography> */}
          <Typography style={{ fontSize: "1.7rem", fontWeight: "bold" }}>
            Analytics Dashboard
          </Typography>
        </div>
        <div style={{ marginTop: "1.5%" }}>
          <Stack spacing={3} direction="row">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              value={site}
              options={siteIdName.map((option) => option.siteName)}
              onChange={(data, event) => handleSite(data, event)}
              sx={{ width: "15vw" }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select  Site ..." />
              )}
              classes={{ option: "autocomplete" }}
            />
            <Button
              variant="contained"
              style={buttonStyle}
              onClick={handleNavigate}
              size="medium"
            >
              Add New Widget
            </Button>
          </Stack>
        </div>
      </div>
      <div className="card-analyst">
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15%",
                marginLeft: "50%",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </div>
          ) : analystWizardData.length > 0 ? (
            analystWizardData.map((elem) => (
              <Grid item xs={21} md={6} key={analystWizardData.indexOf(elem)}>
                <Paper elevation={0} style={{ marginTop: "2%" }}>
                  {/* <Card sx={{ width: "100%" }}>
                  <CardContent style={{ height: "46vh" }}> */}
                  <Grid container spacing={2}>
                    <Grid item xs={20} md={10.8}>
                      {elem.chartType === "Daily Generation" &&
                      elem.range === "custom" ? (
                        <BarChart
                          tableData={elem.dailygenerationChartData}
                          chartName={elem.chartType}
                          widgetName={elem.widgetName}
                        />
                      ) : elem.chartType === "Energy Performance" &&
                        elem.range === "custom" ? (
                        <BarChart
                          chartName={elem.chartType}
                          widgetName={elem.widgetName}
                          tableData={elem.energyPerformanceChartData}
                          chartValue={[elem]}
                          range={elem.range}
                          siteId={elem.siteId}
                          siteListAPI={siteListAPI}
                          equipmentListAPI={equipmentListAPI}
                        />
                      ) : elem.chartType === "Daily Generation" &&
                        elem.range === "daily" ? (
                        <ApexChart
                          chartName={elem.chartType}
                          widgetName={elem.widgetName}
                          tableData={elem.dailygenerationChartData}
                          chartValue={[elem]}
                          range={elem.range}
                          siteId={elem.siteId}
                          siteListAPI={siteListAPI}
                          equipmentListAPI={equipmentListAPI}
                        />
                      ) : elem.chartType === "Energy Performance" &&
                        elem.range === "daily" ? (
                        <ApexChart
                          chartName={elem.chartType}
                          widgetName={elem.widgetName}
                          tableData={elem.energyPerformanceChartData}
                          chartValue={[elem]}
                          range={elem.range}
                          siteId={elem.siteId}
                          siteListAPI={siteListAPI}
                          equipmentListAPI={equipmentListAPI}
                        />
                      ) : elem.chartType === "Parameter Comparision" &&
                        elem.range === "daily" ? (
                        <ApexChart
                          chartName={elem.chartType}
                          widgetName={elem.widgetName}
                          tableData={elem.parameterList}
                          chartValue={[elem]}
                          range={elem.range}
                          siteId={elem.siteId}
                          siteListAPI={siteListAPI}
                          equipmentListAPI={equipmentListAPI}
                        />
                      ) : elem.chartType === "Specific Yield" &&
                        elem.equSpeciYield !== null ? (
                        <CombinedChart
                          tableData={elem.equSpeciYield}
                          WidgetName={elem.widgetName}
                          chartName={elem.chartType}
                          customView={elem}
                        />
                      ) : elem.chartType === "Specific Yield" &&
                        elem.specificYieldInv !== null ? (
                        <CombinedChart
                          tableData={elem.specificYieldInv}
                          WidgetName={elem.widgetName}
                          chartName={elem.chartType}
                          customView={elem}
                        />
                      ) : elem.chartType === "Actual Vs Expected" ? (
                        <CombinedChart
                          tableData={elem.actualVsExcepted}
                          WidgetName={elem.widgetName}
                          chartName={elem.chartType}
                          customView={elem}
                        />
                      ) : elem.chartType === "DG PV Grid Management" &&
                        elem.range === "custom" ? (
                        <BarChart
                          tableData={elem.pvDgGridSyncvalue}
                          widgetName={elem.widgetName}
                          chartName={elem.chartType}
                          customView={elem}
                        />
                      ) : elem.chartType === "DG PV Grid Management" &&
                        elem.range === "daily" ? (
                        <AreaChart
                          tableData={elem.pvDgGridSyncvalue}
                          WidgetName={elem.widgetName}
                          chartName={elem.chartType}
                          customView={elem}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={-2}>
                      <DeleteIcon
                        style={{ marginTop: "45%", cursor: "pointer" }}
                        onClick={() => {
                          handlePopup(elem?.widgetId);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {/* </CardContent>
                </Card> */}
                </Paper>
              </Grid>
            ))
          ) : (
            <div style={{ marginLeft: "42%", marginTop: "8%" }}>
              <Typography style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                No Widgets Configure for this Site..
              </Typography>
            </div>
          )}
        </Grid>
      </div>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Delete Confirmation ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Would you like to remove this widget form the Analytics Dashboard
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={4}>
              <Button
                color="error"
                variant="contained"
                onClick={() => handleDelete(deleteId)}
                style={buttonStyle}
              >
                Yes, Remove it !
              </Button>
              <Button
                autoFocus
                variant="contained"
                onClick={handleClose}
                style={buttonStyle}
              >
                No Thanks
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>

        <div>
          <Snackbar
            open={openToast}
            autoHideDuration={3000}
            onClose={handleCloseToast}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleCloseToast}
              severity={variantText}
              sx={{ width: "100%", ...alertStyle }}
            >
              {textValue}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
};
export default Analytics;
