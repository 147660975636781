import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { CustomList } from './CustomList';
import { ScbParams } from '../../Api/SiteListApi';
import FadeLoader from 'react-spinners/FadeLoader';

export const Accordian = ({ data, count, statusDown }) => {
  const [loading, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState();
  const [dataState, setDataState] = useState();

  useEffect(() => {
    fetchScbParams(data);
    // eslint-disable-next-line
  }, []);

  const fetchScbParams = async (data) => {
    setLoading(true);
    // const data = await ScbParams(id);
    setJsonData(data);
    setDataState(data);
    setLoading(false);
  };

  if (statusDown) {
    return (
      <Grid
        container
        spacing={2}
        columns={16}
        sx={{ display: 'flex', justifyContent: 'space-evenly' }}
      >
        <Grid item xs={16}>
          <div
            style={{
              maxHeight: '220px',
              overflow: 'auto',
            }}
          >
            <CustomList data={null} status={statusDown} />
          </div>
        </Grid>
      </Grid>
    );
  }

  function splitNumber(number) {
    const half = Math.floor(number / 2);

    if (number % 2 === 0) {
      return [half, half];
    } else {
      return [half, half + 1];
    }
  }

  const split = splitNumber(count);
  console.log(dataState, split, jsonData, 'checkit');

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <Grid
          container
          spacing={2}
          columns={16}
          sx={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          <Grid item xs={6}>
            <div>
              <CustomList
                data={dataState}
                limit={[0, split[0]]}
                statusDown={statusDown}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <CustomList
                data={dataState}
                limit={[split[0], count]}
                statusDown={statusDown}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};
