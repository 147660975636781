import React, { useEffect } from "react";
import "./style.css";
import {
  styled,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";

/*---------------------------------------------- MUI Components ----------------------------*/
import {
  Box,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Popover,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
/*----------------------------------------------- Mui icons ----------------------------------*/
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SolarPowerRoundedIcon from "@mui/icons-material/SolarPowerRounded";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import MotionPhotosAutoIcon from "@mui/icons-material/MotionPhotosAuto";
import WifiTetheringErrorIcon from "@mui/icons-material/WifiTetheringError";
import LoginApi from "../../Api/LoginApi";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
/*--------------------------- Images-----------------------------*/
// import Image from '../../images/eiralogo.jpg'
import webdyn from "../../images/webdyn.png";
import Webdynicon from "../../images/Webdyn icon.jpg";

const drawerWidth = 230;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
function MenuBar({ showPrompt }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [imageset, setimageset] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlelogClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const logopen = Boolean(anchorEl);
  const id = logopen ? "simple-popper" : undefined;
  const handleDrawerOpen = () => {
    setOpen(true);
    setimageset(false);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setimageset(true);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        // variant="outlined"
        {...props}
      />
    );
  });
  const handlelogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("jwtToken");
    navigate("/");
    window.location.reload();
  };
  const handleNavigate = (value) => {
    if (value === "") {
      navigate("/menu");
    } else {
      navigate(`/menu/${value}`);
    }
  };

  const handleMyProfile = () => {
    console.log("navigate");
    navigate("/menu/userconf");
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        display: "flex",
        boxShadow: "none",
        backgroundColor: "transparent",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={
          theme.palette.mode === "light"
            ? { backgroundColor: "white", color: "#00054a" }
            : { backgroundColor: "black" }
        }
      >
        <Toolbar>
          <IconButton
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {imageset ? (
            <div>
              <img
                src={webdyn}
                alt="logoImage"
                className="image"
                style={{ width: "auto", marginLeft: "10%" }}
              />
            </div>
          ) : (
            //   <Grid container>
            //   <Grid item xs={4}>
            //     <img src={webdyn} className='image' style={{ maxWidth: "100%", height: "auto" }} />
            //   </Grid>
            // </Grid>
            ""
          )}
          <div className="header-menu">
            <div>
              <div>
                {/* <TextField
                  size="small"
                  className='searchbar'
                  style={theme.palette.mode === 'light' ? { color: "MidnightBlue", width: '20vw', borderRadius: '90px', marginLeft: '2vw', backgroundColor: 'white' } : { width: '20vw', borderRadius: '90px', marginLeft: '2vw' }}
                  placeholder="Search sites, equipments etc here..."
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"
                    > <Button style={theme.palette.mode === 'light' ? { color: "MidnightBlue" } : { color: "white" }}><SearchOutlinedIcon /></Button></InputAdornment>,
                  }} 
                />*/}
              </div>
            </div>
            <div className="header-right">
              {/* <div>
                {theme.palette.mode === 'light' ? <NightsStayIcon style={{ fontSize: '3.5vh', marginLeft: '1vw' }} onClick={colorMode.toggleColorMode} /> : <WbSunnyIcon style={{ fontSize: '3.5vh', marginLeft: '1vw' }} onClick={colorMode.toggleColorMode} />}
              </div> */}
              {/* <div><NotificationsIcon style={{ fontSize: '3.5vh', marginLeft: '1vw' }} /></div>
              <div><CommentIcon style={{ fontSize: '3.5vh', marginLeft: '1vw' }} /></div> */}
              {/* <Divider orientation="vertical" style={{ backgroundColor: 'cornflowerblue', marginLeft: '1vw', height: '5vh', marginTop: '-1vh' }} /> */}
              <div></div>
              <div>
                <span
                  style={{
                    fontSize: "2vh",
                    alignItems: "center",
                    marginLeft: "1vw",
                  }}
                >
                  {sessionStorage.getItem("token")}
                </span>
              </div>
              <div>
                <AccountCircleIcon
                  style={{ fontSize: "3.5vh", marginLeft: "1vw" }}
                  aria-describedby={id}
                  type="button"
                  onClick={handlelogClick}
                />
              </div>
            </div>
            {/* ---------------------------------------- Log out FUnctionality ------------------------------  */}
            <Popover
              id={id}
              open={logopen}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{ marginTop: "16px" }}
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <MenuList autoFocusItem={logopen} id="menu-list-grow">
                  <MenuItem
                    sx={{
                      ml: 1,
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent",
                      },
                      overflow: "hidden",
                    }}
                  >
                    <Button
                      variant="text"
                      style={{
                        textTransform: "capitalize",
                        userSelect: "none",
                      }}
                      startIcon={<Person2OutlinedIcon />}
                      onClick={handleMyProfile}
                    >
                      My profile
                    </Button>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      ml: 1,
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent",
                      },
                      overflow: "hidden",
                    }}
                  >
                    <Button
                      variant="text"
                      onClick={handlelogout}
                      style={{ textTransform: "capitalize", color: "red" }}
                      startIcon={<LogoutIcon />}
                    >
                      Log out
                    </Button>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        style={{ backgroundColor: "#abc4c4" }}
      >
        <DrawerHeader style={{ backgroundColor: "#abc4c4" }}>
          <IconButton
            onClick={handleDrawerClose}
            style={{ backgroundColor: "#abc4c4" }}
          >
            {
              <div style={{ display: "flex" }}>
                <img
                  src={webdyn}
                  alt="logoImage"
                  className="image"
                  style={{ width: "auto", marginLeft: "3%" }}
                />
              </div>
            }
            {theme.direction === "rtl" ? (
              <ChevronRightIcon style={{ color: "white" }} />
            ) : (
              <ChevronLeftIcon style={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <List
          sx={{ width: "100%", maxWidth: 360 }}
          component="nav"
          style={{ color: "white" }}
        >
          <List component="div" disablePadding>
            <ListItemButton onClick={(e) => handleNavigate("")}>
              <ListItemIcon>
                <LineStyleIcon open={open} style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography>Dashboard</Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton onClick={(e) => handleNavigate("sitelist")}>
              <ListItemIcon>
                <SolarPowerRoundedIcon open={open} style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography>Site List</Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              disablePadding
              onClick={() => handleNavigate("analytics")}
            >
              <ListItemIcon>
                <QueryStatsIcon open={open} style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography>Analytics </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              disablePadding
              onClick={() => handleNavigate("view-analyst")}
            >
              <ListItemIcon>
                <MotionPhotosAutoIcon open={open} style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography> Analytics View</Typography>
              </ListItemText>
            </ListItemButton>

            {/* <ListItemButton disablePadding onClick={() => handleNavigate('alerts')} >
              <ListItemIcon>
                <WifiTetheringErrorIcon open={open}
                  style={{ color: 'white' }}
                />
              </ListItemIcon >
              <ListItemText> <Typography>Alerts </Typography> </ListItemText>
            </ListItemButton >  */}

            <ListItemButton
              disablePadding
              onClick={() => handleNavigate("customize-alerts")}
            >
              <ListItemIcon>
                <CrisisAlertIcon open={open} style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography> Customize Alert </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              disablePadding
              onClick={() => handleNavigate("report")}
            >
              <ListItemIcon>
                <AssessmentOutlinedIcon
                  open={open}
                  style={{ color: "white" }}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography>Report </Typography>
              </ListItemText>
            </ListItemButton>

            {/* Alarms button */}
            {/* <ListItemButton
              disablePadding
              onClick={() => handleNavigate("alarms")}
            >
              <ListItemIcon>
                <NotificationsNoneOutlinedIcon
                  open={open}
                  style={{ color: "white" }}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography> Alarms </Typography>
              </ListItemText>
            </ListItemButton> */}

            {/* <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <DriveFileMoveOutlinedIcon open={open}
                style={{ color: 'white' }}
              />
            </ListItemIcon >
            <ListItemText primary="Configuration" />
            {opencollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton > */}
            {/* <Collapse in={opencollapse} timeout="auto" unmountOnExit> */}
            <List component="div" disablePadding>
              {/* <ListItemButton sx={{ pl: 3 }} onClick={(e) => handleNavigate('')}>
                <ListItemIcon>
                  <FileUploadOutlinedIcon open={open}
                    style={{ color: 'white' }}
                  />
                </ListItemIcon >
                <ListItemText> <Typography>Data logger</Typography> Configuration </ListItemText>
              </ListItemButton > */}
              {/* <ListItemButton onClick={(e) => handleNavigate("equipmentconfiguration")}>
              <ListItemIcon>
                <HomeRepairServiceIcon open={open}
                  style={{ color: 'white' }}
                />
              </ListItemIcon >
              <ListItemText    > <Typography>Equipment Configuration</Typography></ListItemText>
            </ListItemButton > */}
              {/* <ListItemButton onClick={(e) => handleNavigate('equipmenttype')} >
              <ListItemIcon>
                <Groups3OutlinedIcon open={open}
                  style={{ color: 'white' }}
                />
              </ListItemIcon >
              <ListItemText > <Typography> Equipment Type </Typography></ListItemText>
            </ListItemButton > */}
              {/* <ListItemButton sx={{ pl: 3 }} onClick={(e) => handleNavigate('customerlist')} >
                <ListItemIcon>
                  <HandshakeIcon open={open}
                    style={{ color: 'white' }}
                  />
                </ListItemIcon >
                <ListItemText    > Customer List</ListItemText>
              </ListItemButton > */}
              {/* 
              <ListItemButton sx={{ pl: 3 }} onClick={(e) => handleNavigate("siteconf")}>
                <ListItemIcon>
                  <SolarPowerRoundedIcon open={open}
                    style={{ color: 'white' }}
                  />
                </ListItemIcon >
                <ListItemText    > <Typography>Site Configuration</Typography></ListItemText>
              </ListItemButton >
              <ListItemButton sx={{ pl: 3 }} onClick={(e) => handleNavigate("userconf")}>
                <ListItemIcon>
                  <GroupAddRoundedIcon open={open}
                    style={{ color: 'white' }}
                  />
                </ListItemIcon >
                <ListItemText    > <Typography>User Configuration</Typography></ListItemText>
              </ListItemButton >
              <ListItemButton sx={{ pl: 3 }} onClick={(e) => handleNavigate('IndustrtyType')}  >
                <ListItemIcon>
                  <PrecisionManufacturingOutlinedIcon open={open}
                    style={{ color: 'white' }}
                  />
                </ListItemIcon >
                <ListItemText    > Industry Type</ListItemText>
              </ListItemButton > */}
              {/* <ListItemButton sx={{ pl: 3 }} onClick={(e) => handleNavigate('Standardparameter')} >
                  <ListItemIcon>
                    <PersonOutlineIcon open={open}
                      style={{ color: 'white' }} 
                    />
                  </ListItemIcon >
                  <ListItemText    > Standard Parameter</ListItemText>
                </ListItemButton >
                <ListItemButton sx={{ pl: 3 }} onClick={(e) => handleNavigate('Errorconfig')}   >
                <ListItemIcon>
                  <ErrorOutlineOutlinedIcon open={open}
                    style={{ color: 'white' }}
                  />
                </ListItemIcon >
                <ListItemText>Error Configuration</ListItemText>
              </ListItemButton   > */}
              {/* 
              <ListItemButton sx={{ pl: 3 }} onClick={(e) => handleNavigate("siteoverview")}>
                <ListItemIcon>
                  <SolarPowerRoundedIcon open={open}
                    style={{ color: 'white' }}
                  />
                </ListItemIcon >
                <ListItemText    > <Typography>Site Overview</Typography></ListItemText>
              </ListItemButton >
          
              <ListItemButton sx={{ pl: 3 }} onClick={(e) => handleNavigate('Statusconfiguration ')}  >
                <ListItemIcon>
                  <TrendingUpOutlinedIcon open={open}
                    style={{ color: 'white' }}
                  />
                </ListItemIcon >
                <ListItemText    > Status configuration</ListItemText>
              </ListItemButton > */}
              {/* <ListItemButton sx={{ pl: 3}} onClick={(e) => handleNavigate('customerconfiguration')} >
                <ListItemIcon>
                  <Groups3OutlinedIcon open={open}
                    style={{ color: 'white' }}
                  // style={theme.palette.mode === 'light' ? { color: "#09001A" } : { color: "white" }} 
                  />
                </ListItemIcon >
                <ListItemText    > Customer Configuration</ListItemText>
              </ListItemButton > */}
              {/* 
              <ListItemButton sx={{ pl: 3 }} onClick={(e) => handleNavigate('equipmentcategory')} >
                <ListItemIcon>
                  <ConstructionIcon open={open}
                    style={{ color: 'white' }} 
                  />
                </ListItemIcon >
                <ListItemText    > Equipment Category</ListItemText>
              </ListItemButton >
           
              <ListItemButton sx={{ pl: 3 }} onClick={(e) => handleNavigate('masterupload')} >
                <ListItemIcon>
                  <UploadFileIcon open={open}
                    style={{ color: 'white' }}
                  />
                </ListItemIcon >
                <ListItemText >Master Upload</ListItemText>
              </ListItemButton >
              <ListItemButton sx={{ pl: 3 }} onClick={() => handleNavigate('userconf')} >
                <ListItemIcon>
                  <PersonIcon open={open}
                    style={{ color: 'white' }}
                  />
                </ListItemIcon >
                <ListItemText> User Configuration</ListItemText>
              </ListItemButton > */}
            </List>
            {/* </Collapse > */}
          </List>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {showPrompt.showPrompt && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            style={{ marginTop: 60, marginRight: 60 }}
            open={true}
            autoHideDuration={10000}
            onClose={showPrompt.handleClose}
            key={"top  right"}
          >
            <Alert
              // onClose={showPrompt.handleClose}
              severity="warning"
              sx={{ width: "100%" }}
            >
              You have been inactive for too long Session expires in 10 seconds
            </Alert>
          </Snackbar>
        )}
        <Outlet />
      </Box>
    </Box>
  );
}
export default function ToggleColorMode({ showPrompt }) {
  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [mode]
  );
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );
  sessionStorage.setItem("theme", mode);
  useEffect(() => {
    const storedTheme = sessionStorage.getItem("theme");
    if (storedTheme) {
      setMode(storedTheme);
    }
  }, []);
  console.log(mode, "mode");
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <MenuBar showPrompt={showPrompt} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
