import React, { useState, useEffect, CSSProperties } from 'react';
import './SiteStyle.css';
import Chart from './Chart';
import YieldCharts from './YieldChart';
import CombinedChart from './CombinedChart';
import ApexChart from '../Components/AnalyticsDashboard/ApexexChart';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CustomAlert from './CustomAlert';
import EnergyMeterTable from './EnergyMeterTable';
import SensorTable from './SensorTable';
import Scb from './Scb';
import EquipmentTable from './EquipmentTable';
import Alerts from './AllAlerts';
/*------------------------Mui Componnets -------------------------*/
import {
  fetchSiteDetails,
  fetchEquipmentIdDetails,
  fetchParameterListDetails,
} from '../../Api/ExcelUploadAPi';
import {
  Typography,
  Paper,
  Button,
  Grid,
  Box,
  Autocomplete,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// import Load from "../../images/Load.jpg";
/*--------------------- mui icons -----------------------------*/
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
/*---------------------Api-------------------------*/
import { fetchEquipmentTable } from '../../Api/EquipmentApi';
import {
  ScbTables,
  SensorTables,
  EnergymeterTables,
  CustomizeAlarms,
  SiteList,
} from '../../Api/SiteListApi';
import { DataAnalystSubmit } from '../../Api/DataAnalystApi';
import { parameterComparisonApplyChanges } from '../../Api/DataAnalystApi';
import FadeLoader from 'react-spinners/FadeLoader';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { size } from 'lodash';
import { Outbound } from '@mui/icons-material';

const SiteOverView = (props) => {
  const [isCustomAlertOpen, setCustomAlertOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const Navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [tableValue, setTableValue] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableDatas, setTableDatas] = useState([]);
  const [energymeterTableData, setEnergymeterTableData] = useState([]);
  const [SensorTableData, setSensorTableData] = useState([]);
  const [ScbTableData, setScbTableData] = useState([]);
  const [specificYeild, setSpecificYeild] = useState(0);
  const [pR, setPR] = useState(0);
  const [equipmentDetails, setEquipmentDetails] = React.useState(false);
  const [equipment, setEquipmentData] = useState(true);
  const [equipments, setEquipmentDatas] = useState(true);
  const [alerts, setAlerts] = useState(false);
  const [alarmsClicked, setAlarmsClicked] = useState(false);
  const [customizeAlertsClicked, setCustomizeAlertsClicked] = useState(false);
  const [showAlarmsButton, setShowAlarmsButton] = useState(true);
  const [showCustomizeAlertsButton, setShowCustomizeAlertsButton] =
    useState(false);
  const [multiChartData, setMultichartData] = useState([]);
  const [unitChange, SetUnitChange] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [scbLoading, setScbLoading] = useState(true);
  const [meterLoading, setMeterLoading] = useState(true);
  const [sensorLoading, setSensorLoading] = useState(true);
  const Loader = (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          width: '100%',
          height: '50vh',
        },
      }}
    >
      <Paper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      </Paper>
    </Box>
  );
  const fallBack = (txt) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            width: '100%',
            height: '50vh',
          },
        }}
      >
        <Paper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '15%',
            }}
          >
            {txt}
          </div>
        </Paper>
      </Box>
    );
  };
  /*-------------------------EquipmentTable API------------------------*/

  useEffect(() => {
    // specific Yeild = today energy / Invertorcapacity <<<<<<<< formula
    let capcity = tableValue?.invertersCapacity;
    let todayEnergy = tableValue?.todayEnergy;
    let strCapacity = replaceCapacity(capcity);
    let strtodayenergy = replacetodayEnergy(todayEnergy);
    let invCapacity = replaceCapacity(tableValue?.capacity);
    let irradiationAvg = tableValue?.irradiationAvg;
    setSpecificYeild((strtodayenergy / invCapacity).toFixed(2));
    const prValue = (
      (strtodayenergy / (invCapacity * irradiationAvg)) *
      100
    ).toFixed(2);
    if (isNaN(prValue) || prValue === 0 || prValue == Infinity || prValue > 150) {
      setPR(0);
    } else {
      setPR(prValue);
    }
  }, [tableValue]);
  const fetchData = async () => {
    try {
      const data = await fetchEquipmentTable(location?.state?.id);

      setTableValue(data);
      fetchDailyGenerationData(data);
      fetchEnergymeterTableData();
      fetchSensorTableData();
      fetchScbTableData();
      setLoading(false);
    } catch (e) {
      // console.log(e);
    }
  };

  const changeUnit = () => {
    SetUnitChange(!unitChange);
  };
  /*--------------SiteList API----------------*/
  const fetchTableData = async (selectedSite) => {
    const data = await SiteList(selectedSite);
    setTableData(data);
  };

  /*--------------AllAlerts API----------------*/
  const fetchTableDatas = async () => {
    const data = await CustomizeAlarms(location?.state?.id);
    setTableDatas(data);
  };

  /*--------------EnergyMeterTable API----------------*/
  const fetchEnergymeterTableData = async () => {
    const data = await EnergymeterTables(location?.state?.id);
    setEnergymeterTableData(data);
    setMeterLoading(false);
  };

  /*--------------SensorTable API----------------*/
  const fetchSensorTableData = async () => {
    const data = await SensorTables(location?.state?.id);
    setSensorTableData(data);
    setSensorLoading(false);
  };

  /*--------------ScbTable API----------------*/
  const fetchScbTableData = async () => {
    const data = await ScbTables(location?.state?.id);
    setScbTableData(data);
    setScbLoading(false);
    console.log(data, 'dattatatasgduirweuhi');
  };

  const fetchEquipmentDetails = async (siteIdFilter) => {
    try {
      let data = await fetchEquipmentIdDetails(siteIdFilter);
      // setEquipments(data);

      let equipmentIDs = data.map((datav) => {
        return datav.equipmentid;
      });
      // setEquipmentData(equipmentName);
      // fetchParams(siteIdFilter[0]?.siteId);
      return { equipmentIDs, data };
    } catch (e) {
      console.error(e);
    }
  };
  const fetchParams = async (id) => {
    try {
      let data = await fetchParameterListDetails(id);
      return data;
    } catch (e) {
      console.error(e);
    }
  };
  const fetchDailyGenerationData = async (tableValue) => {
    let date = new Date();
    let responseFromDate = moment(date).format('YYYY-MM-DD');
    let responseToDate = moment(date).format('YYYY-MM-DD');
    // let dataModel = {
    //   SiteId: tableValue?.siteID,
    //   Range: 'daily',
    //   timeperiod: 'today',
    //   FromDate: responseFromDate,
    //   ToDate: responseToDate,
    //   GraphType: 'Daily Generation',
    // };
    let equipmentIds = await fetchEquipmentDetails(tableValue?.siteID);

    let equipmentParameter = await fetchParams(tableValue?.siteID);

    const equip_Param = equipmentParameter.filter(
      (element) => element === 'ActivePower' || element === 'Irradiation'
    );

    let dataModel = {
      SiteId: tableValue?.siteID,
      EquipmentIds: equipmentIds?.equipmentIDs,
      Parameters: equip_Param,
      Range: 'daily',
      // timeperiod: timevalue,
      FromDate: responseFromDate,
      ToDate: responseToDate,
      GraphType: 'Parameter Comparision',
    };

    // let responseData = await DataAnalystSubmit(dataModel);
    // let responseData = await parameterComparisonApplyChanges(dataModel);

    // console.log(responseData,"responseData");
    // let seriesData = [];
    // const irradiationSeries = {
    //   name: 'irradiation',
    //   data: [],
    // };
    // const todayEnergySeries = {
    //   name: 'todayenergy',
    //   data: [],
    // };
    // responseData.forEach((dataPoint) => {
    //   const timestamp = new Date(dataPoint.timestamp).getTime();
    //   irradiationSeries.data.push([timestamp, dataPoint.irradiation || 0]);
    //   todayEnergySeries.data.push([timestamp, dataPoint.todayEnergy || 0]);
    // });
    // seriesData.push(irradiationSeries);
    // seriesData.push(todayEnergySeries);
    // setTableData(responseData);
    // setMultichartData(seriesData);

    try {
      let responseData = await parameterComparisonApplyChanges(dataModel);

      setTableData(responseData);

      const equipmentMap = {};
      const dataFields = equipmentParameter;
      const seriesData = [];
      const equipmentsDetails = equipmentIds?.data;
      dataFields.forEach((field) => {
        responseData.forEach((data) => {
          let equipmentName = equipmentsDetails?.filter((dataValue) => {
            return dataValue.equipmentid === Number(data.equipmentid);
          });

          const timestamp = new Date(data.timestamp).getTime();
          const value = data[field] !== null ? Number(data[field]) : 0;
          const seriesName = `${equipmentName[0]?.customernaming} - ${field}`;
          const existingSeries = seriesData.find(
            (series) => series.name === seriesName
          );
          if (existingSeries) {
            existingSeries.data.push([timestamp, value]);
          } else {
            const newSeries = {
              name: seriesName,
              data: [[timestamp, value]],
            };
            seriesData.push(newSeries);

            let filteredArray = seriesData.filter((obj) => {
              return !obj.data.every(([_, value]) => isNaN(value));
            });
            setMultichartData(filteredArray);
            // console.log(filteredArray, "filteredArray");
          }
        });
      });
    } catch (e) {
      console.error(e);
    }
  };
  const buttonStyles = {
    textTransform: 'capitalize',
    fontSize: '1.1rem',
    border: 'none',
    width: '100%',
  };
  const handlebackicon = () => {
    Navigate('/menu/sitelist');
  };
  useEffect(() => {
    fetchData();
    setTableToShow('Inverter');
  }, []);
  function replacetodayEnergy(todayEnergy) {
    if (todayEnergy?.includes('MWh')) {
      const numericValue = parseFloat(todayEnergy.replace('MWh', '').trim());
      return numericValue * 1000;
    } else {
      return todayEnergy?.replace('kWh', '');
    }
  }
  function replaceCapacity(capcity) {
    if (capcity?.includes('MWh')) {
      const numericValue = parseFloat(capcity.replace('MW', '').trim());
      return numericValue * 1000;
    }
    if (capcity?.includes('kWp')) {
      const numericValue = parseFloat(capcity.replace('kWp', '').trim());
      return numericValue;
    } else {
      return capcity?.replace('kW', '');
    }
  }
  const Items = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  function getStatusIcon(status) {
    switch (status) {
      case 'Active':
        return '#72E1281F';
      case 'Offline':
        return 'grey';
      case 'Warning':
        return 'orange';
      case 'Down':
        return 'red';
      default:
        return 'black';
    }
  }
  function convertMWhToKWh(mwh) {
    return mwh * 1000;
  }
  const handleSite = (event, newValue) => {
    // newValue will contain the selected option ("Inverter," "Energy Meter," or "Sensor").
    // Set the tableToShow state to newValue.
    setTableToShow(newValue);
  };
  const [showEquipmentAutocomplete, setShowEquipmentAutocomplete] =
    useState(true);
  const [showAlarmsAutocomplete, setShowAlarmsAutocomplete] = useState(false);
  const [showExtraButton, setShowExtraButton] = useState(false);
  const [tableToShow, setTableToShow] = useState('');
  // const [tableToShow, setTableToShow] = useState('');
  const [selectedOption, setSelectedOption] = useState('Inverter'); // Set the initial value as "Inverter"
  const handleEquipment = () => {
    setTableToShow('Inverter');
    setEquipmentData(true);
    setAlerts(false);
    setEquipmentDetails(false);
  };
  const handleAlert = () => {
    setTableToShow('alerts');
    setAlerts(true);
    setEquipmentData(false);
    setEquipmentDetails(false);
    setShowExtraButton(true);
  };
  const handleExtraButton = () => {
    setCustomAlertOpen(true);
    setTableToShow('customAlert');
    setShowAlarmsButton(false);
    fetchTableDatas();
  };
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="blue" loading={true} />
        </div>
      ) : (
        <div>
          <div className="SiteName">
            <Typography style={{ fontSize: '1.25', fontWeight: '500px' }}>
              <KeyboardBackspaceIcon
                style={{ verticalAlign: 'middle', fontSize: '1.7rem' }}
                onClick={handlebackicon}
              />
              Site Overview -
              <span style={{ color: 'blue' }}>{tableValue.siteName}</span>
            </Typography>
          </div>
          <div
            className="summary"
            style={{ marginTop: '1%', backgroundColor: 'aliceblue' }}
          >
            <Paper elevation={0} style={{ cursor: 'default', padding: '1%' }}>
              <div>
                <Grid container columns={16}>
                  <Grid item xs={3.2}>
                    <div style={{ fontSize: '1rem', opacity: '0.7' }}>
                      Capacity
                    </div>
                    <div style={{ fontSize: '1.2rem' }}>
                      {tableValue.capacity}
                    </div>
                    <div
                      style={{
                        fontSize: '1rem',
                        opacity: '0.7',
                        marginTop: '2vh',
                      }}
                    >
                      Inverter Capacity
                    </div>
                    <div style={{ fontSize: '1.2rem' }}>
                      {tableValue.invertersCapacity}
                    </div>
                  </Grid>
                  <Grid item xs={3.2}>
                    <div style={{ fontSize: '1rem', opacity: '0.7' }}>
                      Today Energy
                    </div>
                    <div style={{ fontSize: '1.2rem' }}>
                      {tableValue.todayEnergy}
                    </div>

                    <div>
                      <div
                        style={{
                          fontSize: '1rem',
                          opacity: '0.7',
                          marginTop: '2vh',
                        }}
                      >
                        Total Energy
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        // alignItems: 'flex-end',
                        fontSize: '1.2rem',
                      }}
                    >
                      {unitChange
                        ? tableValue.totalEnergy
                        : String(
                            convertMWhToKWh(
                              tableValue.totalEnergy.split('MWh')[0]
                            ).toFixed(2) +
                              ' ' +
                              'kWh'
                          )}
                      {unitChange ? (
                        <ArrowDropUpOutlinedIcon onClick={changeUnit} />
                      ) : (
                        <ArrowDropDownOutlinedIcon onClick={changeUnit} />
                      )}
                      {/* <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={['MWh', 'kWh']}
                        sx={{ width: 95, padding: 0 }}
                        // size="small"
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              fieldset: {
                                border: 'none',
                                boxShadow: 'none',
                              },
                            }}
                            {...params}
                          />
                        )}
                      /> */}
                    </div>
                  </Grid>
                  {/* <Grid item xs={3.2}>
                    <div style={{ fontSize: '1rem', opacity: '0.7' }}>CUF</div>
                    <div style={{ fontSize: '1.2rem' }}>{tableValue.cuf}</div>
                    <div
                      style={{
                        fontSize: '1rem',
                        opacity: '0.7',
                        marginTop: '2vh',
                      }}
                    >
                      Today Production
                    </div>
                    <div style={{ fontSize: '1.2rem' }}>
                      {tableValue.todayProductionYeild}
                    </div>
                  </Grid> */}
                  <Grid item xs={3.2}>
                    <div style={{ fontSize: '1rem', opacity: '0.7' }}>PR</div>
                    <div style={{ fontSize: '1.2rem' }}>{`${pR} %`}</div>
                    <div
                      style={{
                        fontSize: '1rem',
                        opacity: '0.7',
                        marginTop: '2vh',
                      }}
                    >
                      Active Power
                    </div>
                    <div style={{ fontSize: '1.2rem' }}>
                      {tableValue.activePower} kW
                    </div>
                  </Grid>
                  <Grid item xs={3.2}>
                    <div style={{ fontSize: '1rem', opacity: '0.7' }}>
                      Today Event
                    </div>
                    <div style={{ fontSize: '1.2rem' }}>
                      {tableValue.todayEvents}
                    </div>
                    <div
                      style={{
                        fontSize: '1rem',
                        opacity: '0.7',
                        marginTop: '2vh',
                      }}
                    >
                      CO
                      <span
                        style={{
                          fontSize: '0.7rem',
                          verticalAlign: 'sub',
                          lineHeight: '0',
                        }}
                      >
                        2
                      </span>
                      Avoided
                    </div>
                    <div style={{ fontSize: '1.2rem' }}>{tableValue.co}</div>
                  </Grid>
                  <Grid item xs={3.2}>
                    <div style={{ fontSize: '1rem', opacity: '0.7' }}>
                      Total Events
                    </div>
                    <div style={{ fontSize: '1.2rem' }}>
                      {tableValue.totalEvents}
                    </div>
                    <div
                      style={{
                        fontSize: '1rem',
                        opacity: '0.7',
                        marginTop: '2vh',
                      }}
                    >
                      Specific Yield (AC)
                    </div>
                    <div style={{ fontSize: '1.2rem' }}> {specificYeild}</div>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>
          <div style={{ margin: '20px 5px 0 0 ' }}>
            <Grid container columnSpacing={2}>
              {/* <Grid item lg={6} md={6}>
                <Typography
                  sx={{ textAlign: 'center', color: '#000440', fontWeight: 500 }}>Today Energy</Typography>
                <Paper elevation={0}><Chart tableValue={tableValue} /></Paper>
              </Grid>
              <Grid item lg={6} md={6}>
                <Typography
                  sx={{ textAlign: 'center', color: '#000440', fontWeight: 500 }}>Specific Yeild</Typography>
                <Paper elevation={0}><YieldCharts
                  tableValue={tableValue} /></Paper>
              </Grid> */}
              <Grid item lg={6} className="overviewChart">
                {/* <Typography
                  sx={{ textAlign: 'center', color: '#000440', fontWeight: 500 }}>Specific Yeild</Typography> */}
                <Paper elevation={1}>
                  <CombinedChart tableValue={tableValue} />
                </Paper>
              </Grid>
              <Grid item lg={6} style={{ overflowX: 'scroll' }}>
                {/* <Typography
                                        sx={{ textAlign: 'center', color: '#000440', fontWeight: 500 }}>Specific Yeild</Typography> */}
                <Paper elevation={1}>
                  <ApexChart
                    tableData={tableData}
                    chartName="Parameter Comparision"
                    multiChartData={multiChartData}
                    key="1"
                    name="paramter"
                    timeValue="Today"
                    widgetName={`Daily Generation - ${tableValue?.siteName}`}
                  />
                </Paper>
              </Grid>
            </Grid>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div>
              <div className="equipment" style={{ marginTop: '9%' }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: equipment ? 'rgb(18, 127, 216)' : 'white',
                    color: equipment ? 'white' : 'black',
                    width: '100%',
                    height: '5vh',
                    textTransform: 'capitalize',
                    fontSize: '1.1rem',
                  }}
                  onClick={() => {
                    handleEquipment();
                    setShowEquipmentAutocomplete(true);
                    setShowAlarmsAutocomplete(false);
                  }}
                >
                  Equipment Details
                </Button>
              </div>
              <div style={{ marginTop: '10%' }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: alerts ? 'rgb(18, 127, 216)' : 'white',
                    color: alerts ? 'white' : 'black',
                    width: '100%',
                    height: '5vh',
                    textTransform: 'capitalize',
                    fontSize: '1.1rem',
                  }}
                  onClick={() => {
                    handleAlert();
                    setAlarmsClicked(true);
                    setCustomizeAlertsClicked(false);
                    setShowEquipmentAutocomplete(false);
                    setShowAlarmsAutocomplete(true);
                  }}
                >
                  Alarms
                </Button>
              </div>
            </div>
            <div style={{ marginLeft: '1.5%', width: '97%' }}>
              <div style={{ marginTop: '1%' }}>
                {alerts && (
                  <Box
                    sx={{
                      width: '100%',
                      height: '50%',
                    }}
                  >
                    <Paper
                      elevation={0}
                      style={{
                        width: '100%',
                        height: '5.5vh',
                        marginTop: '2.3%',
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid Items xs={1}>
                          <Button
                            variant="standard"
                            style={{
                              ...buttonStyles,
                              borderBottomColor: alarmsClicked
                                ? 'rgb(18, 127, 216)'
                                : 'white',
                              width: '100%',
                              height: '3vh',
                              textTransform: 'capitalize',
                              fontSize: '1.1rem',
                              borderBottomStyle: 'solid',
                              margin: '10px 0 0 40px',
                            }}
                            onClick={() => {
                              handleAlert();
                              setAlarmsClicked(true);
                              setCustomizeAlertsClicked(false);
                            }}
                          >
                            Equipment
                          </Button>
                        </Grid>
                        <Grid Items xs={2}>
                          <Typography>
                            <Button
                              variant="standard"
                              style={{
                                ...buttonStyles,
                                borderBottomColor: customizeAlertsClicked
                                  ? 'rgb(18, 127, 216)'
                                  : 'white',
                                width: '100%',
                                height: '3vh',
                                textTransform: 'capitalize',
                                fontSize: '1.1rem',
                                borderBottomStyle: 'solid',
                                margin: '10px 0 0 40px',
                                marginRight: '50%',
                              }}
                              onClick={() => {
                                handleExtraButton();
                                setCustomizeAlertsClicked(true);
                                setAlarmsClicked(false);
                              }}
                            >
                              User Defined
                            </Button>
                          </Typography>
                        </Grid>
                        <Grid item xs={7}></Grid>

                        <Grid item xs={2}>
                          <Button
                            style={{
                              color: '#000440',
                              marginTop: '-6%',
                              marginLeft: '48%',
                            }}
                          >
                            <SettingsOutlinedIcon
                              onClick={toggleModal}
                              setIsModalOpen={setIsModalOpen}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                )}

                <div>
                  {showEquipmentAutocomplete && (
                    <Box
                      sx={{
                        width: '100%',
                        height: '50%',
                      }}
                    >
                      <Paper
                        elevation={0}
                        style={{
                          width: '100%',
                          height: '5.5vh',
                          marginTop: '2.5%',
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid Items item xs={10}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              value={tableToShow}
                              options={[
                                'Inverter',
                                'Energy Meter',
                                'SCB',
                                'Sensor',
                              ]}
                              onChange={(event, newValue) => {
                                setSelectedOption(newValue);
                                handleSite(event, newValue);
                              }}
                              sx={{
                                width: '15vw',
                                marginTop: '-1.3%',
                                marginLeft: '7%',
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Site ..."
                                />
                              )}
                              classes={{ option: 'autocomplete' }}
                            />
                          </Grid>

                          <Grid item xs={2}>
                            <Button
                              style={{
                                color: '#000440',
                                marginTop: '-5%',
                                marginLeft: '50%',
                              }}
                            >
                              <SettingsOutlinedIcon
                                onClick={toggleModal}
                                setIsModalOpen={setIsModalOpen}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  )}
                </div>
              </div>
              <div style={{ marginTop: '1.5%', width: '%' }}>
                {/* {tableToShow === 'equipment' || tableToShow === 'Inverter' && tableValue?.equipmentList && tableValue.equipmentList.length > 0 ? (
                                        <EquipmentTable
                                            tableValue={tableValue.equipmentList}
                                            isModalOpen={isModalOpen}
                                            toggleModal={toggleModal}
                                            
                                        />
                                    ) : tableToShow === 'Energy Meter' ? (
                                        <EnergyMeterTable
                                            // tableValue={tableValue.equipmentList}
                                            tableValue={energymeterTableData}
                                            isModalOpen={isModalOpen}
                                            toggleModal={toggleModal}
                                            
                                        />
                                    ) : tableToShow === 'Sensor' ? (
                                        <SensorTable
                                            // tableValue={tableValue.equipmentList}
                                            tableValue={SensorTableData}
                                            isModalOpen={isModalOpen}
                                            toggleModal={toggleModal}
                                            
                                        />
                                    ) : tableToShow === 'alerts' && alerts && tableValue?.eventList && tableValue.eventList.length > 0 ? (
                                        <Alerts
                                            tableValue={tableValue.eventList}
                                            isModalOpen={isModalOpen}
                                            toggleModal={toggleModal}
                                            
                                        />
                                    ) : tableToShow === 'customAlert' ? (
                                        <CustomAlert
                                            tableValue={tableDatas}
                                            isModalOpen={isModalOpen}
                                            toggleModal={toggleModal}
                                            
                                        />
                                    ) : (
                                        <div>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    '& > :not(style)': {
                                                        width: '100%',
                                                        height: '50vh',
                                                    },
                                                }}
                                            >
                                                <Paper>
                                                    <div className='alerms'>There are no alarms to show</div>
                                                </Paper>
                                            </Box>
                                        </div>
                                    )}  */}
                {(tableToShow === 'equipment' || tableToShow === 'Inverter') &&
                tableValue?.equipmentList &&
                tableValue.equipmentList.length > 0 ? (
                  <EquipmentTable
                    tableValue={tableValue.equipmentList}
                    isModalOpen={isModalOpen}
                    toggleModal={toggleModal}
                  />
                ) : tableToShow === 'Energy Meter' ? (
                  meterLoading ? (
                    Loader
                  ) : energymeterTableData.length > 0 ? (
                    <EnergyMeterTable
                      tableValue={energymeterTableData}
                      isModalOpen={isModalOpen}
                      toggleModal={toggleModal}
                    />
                  ) : (
                    fallBack('There are no devices to show')
                  )
                ) : tableToShow === 'Sensor' ? (
                  sensorLoading ? (
                    Loader
                  ) : SensorTableData.length > 0 ? (
                    <SensorTable
                      tableValue={SensorTableData}
                      isModalOpen={isModalOpen}
                      toggleModal={toggleModal}
                    />
                  ) : (
                    fallBack('There are no sensor configured to show')
                  )
                ) : tableToShow === 'SCB' ? (
                  scbLoading ? (
                    Loader
                  ) : ScbTableData.length > 0 ? (
                    <Scb
                      tableValue={ScbTableData}
                      isModalOpen={isModalOpen}
                      toggleModal={toggleModal}
                    />
                  ) : (
                    fallBack(' There are no SCB configured to show')
                  )
                ) : tableToShow === 'alerts' &&
                  alerts &&
                  tableValue?.eventList &&
                  tableValue.eventList.length > 0 ? (
                  <Alerts
                    tableValue={tableValue.eventList}
                    isModalOpen={isModalOpen}
                    toggleModal={toggleModal}
                  />
                ) : tableToShow === 'customAlert' && tableDatas.length > 0 ? (
                  <CustomAlert
                    tableValue={tableDatas}
                    isModalOpen={isModalOpen}
                    toggleModal={toggleModal}
                  />
                ) : (
                  <div>
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                          width: '100%',
                          height: '50vh',
                        },
                      }}
                    >
                      <Paper>
                        {/* <div className='alerms'>There are no alarms to show</div> */}
                        {tableToShow === 'equipment' && (
                          <div className="alerms">
                            No equipment data to show
                          </div>
                        )}
                        {tableToShow === 'Sensor' && (
                          <div className="alerms"></div>
                        )}
                        {tableToShow === 'alerts' && (
                          <div className="alerms">
                            There are no alarm configured to show
                          </div>
                        )}
                        {tableToShow === 'customAlert' && (
                          <div className="alerms">
                            There are no alarms to show
                          </div>
                        )}
                      </Paper>
                    </Box>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SiteOverView;