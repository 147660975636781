import React from "react";
import { Paper, Typography, Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { IoIosCloud } from "react-icons/io";
const TotalSites = ({ SiteStatistics }) => {
  let roofTopSites = 0;
  let utilitySites = 0;
  SiteStatistics?.forEach((site) => {
    const roofTop = Number(site.roofTopSites);
    const utility = Number(site.utilitySites);
    roofTopSites += roofTop;
    utilitySites += utility;
  });

  const totalSites = roofTopSites + utilitySites;

  return (
    <Paper
      className="total-sites"
      elevation={0}
      sx={{
        borderRadius: "20px",
        width: "100%",
        height: "92%",
        padding: "20px",
        borderRadius: "13px",
        display: "flex",
        flexDirection: "column", // Use flex column layout
      }}
    >
      {/* <div style={{ flex: "1" }}>
      <Grid container spacing={1}>
          <Grid item md={8} lg={10} xs={10}>
          <Typography style={{ fontSize: "16px", opacity: "0.7" }}>
          Total Sites
        </Typography>
        <Typography style={{ fontSize: "18px" }}>{totalSites}</Typography> 
          </Grid>
          <Grid item md={2} lg={2} xs={2}>
          <Link to="/menu/Sitelist">
        <Typography style={{ textAlign: "right", margin: "20px 0 0 0" }}>
          {" "}
          <ArrowForwardIcon
            style={{ verticalAlign: "bottom", textAlign: "right" }}
          />
        </Typography>
      </Link>
          </Grid>
        </Grid>
        
      </div> */}
      <Grid container spacing={1}>
        <Grid item md={8} lg={10} xs={10}>
          <Typography style={{ fontSize: "16px", opacity: "0.7" }}>
            Total Sites
          </Typography>
          <Typography style={{ fontSize: "18px" }}>
            {totalSites}
          </Typography>
        </Grid>
        <Grid item md={2} lg={2} xs={2}>
          <Link to="/menu/Sitelist">
            {" "}
            <ArrowForwardIcon
              style={{ verticalAlign: "bottom", textAlign: "right" }}
            />
          </Link>
        </Grid>
      </Grid>

      <div style={{ padding: "25px 0 0 0", border: "" }}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <Typography style={{ fontSize: "16px", opacity: "0.7" }}>
              Roof Top
            </Typography>
            <Typography style={{ fontSize: "1.2rem" }}>
              {roofTopSites}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography style={{ fontSize: "16px", opacity: "0.7" }}>
              Utility
            </Typography>
            <Typography style={{ fontSize: "1.2rem" }}>
              {utilitySites}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default TotalSites;