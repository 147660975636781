import React, { useMemo, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import { fetchEquipmentIdDetails } from '../../Api/ExcelUploadAPi';
// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
const BarChart = (props) => {
  const {
    tableData,
    chartName,
    barchartdata,
    siteId,
    widgetName,
    chartValue,
    range,
  } = props;

  const [barChart, setBarchartData] = React.useState();

  // console.log('tableData:', tableData);
  // console.log("chartName:", chartName);
  // console.log('barchartdata:', barchartdata);
  // console.log("siteId:", siteId);
  // console.log("widgetName:", widgetName);
  // console.log("chartValue:", chartValue);
  // console.log("range:", range);
  // console.log('barChart:', barChart);

  useEffect(() => {
    if (chartName === 'Energy Performance' && range === 'custom') {
      chartValue?.map(async (data) => {
        let equipmentsdata = await fetchEquipmentIdDetails(siteId);
        const convertedData = tableData.reduce((result, item) => {
          const found = result.find(
            (x) => x.name === item.equipmentId.toString()
          );
          if (found) {
            found.data.push(item.todayEnergy);
          } else {
            result.push({
              name: item.equipmentId.toString(),
              data: [item.todayEnergy],
            });
          }
          return result;
        }, []);
        let converteddata = convertedData;
        let dataMap = new Map(
          equipmentsdata.map((item) => [
            String(item.equipmentid),
            item.customernaming,
          ])
        );
        converteddata.forEach((item) => {
          if (dataMap.has(item.name)) {
            item.name = dataMap.get(item.name);
          }
        });
        setBarchartData(convertedData);
      });
    } else if (chartName === 'DG PV Grid Management') {
      // Transformation for DG PV Grid Management chart
      const meters = ['gridmeter', 'solarmeter', 'dgmeter'];
      const allTimestamps = Array.from(
        new Set(
          meters
            .flatMap((meter) => tableData[meter] || [])
            .map((data) => data.timestamp)
        )
      );

      const transformedData = meters.map((meter) => {
        const meterData = tableData[meter] || [];
        const meterValues = allTimestamps.map((timestamp) => {
          const entry = meterData.find((data) => data.timestamp === timestamp);
          return entry ? entry.todayEnergy : 0;
        });

        // Map meter names to desired format
        const meterNameMap = {
          gridmeter: 'Grid',
          solarmeter: 'Solar',
          dgmeter: 'DG',
        };

        return {
          name: meterNameMap[meter],
          data: meterValues,
        };
      });

      setBarchartData(transformedData);
    } else {
      setBarchartData(barchartdata);
    }
  }, [props, chartValue]);

  const chartData = useMemo(() => {
    if (!tableData || chartName === 'DG PV Grid Management') return [];
    return tableData.map((data) => data.todayEnergy);
  }, [tableData, chartName]);

  const timeStampData = useMemo(() => {
    if (!tableData || chartName === 'DG PV Grid Management') {
      // Concatenate the arrays from different meters
      const allMeterData = [].concat(...Object.values(tableData));
      // Extract unique timestamps using Set
      const uniqueTimestamps = [
        ...new Set(allMeterData.map((data) => data.timestamp)),
      ];
      return uniqueTimestamps;
    } else {
      return tableData.map((data) => data.timestamp);
    }
  }, [tableData, chartName]);

  const Irradiation = useMemo(() => {
    if (!tableData || chartName === 'DG PV Grid Management') return [];
    return tableData.map((data) => data.irradiation);
  }, [tableData, chartName]);

  // const formattedDates = timeStampData.map(dateStr => {
  //   const parts = dateStr.split('-');
  //   return `${parts[2]}-${parts[1]}-${parts[0]}`;
  // });
  let uniqueChars = [...new Set(timeStampData)];

  const columnChartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: widgetName,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      // categories: getChartValue?.todayEnergy,timeStampData,
      categories: uniqueChars,
    },
    yAxis: [
      {
        title: {
          text: 'Energy Generation (kWh)',
        },
        style: {
          color: '#666666',
          fontSize: '0.8em',
          fontFamily: 'Inter',
        },
      },
      {
        title: {
          text: 'Irradiation',
        },
        opposite: true,
        visible: chartName === 'Daily Generation' ? true : false,
      },
    ],
    plotOptions: {
      series: {
        stacking: chartName === 'DG PV Grid Management' ? 'normal' : undefined,
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function ({ series, seriesIndex, dataPointIndex, w }) {
        if (chartName === 'DG PV Grid Management') {
          var currentPoint = this,
            currentSeries = currentPoint.series,
            chart = currentSeries.chart,
            stackValues = '';

          chart.series.forEach(function (series) {
            series.points.forEach(function (point) {
              if (
                currentSeries.userOptions.stack === series.userOptions.stack &&
                currentPoint.key === point.category
              ) {
                stackValues +=
                  series.name + ': ' + point.y.toFixed(2) + '  kWh' + '<br/>';
              }
            });
          });

          return (
            '<div class="custom-tooltip">' +
            '<b>Energy Generation</b>' +
            '<br/><b>' +
            this.x +
            '</b><br/>' +
            stackValues +
            'Total: ' +
            this.point.stackTotal.toFixed(2) +
            '  kWh' +
            '</div>'
          );
        } else {
          // Default tooltip for other charts
          if (this.series.name === 'Energy Generation (kWh)') {
            return `<div class="custom-tooltip">
          <p>${this.point.category}</p>
          <p>Energy Generation: ${this.point.y} (kWh)</p>
        </div>`;
          } else if (this.series.name === 'Irradiation') {
            return `<div class="custom-tooltip">
          <p>${this.point.category}</p>
          <p>Irradiation: ${this.point.y}</p>
        </div>`;
          } else {
            return `<div class="custom-tooltip">
          <p>${this.point.category}</p>
          <p>Energy Generation: ${this.point.y} (kWh)</p>
        </div>`;
          }
        }
      },
    },

    series:
      chartName === 'Daily Generation'
        ? [
            {
              name: 'Energy Generation (kWh)',
              data: chartData,
              type: 'column',
              color: 'LightSkyBlue',
            },
            {
              name: 'Irradiation',
              data: Irradiation,
              type: 'line',
              yAxis: 1,
              color: 'Navy',
            },
          ]
        : tableData && chartName === 'DG PV Grid Management'
        ? (barChart ?? [])
            .map((item) => ({
              ...item,
              data: item.data.map((value) => Number(value.toFixed(2))),
              color:
                item.name === 'Grid'
                  ? 'Grey'
                  : item.name === 'Solar'
                  ? 'Orange'
                  : item.name === 'DG'
                  ? 'LightSkyBlue'
                  : undefined,
            }))
            .sort((a, b) => {
              const sumA = a.data.reduce((sum, value) => sum + value, 0);
              const sumB = b.data.reduce((sum, value) => sum + value, 0);
              return sumA - sumB; // Sort in ascending order of total values
            })
        : barChart,
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={columnChartOptions} />
    </div>
  );
};
export default BarChart;
