import axios from "axios";

export const SiteDetails = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_DASHBOARD
      }=${sessionStorage.getItem("id")}`
    );
    // const response = await axios.get(`${process.env.REACT_APP_DASHBOARD}=${sessionStorage.getItem('id')}`);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
