import React, { useMemo, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import { fetchEquipmentIdDetails } from '../../Api/ExcelUploadAPi';
// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
const CombinedChart = (props) => {
  const { tableData, WidgetName, customView, chartName } = props;

  // console.log(tableData, "tableData");
  const [barChart, setBarchartData] = React.useState();

  useEffect(() => {
    setBarchartData(tableData);
  }, [props]);

  const chartData = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => data.todayEnergy);
  }, [tableData]);

  const specificYiled = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => data.specificYield);
  }, [tableData]);

  const timeStampData = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => data.timeStamp);
  }, [tableData]);

  const equipmentName = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => data.equipmentName);
  }, [tableData]);

  /*--------------------------- Actual vs Expected ------------------*/

  const Irradiation = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => data.irradiation);
  }, [tableData]);

  const ExceptedEnergy = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => data.exceptedEnergy);
  }, [tableData]);

  const timeStampDataActual = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => data.timestamp);
  }, [tableData]);

  // console.log(Irradiation,"equipmentName",ExceptedEnergy,timeStampDataActual)
  // const formattedDates = timeStampData?.map(dateStr => {
  //   const parts = dateStr?.split('-');
  //   return `${parts[2]}-${parts[1]}-${parts[0]}`;
  // });
  let uniqueChars = [...new Set(timeStampData)];
  let dataUnique = [...new Set(timeStampDataActual)];

  function convertDateFormat(inputDate) {
    const parts = inputDate.split('-');
    if (parts.length === 3) {
      const [year, month, day] = parts;
      return `${day}-${month}-${year}`;
    } else {
      return 'Invalid date format';
    }
  }

  const columnChartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: chartName === 'Specific Yield' ? ` ${WidgetName} ` : WidgetName,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      //  categories: getChartValue?.todayEnergy,
      categories:
        chartName === 'Actual Vs Expected'
          ? dataUnique
          : chartName === 'Specific Yield' && customView.range === 'daily'
          ? equipmentName
          : uniqueChars,
    },
    yAxis: [
      {
        title: {
          text: 'Energy Generation (kWh)',
        },
        style: {
          color: '#666666',
          fontSize: '0.8em',
          fontFamily: 'Inter',
        },
      },
      {
        title: {
          text: 'Specific Yield (AC)',
        },
        opposite: true,
        visible: chartName === 'Actual Vs Expected' ? false : true,
        min: 0,
        max: 10,
      },
      {
        title: {
          text: 'Irradiation (Wh/m2)',
        },
        opposite: true,
        visible: chartName === 'Actual Vs Expected' ? true : false,
        // min: 0,
        // max: 10,
      },
    ],
    tooltip: {
      useHTML: true,
      formatter: function ({ series, seriesIndex, dataPointIndex, w }) {
        if (this.series.name === 'Energy Generation (kWh)') {
          return `<div class="custom-tooltip">
            <p>${this.point.category}</p>
            <p>Energy Generation: ${this.point.y} (kWh)</p>
          </div>`;
        } else if (this.series.name === 'Specific Yield') {
          return `<div class="custom-tooltip">
            <p>${this.point.category}</p>
            <p>Specific Yield (AC): ${this.point.y} </p>
          </div>`;
        } else if (this.series.name === 'Irradiation') {
          return `<div class="custom-tooltip">
            <p>${this.point.category}</p>
            <p>Irradiation (Wh/m2): ${this.point.y} </p>
          </div>`;
        } else if (this.series.name === 'Today Energy') {
          return `<div class="custom-tooltip">
            <p>${this.point.category}</p>
            <p>Today Energy (kWh): ${this.point.y} </p>
          </div>`;
        } else if (this.series.name === 'ExceptedEnergy') {
          return `<div class="custom-tooltip">
            <p>${this.point.category}</p>
            <p>Excepted Energy (kWh): ${this.point.y} </p>
          </div>`;
        }
      },
    },
    series:
      chartName === 'Specific Yield'
        ? [
            {
              name: 'Energy Generation (kWh)',
              data: chartData,
              type: 'column',
              color: 'LightSkyBlue',
            },
            {
              name: 'Specific Yield',
              data: specificYiled,
              type: 'line',
              yAxis: 1,
              color: 'Navy',
            },
          ]
        : chartName === 'Actual Vs Expected'
        ? [
            {
              name: 'Today Energy',
              data: chartData,
              yAxis: 0,
              type: 'column',
              color: 'LightSkyBlue',
            },
            {
              name: 'ExceptedEnergy',
              data: ExceptedEnergy,
              type: 'line',
              // yAxis: 0,
              color: 'Navy',
            },
            {
              name: 'Irradiation',
              data: Irradiation,
              type: 'line',
              yAxis: 2,
              color: '#AD1378',
            },
          ]
        : '',
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={columnChartOptions} />
    </div>
  );
};
export default CombinedChart;
