import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Modal,
  Typography,
  Switch,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './SiteStyle.css';
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}
const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3, 9),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];
const headCells = [
  {
    id: 'name',
    label: 'Site Name',
  },
  {
    id: 'name',
    label: 'Site Id',
  },
  {
    id: 'calories',
    label: 'Status',
  },
  {
    id: 'fat',
    label: 'Today Energy (kWh)',
  },
  {
    id: 'carbs',
    label: 'Equipment Type',
  },
  {
    id: 'protein',
    label: 'Status',
  },
  {
    id: 'protein',
    label: 'Today Energy',
  },
  {
    id: 'protein',
    label: 'Total Energy',
  },
  {
    id: 'protein',
    label: 'Last Data Received ',
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead stickyHeader>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
export default function AllAlerts(props) {
  const { tableValue, isModalOpen, toggleModal } = props;
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [sortColumn, setSortColumn] = useState(null);
  //------------------Enable nd Diabled---------------//
  const [filteredTableValue, setFilteredTableValue] = useState(tableValue);
  const defaultLoadingStates = {
    errorCode: true,
    errorMessage: true,
    eventTime: true,
    Occurrence: true,
    equipmentName: true,
    priority: true,
    capacity: true,
  };
  const [errorCodeLoading, setErrorCodeLoading] = useState(
    defaultLoadingStates.errorCode
  );
  const [errorMessageLoading, setErrorMessageLoading] = useState(
    defaultLoadingStates.errorMessage
  );
  const [eventTimeLoading, setEventTimeLoading] = useState(
    defaultLoadingStates.eventTime
  );
  const [OccurrenceLoading, setOccurrenceLoading] = useState(
    defaultLoadingStates.Occurrence
  );
  const [equipmentNameLoading, setEquipmentNameLoading] = useState(
    defaultLoadingStates.equipmentName
  );
  const [priorityLoading, setPriorityLoading] = useState(
    defaultLoadingStates.priority
  );
  const [capacityLoading, setCapacityLoading] = useState(
    defaultLoadingStates.capacity
  );
  const handleToggle = () => {
    const filteredValues = tableValue?.filter((row) => {
      return (
        (errorCodeLoading && row.errorCode) ||
        (errorMessageLoading && row.errorMessage) ||
        (eventTimeLoading && row.eventTime) ||
        (OccurrenceLoading && row.Occurrence) ||
        (equipmentNameLoading && row.equipmentName) ||
        (priorityLoading && row.priority) ||
        (capacityLoading && row.capacity)
      );
    });
    setFilteredTableValue(filteredValues);
  };
  useEffect(() => {
    resetToDefaultColumns();
  }, []);
  const resetToDefaultColumns = useCallback(() => {
    setErrorCodeLoading(defaultLoadingStates.errorCode);
    setErrorMessageLoading(defaultLoadingStates.errorMessage);
    setEventTimeLoading(defaultLoadingStates.eventTime);
    setOccurrenceLoading(defaultLoadingStates.Occurrence);
    setEquipmentNameLoading(defaultLoadingStates.equipmentName);
    setPriorityLoading(defaultLoadingStates.priority);
    setCapacityLoading(defaultLoadingStates.capacity);
    handleToggle();
  }, [defaultLoadingStates]);
  /*-------------sorting--------------*/
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    sortData(tableValue, newOrder, property);
  };
  const sortData = (data, sortOrder, field) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];
      if (sortOrder === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else if (sortOrder === 'desc') {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
      return 0;
    });
    setFilteredTableValue(sortedData);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.alerts);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (filteredTableValue?.length || 0) - page * rowsPerPage
    );
  function getStatusIcon(status) {
    switch (status) {
      case 'Active':
        return '#0bdf2a';
      case 'Offline':
        return 'grey';
      case 'Warning':
        return 'orange';
      case 'Down':
        return 'red';
      default:
        return 'black';
    }
  }
  console.log(tableValue, 'tableValue');
  function addTimeToTimestamp(timestampText, hoursToAdd, minutesToAdd) {
    const [datePart, timePart] = timestampText.split(' ');
    const [day, month, year] = datePart.split('-');
    const [hours, minutes] = timePart.split(':');

    const parsedDay = parseInt(day);
    const parsedMonth = parseInt(month) - 1;
    const parsedYear = parseInt(year);
    const parsedHours = parseInt(hours);
    const parsedMinutes = parseInt(minutes);

    let eventTimestamp = new Date(
      parsedYear,
      parsedMonth,
      parsedDay,
      parsedHours,
      parsedMinutes
    );

    eventTimestamp.setHours(eventTimestamp.getHours() + hoursToAdd);
    eventTimestamp.setMinutes(eventTimestamp.getMinutes() + minutesToAdd);

    const updatedDay = eventTimestamp.getDate().toString().padStart(2, '0');
    const updatedMonth = (eventTimestamp.getMonth() + 1)
      .toString()
      .padStart(2, '0');
    const updatedYear = eventTimestamp.getFullYear().toString();
    const updatedHours = eventTimestamp.getHours().toString().padStart(2, '0');
    const updatedMinutes = eventTimestamp
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const updatedTimestampText = `${updatedDay}-${updatedMonth}-${updatedYear} ${updatedHours}:${updatedMinutes}`;

    return updatedTimestampText;
  }
  return (
    <div>
      {/* <div>
                <div>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                mr: 0,
                                mb: 2,
                                mt: 2,
                                width: '100%',
                                height: "7vh",
                            },
                        }}
                    >
                    </Box>
                </div>
            </div> */}
      <div>
        <div>
          {filteredTableValue && filteredTableValue.length > 0 && (
            <Paper sx={{ width: '100%', mb: 2 }} elevation={0}>
              <TableContainer sx={{ height: '50vh' }}>
                <Table
                  aria-labelledby="tableTitle"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      {errorCodeLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            // active={orderBy === 'errorCode' ? order : 'desc'}
                            direction={orderBy === 'errorCode' ? order : 'desc'}
                            onClick={() => handleRequestSort('errorCode')}
                          >
                            Error Code
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {errorMessageLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            // active={orderBy === 'errorMessage' ? order : 'desc'}
                            direction={
                              orderBy === 'errorMessage' ? order : 'desc'
                            }
                            onClick={() => handleRequestSort('errorMessage')}
                          >
                            Error Message
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {eventTimeLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            // active={orderBy === 'eventTimestampText' ? order : 'desc'}
                            direction={
                              orderBy === 'eventTimestampText' ? order : 'desc'
                            }
                            onClick={() =>
                              handleRequestSort('eventTimestampText')
                            } // Use 'siteName' here
                          >
                            Event Time
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {OccurrenceLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            // active={orderBy === 'eventOccurrence' ? order : 'desc'}
                            direction={
                              orderBy === 'eventOccurrence' ? order : 'desc'
                            }
                            onClick={() => handleRequestSort('eventOccurrence')} // Use 'siteName' here
                          >
                            Occurrence
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {priorityLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            // active={orderBy === 'priority' ? order : 'desc'}
                            direction={orderBy === 'priority' ? order : 'desc'}
                            onClick={() => handleRequestSort('priority')} // Use 'siteName' here
                          >
                            Priority
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {equipmentNameLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            // active={orderBy === 'customerNaming' ? order : 'desc'}
                            direction={
                              orderBy === 'customerNaming' ? order : 'desc'
                            }
                            onClick={() => handleRequestSort('customerNaming')} // Use 'siteName' here
                          >
                            Equipment Name
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {capacityLoading && (
                        <TableCell align="center">
                          <TableSortLabel
                            // active={orderBy === 'capacity' ? order : 'desc'}
                            direction={orderBy === 'capacity' ? order : 'desc'}
                            onClick={() => handleRequestSort('capacity')} // Use 'siteName' here
                          >
                            Capacity
                          </TableSortLabel>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTableValue !== undefined
                      ? filteredTableValue
                          ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage) // Display rows based on pagination
                          .map((row, index) => {
                            return (
                              <TableRow
                                hover
                                onClick={(event) =>
                                  handleClick(event, row.siteName)
                                }
                                tabIndex={-1}
                                key={row.siteName}
                                sx={{
                                  cursor: 'pointer',
                                }}
                              >
                                {errorCodeLoading && (
                                  <TableCell align="center">
                                    {row.errorCode}
                                  </TableCell>
                                )}
                                {errorMessageLoading && (
                                  <TableCell align="center">
                                    {row.errorMessage}
                                  </TableCell>
                                )}
                                {eventTimeLoading && (
                                  <TableCell align="center">
                                    {addTimeToTimestamp(
                                      row.eventTimestampText,
                                      5,
                                      30
                                    )}
                                  </TableCell>
                                )}
                                {OccurrenceLoading && (
                                  <TableCell align="center">
                                    {row.eventOccurrence}
                                  </TableCell>
                                )}
                                {priorityLoading && (
                                  <TableCell align="center">
                                    {row.priority === 1
                                      ? 'High'
                                      : row.priority === 2
                                      ? 'Low'
                                      : row.priority === 3
                                      ? ''
                                      : 'Medium'}
                                  </TableCell>
                                )}
                                {equipmentNameLoading && (
                                  <TableCell align="center">
                                    {row.customerNaming}
                                  </TableCell>
                                )}
                                {capacityLoading && (
                                  <TableCell align="center">
                                    {Number(row.capacity).toFixed(1)}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })
                      : ''}
                    {emptyRows > 0 && (
                      <TableRow>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredTableValue?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
          )}
        </div>
      </div>
      <div>
        <Modal
          open={isModalOpen}
          onClose={toggleModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            elevation={0}
            className="total-equip"
            sx={{
              width: '19%',
              maxWidth: '250px',
              height: 'max-content',
              overflow: 'hidden',
              backgroundColor: 'aliceblue',
              padding: '10px',
              position: 'relative',
              marginLeft: '77%',
              marginTop: '20.5%',
            }}
          >
            <div>
              <Grid container spacing={10}>
                <Grid item xs={6} md={14}>
                  <Typography
                    style={{
                      color: 'rgb(18, 127, 216)',
                      marginLeft: '80%',
                      marginTop: '4%',
                      cursor: 'pointer',
                    }}
                    onClick={toggleModal}
                  >
                    <CloseOutlinedIcon />
                  </Typography>
                  {errorCodeLoading ? (
                    <FormControlLabel
                      sx={{
                        display: 'block',
                      }}
                      control={
                        <Switch
                          checked={errorCodeLoading}
                          onChange={() =>
                            setErrorCodeLoading(!errorCodeLoading)
                          }
                          color="primary"
                          disabled
                        />
                      }
                      label="Error Code "
                    />
                  ) : (
                    <FormControlLabel
                      sx={{
                        display: 'block',
                      }}
                      control={<Switch color="primary" />}
                      label="Error Code"
                    />
                  )}
                  <FormControlLabel
                    sx={{
                      display: 'block',
                    }}
                    control={
                      <Switch
                        checked={errorMessageLoading}
                        onChange={() =>
                          setErrorMessageLoading(!errorMessageLoading)
                        }
                        color="primary"
                      />
                    }
                    label="Error Message"
                  />
                  <FormControlLabel
                    sx={{
                      display: 'block',
                    }}
                    control={
                      <Switch
                        checked={eventTimeLoading}
                        onChange={() => setEventTimeLoading(!eventTimeLoading)}
                        color="primary"
                      />
                    }
                    label="Event Time"
                  />
                  <FormControlLabel
                    sx={{
                      display: 'block',
                    }}
                    control={
                      <Switch
                        checked={OccurrenceLoading}
                        onChange={() =>
                          setOccurrenceLoading(!OccurrenceLoading)
                        }
                        color="primary"
                      />
                    }
                    label="Occurrence"
                  />
                  <FormControlLabel
                    sx={{
                      display: 'block',
                    }}
                    control={
                      <Switch
                        checked={priorityLoading}
                        onChange={() => setPriorityLoading(!priorityLoading)}
                        color="primary"
                      />
                    }
                    label="Priority "
                  />
                  <FormControlLabel
                    sx={{
                      display: 'block',
                    }}
                    control={
                      <Switch
                        checked={equipmentNameLoading}
                        onChange={() =>
                          setEquipmentNameLoading(!equipmentNameLoading)
                        }
                        color="primary"
                      />
                    }
                    label="Equipment Name "
                  />
                  <FormControlLabel
                    sx={{
                      display: 'block',
                    }}
                    control={
                      <Switch
                        checked={capacityLoading}
                        onChange={() => setCapacityLoading(!capacityLoading)}
                        color="primary"
                      />
                    }
                    label=" Capacity"
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Modal>
      </div>
    </div>
  );
}
