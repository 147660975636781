import React, { useState, useEffect, useMemo } from "react";
import { Grid, Box, useTheme, useMediaQuery } from "@mui/material";
import Barchart from "./BarChart";
import PerformanceMetricsChart from "./PerformanceMetricsChart";
import { SiteDetails } from "../../../Api/DashboardApi";
import SiteStatusChart from "./SiteStatusChart";
import Skeleton from '@mui/material/Skeleton';
const SiteChartGrid = (props) => {
  const { SiteEnergy, SiteStatistics, EventStatistcs, SiteEnergyStatistics, loading } = props;
  const [totalSites, setTotalSites] = useState(0);
  const [activeSite, setActiveSite] = useState(0);
  const [downSite, setDownSite] = useState(0);
  const [offlinesite, setOfflineSite] = useState(0);
  const [warningsite, setWarningSite] = useState(0);
  const [loadFactor, setLoadFactor] = useState(0);
  //const [EventStatistcs, setEventStatistcs] = React.useState();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  console.log("EventStatistcs in BarChart: ", EventStatistcs);
  console.log("SiteEnergyStatistics in BarChart: ", SiteEnergyStatistics);


  useEffect(() => {
    fetchTotalSites();
    // fetchData();
  }, [props]);

  // const fetchData = async () => {
  //   try {
  //     const data = await SiteDetails();
  //     console.log(data);
  //     setEventStatistcs(data?.EventStatistcs);
  //     console.log(data?.EventStatistcs);
  //   } catch (e) {
  //     console.log(e, "test");
  //   }
  // };

  const fetchTotalSites = async () => {
    try {
      if (SiteStatistics && SiteStatistics.length > 0) {
        let totalSites = 0;
        let activeCount = 0;
        let downCount = 0;
        let offlineCount = 0;
        let warningCount = 0;

        SiteStatistics.forEach((site) => {
          totalSites++;
          activeCount += Number(site.activeStatus);
          downCount += Number(site.downStatus);
          offlineCount += Number(site.offlineStatus);
          warningCount += Number(site.warningStatus);
        });

        const loadFactorValue = SiteEnergy?.loadFactor || "0%";
        const factor = parseFloat(loadFactorValue.replace("%", ""));
        const limitedLoadFactor = factor <= 25 ? factor : 25;
        setTotalSites(totalSites);
        setActiveSite(activeCount);
        setDownSite(downCount);
        setOfflineSite(offlineCount);
        setWarningSite(warningCount);
        setLoadFactor(factor);
      }
    } catch (e) {
      console.log(e);
    }
  };
  console.log(loadFactor, "Testing")
  console.log(totalSites);
  console.log(
    typeof activeSite,
    typeof downSite,
    typeof warningsite,
    typeof offlinesite
  );

  const series = [activeSite, downSite, warningsite, offlinesite];
  const options = {
    chart: {
      width: isSmallScreen ? "100%" : "35%",
      type: "donut",
    },
    colors: ["#71DD37", "#FF1913", "#FFB10E", "#98A3B2"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: !isSmallScreen,
    },
    tooltip: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  const RadialChart = {
    series: [loadFactor <= 25 ? loadFactor : 25],
    options: {
      chart: {
        height: "50%",
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return loadFactor <= 25 ? parseInt(loadFactor) : "25";
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Percent"],
      return: loadFactor <= 25 ? loadFactor : "25",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
          minHeight: "20vh",
          maxHeight: "max-content",
          m: 0,
          padding: "0",
        },
      }}
    >
      <Grid container columnSpacing={1.5} rowSpacing={1.5}>
        {loading ? (

          <React.Fragment>
            <Skeleton variant="rectangular" width="32%" height={450} sx={{ marginTop: '1%' }} />
          </React.Fragment>
        ) : (
          <Grid item xs={12} md={12} lg={4} color={"red"}>
            <SiteStatusChart
              totalSites={totalSites}
              activeSite={activeSite}
              downSite={downSite}
              warningsite={warningsite}
              offlinesite={offlinesite}
              options={options}
              series={series}
            />
          </Grid>)}
        {loading ? (

          <React.Fragment>
            <Skeleton variant="rectangular" width="32%" height={450} sx={{ marginTop: '1%', marginLeft: "1%" }} />
          </React.Fragment>
        ) : (
          <Grid item xs={12} md={12} lg={4}>
            {/* <PerformanceMetricsChart
              loadFactor={loadFactor}
              RadialChart={RadialChart}
            /> */}
            <PerformanceMetricsChart SiteEnergyStatistics={SiteEnergyStatistics} />
          </Grid>)}
        {loading ? (

          <React.Fragment>
            <Skeleton variant="rectangular" width="32%" height={450} sx={{ marginTop: '1%', marginLeft: "1%" }} />
          </React.Fragment>
        ) : (
          <Grid item xs={12} md={12} lg={4}>
            <Barchart EventStatistcs={EventStatistcs} />
          </Grid>
        )}

      </Grid>
    </Box>
  );
};

export default SiteChartGrid;