
import React from "react";
import { Paper, Grid, Typography } from "@mui/material";
import { MdEnergySavingsLeaf } from "react-icons/md";

const TodaysEnergy = ({ SiteEnergy }) => {
  return (
    <Paper
      elevation={0}
      className="total-energy"
      sx={{
        width: "100%",
        height: "92%",
        padding: "20px",
        borderRadius: "13px",
        display: "flex",
        flexDirection: "column",
      }}
    >
        <Grid container spacing={1}>
          <Grid item md={8} lg={10} xs={10}>
            <Typography style={{ fontSize: "16px", opacity: "0.7" }}>
              Today's Energy
            </Typography>
            <Typography style={{ fontSize: "18px" }}>
              {SiteEnergy?.todayEnergy}
            </Typography>
          </Grid>
          <Grid item md={2} lg={2} xs={2}>
            <MdEnergySavingsLeaf
              style={{ fontSize: "33px", color: "#000440" }}
            />
          </Grid>
        </Grid>
      <div style={{ margin: "25px 0 0 0" }}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <Typography style={{ fontSize: "16px", opacity: "0.7" }}>
              Total Energy
            </Typography>
            <Typography style={{ fontSize: "18px" }}>
              {SiteEnergy?.totalEnergy}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default TodaysEnergy;

