import React, { useState } from "react";
import './Alerts.css';
import { Typography, Grid, Button, Paper, Box, Drawer, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Autocomplete, Stack } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import {useNavigate } from "react-router-dom";
const buttonStyle = {
    textTransform: "capitalize",
    boxShadow: "4px 4px 17px rgba(0, 0, 0.50, 0.50)",
    fontWeight: "bold",
    backgroundColor: "#000440",
    color: "white"
    // backgroundColor:'#09001A'
};
const ac_power_text = {
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginLeft: "2%",
};
const ac_power_text_info = {
    marginTop: "3%",
    marginLeft: "2%",
}
// const handleCancel = () => {
//     navigate("/menu/alerts")
// }
export default function Createreport() {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    const location = useLocation();
    const selectedRow = location.state && location.state.selectedRow;
    /*--------------------Drawer--------------*/
    const [state, setState] = React.useState({
        right: false
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    const list = (anchor) => (
        <Box
            // sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
            sx={{ width: "600px", height: "20%" }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {['Inbox', 'create'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {<Typography> create </Typography>}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ marginLeft: "2%" }}>
                            <Grid >
                                <Typography> Select Site*</Typography>
                                <TextField
                                    sx={{ width: "12vw" }}
                                    id="outlined-basic"
                                    // defaultValue={editValue}
                                    size="small"
                                    variant="outlined"
                                    name="selectSite"
                                    placeholder="KS Site"
                                // onChange={(e) => handleChange(e)}
                                />
                            </Grid>
                        </div>
                        <div style={{ marginRight: "2%" }}>
                            <Grid >
                                <Typography> Select Equipment*</Typography>
                                <TextField
                                    sx={{ width: "12vw" }}
                                    id="outlined-basic"
                                    // defaultValue={editValue}
                                    size="small"
                                    variant="outlined"
                                    name="selectEquipment"
                                    placeholder="Meter"
                                // onChange={(e) => handleChange(e)}
                                />
                            </Grid>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", margin: "80px 0 0 0", }}>
                        <div style={{ marginLeft: "2%" }}>
                            <Grid >
                                <Typography> Select Category*</Typography>
                                <TextField
                                    sx={{ width: "12vw" }}
                                    id="outlined-basic"
                                    // defaultValue={editValue}
                                    size="small"
                                    variant="outlined"
                                    name="selectCategory"
                                    placeholder="Maintenance"
                                // onChange={(e) => handleChange(e)}
                                />
                            </Grid>
                        </div>
                        <div style={{ marginRight: "2%" }}>
                            <Grid >
                                <Typography> Select Type*</Typography>
                                <TextField
                                    sx={{ width: "12vw" }}
                                    id="outlined-basic"
                                    // defaultValue={editValue}
                                    size="small"
                                    variant="outlined"
                                    name="selectType"
                                    placeholder="Grid Failure"
                                // onChange={(e) => handleChange(e)}
                                />
                            </Grid>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", margin: "80px 0 0 0", }}>
                        <div style={{ marginLeft: "2%" }}>
                            <Grid >
                                <Typography> Select Priority*</Typography>
                                <TextField
                                    sx={{ width: "12vw" }}
                                    id="outlined-basic"
                                    // defaultValue={editValue}
                                    size="small"
                                    variant="outlined"
                                    name="selectCategory"
                                    placeholder="Select"
                                // onChange={(e) => handleChange(e)}
                                />
                            </Grid>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", margin: "80px 0 0 0", }}>
                        <div style={{ marginLeft: "2%" }}>
                            <Grid >
                                <Typography>Enter Description*</Typography>
                                <TextField
                                    sx={{ width: "12vw" }}
                                    id="outlined-basic"
                                    // defaultValue={editValue}
                                    size="small"
                                    variant="outlined"
                                    name="selectCategory"
                                    placeholder="Enter"
                                // onChange={(e) => handleChange(e)}
                                />
                            </Grid>
                        </div>
                    </div>
                    <form className="uploader-form">
                        <div>
                            <Grid>
                                <input
                                    type="file"
                                    accept=".jpg, .png, .gif"
                                    label="Select logo"
                                    className="input-file"
                                // ref={fileInput}
                                />
                                <svg
                                    viewBox="-400 0 2024 1024"
                                    fill="currentColor"
                                    height="5em"
                                    width="5em"
                                    className="svg-icon"
                                >
                                </svg>
                            </Grid>
                        </div>
                    </form>
                </div>
            </List>
        </Box >
    );
    return (
        <div>
            <div className="analytics">
                <div style={{marginLeft: '2%' }}>
                    <Typography style={{ fontSize: "1.7rem", fontWeight: "bold" ,  }}>
                        <KeyboardBackspaceIcon style={{ verticalAlign: 'middle', fontSize: '1.7rem',  }} />
                        View Alert - {selectedRow}
                    </Typography>
                </div>
                <div>
                    <Stack spacing={3} direction="row">
                        {["right"].map((anchor) => (
                            <React.Fragment key={anchor}>
                                <Button onClick={toggleDrawer(anchor, true)} style={buttonStyle}>Create Ticket</Button>
                                <SwipeableDrawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                    onOpen={toggleDrawer(anchor, true)}
                                >
                                    {list()}
                                </SwipeableDrawer>
                            </React.Fragment>
                        ))}
                    </Stack>
                </div>

            </div>
            { /*--------------------Alert Details-------------*/}
            <div style={{ marginTop: "2%" }}>
                <Grid container spacing={0}>
                    <Grid item xs={3}>
                        <Box
                            sx={{
                                height: "70vh",
                                width: "120%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingLeft: "7%",
                            }}>
                            <Paper elevation={0} style={{ height: "100%", width: "auto", padding: "4%", }}>
                                <Typography style={{ marginBottom: "20px" }}>
                                    Alert Details
                                </Typography>
                                <Grid container spacing={10} columns={16}  >
                                    <Grid item xs={6}>
                                        <Typography style={{ fontSize: "16px", opacity: "0.7", whiteSpace: "nowrap" }}>
                                            Alert ID
                                        </Typography>
                                        <Typography style={{ fontSize: "16px", whiteSpace: "nowrap" }}>
                                            INT-3243
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{ fontSize: "16px", opacity: "0.7", whiteSpace: "nowrap" }}>
                                            Equipment
                                        </Typography>
                                        <Typography style={{ fontSize: "16px", whiteSpace: "nowrap", }}>
                                            Scheneider, PM-5562
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{ fontSize: "16px", opacity: "0.7", whiteSpace: "nowrap" }}>
                                            Time Threshold
                                        </Typography>
                                        <Typography style={{ fontSize: "16px", whiteSpace: "nowrap" }}>
                                            12 Hours
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{ fontSize: "16px", opacity: "0.7", whiteSpace: "nowrap" }}>
                                            Alert Status
                                        </Typography>
                                        <Typography style={{ fontSize: "16px", whiteSpace: "nowrap" }}>
                                            Open
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{ fontSize: "16px", opacity: "0.7", whiteSpace: "nowrap" }}>
                                            Night Time Filter
                                        </Typography>
                                        <Typography style={{ fontSize: "16px", whiteSpace: "nowrap" }}>
                                            In-Active
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <Box
                            sx={{
                                width: "auto",
                                height: "13vh",
                                marginLeft: "6vw"
                            }}
                        >
                            <Paper elevation={0} style={{ height: "auto" }}>
                                <Typography style={ac_power_text}>
                                    AC Power Down - KS Site
                                </Typography>
                                <Typography style={ac_power_text_info}>
                                    Information will come here
                                </Typography>

                            </Paper>
                        </Box>
                        <Box
                            sx={{
                                width: "auto",
                                height: "auto",
                                marginLeft: "6vw",

                            }}
                        >
                            <Paper elevation={0} style={{ height: "57.5vh", marginTop: "-1%" }}>

                                <Typography style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                    Graph will be shown here
                                </Typography>
                            </Paper>
                        </Box>
                    </Grid>

                </Grid>
            </div>
        </div>
    )
}