import axios from 'axios';



/*-----------------------------daily Generation   Apply Call   ------------------------*/

export const DataAnalystSubmit = async (obj) => {
  console.log(obj)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ANALYTIC_APPLY}`,obj);
      
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };

/*-----------------------------Equipment    Apply Call   ------------------------*/

export const EquipmentApi = async (obj) => {
  console.log(obj)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ANALYTIC_APPLY}`,obj);
      
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };


/*----------------------------- Submit call  ------------------------*/

export const SubmitDataAnalyst = async (data) => {
  try {
    console.log(data,"data");
    // const response = await axios.post(`${process.env.REACT_API_ANALYTIC_SUBMIT}`,data);
    const response = await axios.post(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_ANALYTIC_SUBMIT}`,data);
    
  
    return(response.data)
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};


/*--------------------------------Get Widzard call ---------------------- */ 

export const getDataAnalyst = async (obj) => {

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_WIDGETS_GET}`,obj);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};



/*------------------------------------ Energy Performance Apply Changes ------------------------------ */

 export const equipmentApplyChanges = async (data) =>{ 
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_ANALYTIC_EQUIPMENT_APPLY}`,data);
    return response.data;
  } catch (error) {
    throw error;
  }

 }

/*------------------------------------ Parameter Comaprison Apply Changes ------------------------------ */

export const parameterComparisonApplyChanges = async (data) =>{ 
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_PARAMTERCOMPARISON}`,data);
    return response.data;
  } catch (error) {
    throw error;
  }

 }

 


 /*-----------------------------------------------------Delete Wizard API----------------------------------*/

 export const WizardDeleteCall = async (data) =>{ 
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DELETELIST}= ${data}`);
    console.log(response.data)
    return response.data;
  } catch (error) {
    throw error;
  }

 }
 
 /*------------------------------------------ Specific Yeild ----------------------------- */ 


 export const SpecificYeildChanges = async (data) =>{ 
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_SPECIFIC_YIELD}`,data);
    return response.data;
  } catch (error) {
    throw error;
  }

 }




  /*------------------------------------------ Specific Yeild ----------------------------- */ 


  export const SpecificYeildForDaily = async (data) =>{ 
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_SPECIFIC_YIELD_DAILY}`,data);
      console.log(response,"response");
      return response.data;
    } catch (error) {
      throw error;
    }
  
   }


  /*------------------------------------------ Actual Vs Expected ----------------------------- */ 
   export const ExpectedActual = async (data) =>{ 
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_EXPECTEDACTUAL}`,data);
      return response.data;
    } catch (error) {
      throw error;
    }
  
   }

    /*------------------------------------------ DGPV Grid Management ----------------------------- */ 
    export const DieselGenrator = async (data) =>{ 
      // console.log(data,"data")
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_DGPVGENERATOR}`,data);
        console.log(response,'response');
         return response.data;
      
      } catch (error) {
        throw error;
      }
    
     }