import React, { useState, useEffect, useRef } from "react";
import "./Alerts.css";
import { Link } from "react-router-dom";
/*---------------------------Mui Components ----------------------------------*/
import {
  Button,
  Stack,
  Alert,
  Box,
  Paper,
  TextField,
  Autocomplete,
  Typography,
  CardContent,
  Card,
  FormControl,
  Modal,
  Grid,
  Switch,
  TableSortLabel,
  FormControlLabel,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  InputAdornment,
  TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
/*-------------------------------API----------------------------*/
import {
  fetchAlertsTable,
  updateEditCall,
  postAlertsSave,
} from "../../../Api/AlertsComponentApi";
/*---------------------------Mui Icons --------------------------*/
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MuiAlert from "@mui/material/Alert";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
/*------------------------- External compoenents ---------------------------*/

import {
  fetchSiteDetails,
  fetchEquipmentIdDetails,
  fetchParameterListDetails,
} from "../../../Api/ExcelUploadAPi";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const Status = [
  { label: "Active", value: "active" },
  { label: "In Active", value: "inactive" },
];
const Logic = [
  { label: "Less", value: "Less" },
  { label: "Greater", value: "Greater" },
  { label: "Equal", value: "Equal" },
];

const typoStyle = {
  textTransform: "capitalize",
  opacity: "0.8",
};
const typoStyleTime = {
  textTransform: "capitalize",
  opacity: "0.8",
  marginTop: "20px",
};
/*--------------------- Accept Numeric Only ---------------------*/
const handleInput = (event) => {
  let input = event.target.value;
  input = input.replace(/[^0-9]/g, "");
  if (input.length > 50) {
    input = input.slice(0, 10);
  }
  event.target.value = input;
};
const CustomizeAlerts = () => {
  const navigate = useNavigate();
  const [site, setSite] = React.useState("");
  const [loading, setloading] = useState(false);
  const [tableValue, setTableValue] = useState([]);
  const [editValue, setEditValue] = useState();
  const [email, setEmail] = useState(sessionStorage.getItem("email"));
  const [validEmail, setValidEmail] = useState(true);
  const [activeFlag, setactiveFlag] = useState();
  const [siteIdName, setSiteId] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentParameter, setParameterSite] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("alerts");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [siteEnergy, setSiteEnergy] = useState([]);
  const [fieldValue, setFieldValue] = useState([]);
  const [logic, setLogic] = useState("");
  const [equipmentName, setEquipment] = useState("");
  const [editState, setEditState] = useState(false);
  const [disabledSiteName, setDisabledSiteName] = useState(false);
  const [disabledEquipment, setDisabledEquipment] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [equipmentParamter, setEquipmentParameter] = useState("");
  const [sitename, setSitename] = useState("");
  const [fromTime, setFromTime] = useState("");

  const [toTime, setToTime] = useState("");

  const earliestFromTime = dayjs().startOf("day").hour(5); // 5 AM
  const latestToTime = dayjs().startOf("day").hour(19); // 7 PM

  // Format earliestFromTime and latestToTime into HH:mm format
  const formattedEarliestFromTime = earliestFromTime.format("HH:mm");
  const formattedLatestToTime = latestToTime.format("HH:mm");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  //----------------User Table----------------//
  const fetchData = async () => {
    setloading(false);
    try {
      const data = await fetchAlertsTable();
      console.log(data, "data");
      const reverse = [...data].reverse();
      setTableValue(reverse);
      setloading(true);
    } catch (e) {
      setloading(true);
    }
  };
  /*----------------------Edit Call--------------------*/
  const handleEditClick = async (alertId) => {
    // let data = await FetchEditValue(alertId)
    console.log(alertId, "alertId");

    fetchEquipmentDetails(alertId.siteName);
    fetchParams(alertId.siteid);
    setEditValue(alertId);
    setEditState(true);
    setmodalOpen(true);
    setDisabledSiteName(true);
    setDisabledEquipment(true);
    setEditMode(true);
  };

  console.log(editValue, "editValue....");

  const handleChangeFromTime = (event) => {
    console.log(event, "event");
    const selectedFromTime = dayjs(event);
    console.log(selectedFromTime, "selectedFromTime");
    console.log(earliestFromTime, "earliestFromTime");
    if (selectedFromTime.isBefore(earliestFromTime)) {
      console.log(earliestFromTime, "early");
      setFromTime(earliestFromTime);
    } else {
      setFromTime(event);
      console.log(event, "elseevent");
    }
  };

  const handleChangeToTime = (event) => {
    console.log(event, "event");
    const selectedToTime = dayjs(event);
    console.log(selectedToTime, "selectedToTime");
    console.log(latestToTime, "earliestFromTime");
    if (selectedToTime.isAfter(latestToTime)) {
      console.log(latestToTime, "early");
      setToTime(latestToTime);
    } else {
      setToTime(event);
      console.log(event, "elseevent");
    }
  };

  console.log(earliestFromTime, latestToTime, "test");

  /*----------------------------- update call -------------------------------*/
  const [alertFields, setAlertFields] = useState({
    alertId: "",
    siteid: "",
    customerNaming: "",
    parameter: "",
    logic: "",
    value: "",
    emailId: "",
    errorMessage: "",
    fromTime: "",
    toTime: "",
    activeFlag: "",
    action: "",
    fromTime: "",
    toTime: "",
  });
  useEffect(() => {
    fetchData();
  }, []);
  const handleStatus = (data, event) => {
    console.log(event, "status handle claue");
    setactiveFlag(event.value);
  };
  //     let responsedata = await updateEditCall(obj, alertId);
  //     console.log(responsedata);
  //     if(responsedata.status ===200){
  //       fetchData();
  //     setOpen(false)
  //     }
  //   };
  const handleSubmit = async (equipmentName) => {
    console.log(equipmentName, "equipmentName");
    let equipemntIds = equipments?.filter((data) => {
      return data.customernaming === equipmentName;
    });
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });
    //
    let valueDta = Number(fieldValue?.value);
    console.log(fieldValue, "fieldVlaue");
    console.log(fromTime, toTime);
    const constFromTime = "";
    const constTotime = "";
    console.log(
      dayjs(earliestFromTime).format("HH:mm"),
      dayjs(latestToTime).format("HH:mm"),
      "emailid test"
    );
    console.log(
      dayjs(fromTime).format("HH:mm"),
      dayjs(toTime).format("HH:mm"),
      "apicall"
    );
    let data = {
      userId: sessionStorage.getItem("id"),
      equipmentId: equipemntIds[0].equipmentid,
      siteId: siteIdFilter[0].siteId,
      activeFlag: 1,
      // emailId: fieldValue?.emailId,
      emailId: email,
      errorMessage: fieldValue?.errorMessage,
      value: valueDta,
      parameter: equipmentParamter,
      logic: logic,
      // fromTime: dayjs(fromTime).format('HH:mm'),  // Use raw fromTime value
      // toTime: dayjs(toTime).format('HH:mm'),
      fromTime:
        fromTime !== ""
          ? dayjs(fromTime).format("HH:mm")
          : dayjs(earliestFromTime).format("HH:mm"),
      toTime:
        toTime !== ""
          ? dayjs(toTime).format("HH:mm")
          : dayjs(latestToTime).format("HH:mm"),
    };
    console.log(data, "data test");
    let responseData = await postAlertsSave(data);
    console.log(responseData, "response");
    // setUpdateIconState(false)
    setFieldValue([]);
    setLogic("");
    setactiveFlag();
    fetchData();
    setFromTime("");
    setToTime("");
    setmodalOpen(false);
  };

  // const handleUpdate = async () => {
  //   let customerNaming =
  //     equipmentName === "" ? editValue.customerNaming : equipmentName;
  //   console.log(customerNaming, "custname");
  //   let siteid = editValue.siteName;
  //   let siteIdFilter = siteIdName?.filter((data) => {
  //     return data.siteName === siteid;
  //   });
  //   let equipemntIds = equipments?.filter((data) => {
  //     return data.customernaming === customerNaming;
  //   });
  //   console.log(equipments, "eqpid");
  //   let paramtr =
  //     equipmentParamter !== "" ? equipmentParamter : editValue.parameter;
  //   let logc = logic !== "" ? logic : editValue.logic;
  //   let val =
  //     fieldValue.value !== undefined ? fieldValue.value : editValue.value;
  //   let email =
  //     fieldValue.emailId !== undefined ? fieldValue.emailId : editValue.emailId;
  //   let errormessage =
  //     fieldValue.errorMessage !== undefined
  //       ? fieldValue.errorMessage
  //       : editValue.errorMessage;
  //   let activeFlagID = activeFlag === "inactive" ? 0 : editValue.activeFlag;
  //   let fromtime =
  //     fieldValue.fromTime !== undefined ? fieldValue.fromTime : editValue.fromTime;
  //   console.log(fromtime, "fromtime");
  //   let totime = fieldValue.toTime !== undefined ? fieldValue.toTime : editValue.toTime;
  //   console.log(totime, "active flag");

  //   // let fromtime =
  //   //   fieldValue.fromTime !== undefined ? dayjs(fieldValue.fromTime).format('HH:mm') : editValue.fromTime;
  //   // console.log(fromtime, "fromtime");
  //   // let totime = fieldValue.toTime !== undefined ? dayjs(fieldValue.toTime).format('HH:mm') : editValue.toTime;
  //   // console.log(totime, "active flag");

  //   let valuedata = Number(val);
  //   console.log(siteIdFilter[0].siteId, "UpdateSiteId");
  //   let obj = {
  //     equipmentId: equipemntIds[0].equipmentid,
  //     siteId: siteIdFilter[0].siteId,
  //     parameter: paramtr,
  //     logic: logc,
  //     value: valuedata,
  //     emailId: email,
  //     errorMessage: errormessage,
  //     activeFlag: activeFlagID,
  //     fromTime: fromtime,
  //     toTime: totime
  //   };

  //   console.log(obj, "obj", editValue, "editValue");
  //   let res = await updateEditCall(obj, editValue.alertId);
  //   setFieldValue([]);
  //   setLogic("");
  //   setactiveFlag();
  //   fetchData();
  //   setmodalOpen(false);
  //   // setmodalOpen(false);
  //   // console.log(res, "dataaa");
  //   // if (res.data === "Updated") {
  //   // setOpen(false);
  //   // setmodalOpen(false);
  //   // fetchData();
  //   // }
  // };

  const handleUpdate = async () => {
    let customerNaming =
      equipmentName === "" ? editValue.customerNaming : equipmentName;
    console.log(customerNaming, "custname");
    let siteid = editValue.siteName;
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteid;
    });
    let equipemntIds = equipments?.filter((data) => {
      return data.customernaming === customerNaming;
    });
    console.log(equipments, "eqpid");
    let paramtr =
      equipmentParamter !== "" ? equipmentParamter : editValue.parameter;
    let logc = logic !== "" ? logic : editValue.logic;
    let val =
      fieldValue.value !== undefined ? fieldValue.value : editValue.value;
    let email =
      fieldValue.emailId !== undefined ? fieldValue.emailId : editValue.emailId;
    let errormessage =
      fieldValue.errorMessage !== undefined
        ? fieldValue.errorMessage
        : editValue.errorMessage;
    let activeFlagID = activeFlag === "inactive" ? 0 : editValue.activeFlag;

    // Format fromTime and toTime to "HH:mm" format
    let fromtime =
      fieldValue.fromTime !== undefined ? dayjs(fieldValue.fromTime).format('HH:mm') : editValue.fromTime;
    console.log(fromtime, "fromtime");
    let totime = fieldValue.toTime !== undefined ? dayjs(fieldValue.toTime).format('HH:mm') : editValue.toTime;
    console.log(totime, "active flag");

    // let fromtime =
    //   fieldValue.fromTime !== undefined ? fieldValue.fromTime : editValue.fromTime;
    // console.log(fromtime, "fromtime");
    // let totime = fieldValue.toTime !== undefined ? fieldValue.toTime : editValue.toTime;
    // console.log(totime, "active flag");
    console.log(dayjs(fromTime).format("HH:mm"), "fromTime", toTime);
    console.log(fieldValue, "editValue");

    let valuedata = Number(val);
    console.log(siteIdFilter[0].siteId, "UpdateSiteId");
    let obj = {
      equipmentId: equipemntIds[0].equipmentid,
      siteId: siteIdFilter[0].siteId,
      parameter: paramtr,
      logic: logc,
      value: valuedata,
      emailId: email,
      errorMessage: errormessage,
      activeFlag: activeFlagID,
      fromTime:
        fromTime !== ""
          ? dayjs(fromTime).format("HH:mm")
          : editValue.fromTime?.slice(0, 5),
      toTime:
        toTime !== ""
          ? dayjs(toTime).format("HH:mm")
          : editValue.toTime?.slice(0, 5),
    };

    console.log(obj, "obj", editValue, "editValue");
    let res = await updateEditCall(obj, editValue.alertId);
    setFieldValue([]);
    setLogic("");
    setactiveFlag();
    fetchData();
    setmodalOpen(false);
    setmodalOpen(false);
    console.log(res, "dataaa");
    if (res.data === "Updated") {
      setOpen(false);
      setmodalOpen(false);
      setFromTime("");
      setToTime("");
      fetchData();
    }
  };

  /*----------------------------Fields-------------------------------*/
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [clickCount, setClickCount] = useState(1);
  const [enabledFields, setEnabledFields] = useState(true);
  const maxFields = 20;
  const handleAddButtonClick = () => {
    if (clickCount < maxFields) {
      setClickCount(clickCount + 1);
      setEnabledFields(true);
    }
  };
  const handleRemoveButtonClick = () => {
    if (clickCount > 1) {
      setClickCount(clickCount - 1);
      setEnabledFields(false);
    }
  };
  /*----------------------------Search Input-------------------------------*/
  // const [searchInput, setSearchInput] = useState('');
  // const handleSearchInputChange = (e) => {
  //     const inputValue = e.target.value;
  //     setSearchInput(inputValue);
  //     const filteredData = rows.filter((row) =>
  //         Object.values(row).some((value) =>
  //             String(value).toLowerCase().includes(inputValue.toLowerCase())
  //         )
  //     );
  //     setTableValue(filteredData);
  // };
  const handleNavigate = () => {
    navigate("/menu/alerts");
  };
  const [isCreateAlertModalOpen, setCreateAlertModalOpen] = useState(false);
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const handleOpenCreateAlertModal = () => {
    setCreateAlertModalOpen(true);
  };
  const handleCloseCreateAlertModal = () => {
    setCreateAlertModalOpen(false);
  };
  const handleOpenSettingsModal = () => {
    setSettingsModalOpen(true);
  };
  const handleCloseSettingsModal = () => {
    setSettingsModalOpen(false);
  };
  /*--------------- Settings (Optional) Modal Components-----------------*/
  const [filteredSiteEnergy, setFilteredSiteEnergy] = useState(siteEnergy);
  const defaultLoadingStates = {
    selectalert: true,
    selectsite: true,
    equipments: true,
    parameter: true,
    logic: true,
    value: true,
    errormessage: true,
    fromTime: true,
    toTime: true,
    status: true,
    action: true,
  };
  const [selectalertLoading, setselectalertLoading] = useState(
    defaultLoadingStates.selectalert
  );
  const [selectsiteLoading, setselectsiteLoading] = useState(
    defaultLoadingStates.selectsite
  );
  const [equipmentsLoading, setequipmentsLoading] = useState(
    defaultLoadingStates.equipments
  );
  const [parameterLoading, setparameterLoading] = useState(
    defaultLoadingStates.parameter
  );
  const [logicLoading, setlogicLoading] = useState(defaultLoadingStates.logic);
  const [valueLoading, setvalueLoading] = useState(defaultLoadingStates.value);
  const [fromTimeLoading, setfromtimeLoading] = useState(
    defaultLoadingStates.fromTime
  );
  const [toTimeLoading, settotimeLoading] = useState(
    defaultLoadingStates.toTime
  );
  const [errormessageloading, seterrormessageloading] = useState(
    defaultLoadingStates.errormessage
  );
  const [statusLoading, setstatusLoading] = useState(
    defaultLoadingStates.status
  );
  const [actionLoading, setactionLoading] = useState(
    defaultLoadingStates.action
  );
  const handleToggle = () => {
    const filteredValues = siteEnergy?.filter((row) => {
      return (
        (selectalertLoading && row.selectalert) ||
        (selectsiteLoading && row.selectsite) ||
        (equipmentsLoading && row.equipments) ||
        (parameterLoading && row.parameter)(logicLoading && row.logic) ||
        (valueLoading && row.value)(errormessageloading && row.errormessage) ||
        (fromTimeLoading && row.fromTime)(fromTimeLoading && row.fromTime) ||
        (toTimeLoading && row.toTime)(toTimeLoading && row.toTime) ||
        (statusLoading && row.status)(actionLoading && row.action)
      );
    });
    setFilteredSiteEnergy(filteredValues);
  };
  /*------------------------- Mui IOS Switch --------------------------*/
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const buttonStyle = {
    textTransform: "capitalize",
    fontWeight: "bold",
    // backgroundColor: "#000440",
    color: "white",
    // backgroundColor:'#09001A'
  };
  const buttonStyles = {
    textTransform: "capitalize",
    boxShadow: "4px 4px 17px rgba(0, 0, 0.50, 0.50)",
    fontSize: "1.1rem",
    border: "none",
    width: "7vw",
  };
  /*-----------------------Email Validation-----------------*/
  function isEmailValid(email) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  // const handleEmailChange = (event) => {
  //   const email = event.target.value;
  //   if (!isEmailValid(email)) {
  //     console.log("Invalid email address");
  //   }
  // };
  // const handleEmailChangevalue = (e) => {
  //   setEmail(e.target.value);
  //   const email = e.target.value;
  //   setEmail(email);
  //   setValidEmail(isEmailValid(email));
  // };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setValidEmail(isValidEmail(newEmail));
  };
  const handleEmailChangevalue = (e) => {
    setEmail(e.target.value);
    const newEmail = e.target.value;
    setEmail(newEmail);
    setValidEmail(isValidEmail(newEmail));
  };
  /*-------------sorting--------------*/
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    sortData(tableValue, newOrder, property);
  };
  const sortData = (data, sortOrder, field) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];
      if (sortOrder === "asc") {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else if (sortOrder === "desc") {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
      return 0;
    });
    setTableValue(sortedData);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.alerts);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleSetValue = (rowid) => {
    navigate("/menu/viewalert", { state: { id: rowid } });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, (siteEnergy?.length || 0) - page * rowsPerPage);
  function createData(
    alerts,
    alerttype,
    errorcode,
    selectsite,
    equipment,
    logic,
    value,
    fromTime,
    toTime,
    errormessage,
    description,
    parameter,
    status,
    action,
    siteName,
    customerNaming,
    smsalert
  ) {
    return {
      alerts,
      alerttype,
      errorcode,
      selectsite,
      equipment,
      logic,
      value,
      fromTime,
      toTime,
      errormessage,
      description,
      status,
      action,
      parameter,
      siteName,
      customerNaming,
      smsalert,
    };
  }
  const rows = [
    createData(
      "Meter Track",
      "Meter Track",
      10,
      1,
      "Active",
      "KS Site",
      "eira@gmail.com",
      "MRV-I",
      "Device Reset",
      "Active"
    ),
    createData(
      "Grid Failure",
      "System Disconnect",
      10,
      1,
      "In Active",
      "KB Site",
      "eira@gmail.com",
      "MRV-II",
      "System Disconnect",
      "Active"
    ),
    createData(
      "Energy Loss",
      "Zero Generation",
      10,
      1,
      "Active",
      "KC Site",
      "eira@gmail.com",
      "MRV-III",
      "Zero Generation",
      "Active"
    ),
    createData(
      "Meter Track",
      "Meter Track",
      10,
      1,
      "Active",
      "KS Site",
      "eira@gmail.com",
      "MRV-III",
      "System Disconnect",
      "In Active"
    ),
    createData(
      "Grid Failure",
      "System Disconnect",
      10,
      1,
      "In Active",
      "KS Site",
      "eira@gmail.com",
      "MRV-I",
      "System Disconnect",
      "Active"
    ),
  ];
  useEffect(() => {
    getSiteCall();
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  /*----------------------------- Paramater Get call ------------------------------*/
  const fetchParams = async (id) => {
    try {
      let data = await fetchParameterListDetails(id);
      setParameterSite(data);
    } catch (e) {
      console.error(e);
    }
  };
  /*----------------------------- Site Name API Call-----------------------*/
  const prevValRef = useRef("first");
  const handleSite = (data, val) => {
    console.log(val, "val");
    console.log(prevValRef.current, "prev val");
    if (val === prevValRef.current || prevValRef.current === "first") {
      setSite(val);
      fetchEquipmentDetails(val);
      setSitename(data);
      setEquipment("");
      setLogic("");
      // setFieldValue({...fieldValue, values: ""})
      // setFieldValue({ values: "" });
      setFieldValue((prevFieldValues) => {
        // Only clear the 'values' field and keep other values intact
        return { ...prevFieldValues, values: "" };
      });
      console.log("val equal");
    } else {
      handlemodalClose();
      setTimeout(() => {
        handlemodalOpen();
        prevValRef.current = "first";
      });
      console.log("val not equal");
    }
    prevValRef.current = val;
  };

  console.log(equipmentName, logic);
  console.log(editValue, "editValue");
  const handleEquipment = (data, value) => {
    if (value == null) {
      setEquipment("");
    } else {
      setEquipment(value);
      // setEquipment(data)
    }
  };

  const fetchEquipmentDetails = async (event) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === event;
    });
    try {
      let data = await fetchEquipmentIdDetails(siteIdFilter[0]?.siteId);
      setEquipments(data);
      fetchParams(siteIdFilter[0]?.siteId);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getSiteCall();
  }, []);

  /*-------------------------- Site Get ---------------------*/
  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDetails(id);
      if (data.length > 0) {
        setSiteId([...new Set(data)]);
        setSite(data[0]?.siteName);
      }
    } catch (e) {
      console.error(e);
    }
  };
  /*-------------------Modal Component--------------------*/
  const stylemodal = {
    margin: "20px 0px 0px 0px",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    // left: '52%',
    // width: "78%",
    // height: "55vh",
    // width: "90%",
    width: "90%",
    left: "47%",
    height: "69vh",
    bgcolor: "background.paper",
    p: 4,
    marginLeft: "5%",
  };
  const [modalopen, setmodalOpen] = React.useState(false);
  console.log(modalopen, "modalopen");
  const handlemodalOpen = () => {
    setmodalOpen(true);
    setEditState(false);
    // setUpdateIconState(false)
    setEditValue();
    setEditMode(false);
  };
  const handlemodalClose = () => {
    setmodalOpen(false);
  };

  const handleChange = (e) => {
    setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
  };
  const handleLogic = (data, event) => {
    setLogic(event.value);
  };

  /*---------------------Search Option ----------------*/
  const [searchInput, setSearchInput] = useState("");
  const tableValues = tableValue.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchInput.toLowerCase())
    )
  );
  const handleEquipmentParameter = (data, value) => {
    console.log(data, value);
    if (value === null) {
      setEquipmentParameter("");
    } else setEquipmentParameter(value);
  };

  return (
    <div className="">
      <div>
        <Box
          sx={{
            width: "100%",
            height: 50,
          }}
        >
          <Paper elevation={0} style={{ height: "100%", position: "relative" }}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "5px 0 0 13px",
                }}
              >
                <Typography>
                  <Link
                    to="/menu"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <KeyboardBackspaceIcon
                      style={{
                        verticalAlign: "middle",
                        fontSize: "1.7rem",
                        cursor: "pointer",
                      }}
                    />
                    Add Alerts
                  </Link>{" "}
                </Typography>
                <TextField
                  id="standard-basic"
                  placeholder="Search Site Name here..."
                  variant="outlined"
                  size="small"
                  // sx={{ width: "100%" }}
                  sx={{ flex: 1, width: "350px", marginLeft: "15%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Button style={{ color: "#000440" }}>
                          <SearchOutlinedIcon />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
              <div
                style={{ position: "absolute", bottom: "7px", right: "100px" }}
              >
                <Stack spacing={3} direction="row">
                  <Button
                    variant="contained"
                    style={buttonStyle}
                    onClick={handlemodalOpen}
                  >
                    Create New Alert
                  </Button>
                </Stack>
              </div>
              <div
                style={{ position: "absolute", bottom: "8px", right: "25px" }}
              >
                <Button
                  onClick={handleOpenSettingsModal}
                  style={{ color: "#000440" }}
                >
                  {" "}
                  <SettingsOutlinedIcon />
                </Button>
              </div>
            </div>
          </Paper>
        </Box>
        <div>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                mr: 0,
                mb: 2,
                mt: 2,
                width: "100%",
                minHeight: "7vh",
                maxHeight: "max-content",
                m: 0,
                padding: "0",
              },
            }}
          ></Box>
          <div>
            <Box
              sx={{
                width: "100%",
                minHeight: "65vh",
                maxHeight: "max-content",
              }}
            >
              <Paper
                sx={{ width: "100%", mb: 2, marginTop: "0.5%" }}
                elevation={0}
              >
                <TableContainer style={{ height: "65vh" }}>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        {/* {selectalertLoading && <TableCell align="center">  Alert Id</TableCell>} */}
                        {selectsiteLoading && (
                          <TableCell align="center">
                            <TableSortLabel
                              // active={orderBy === 'siteName' ? order : 'desc'}
                              direction={orderBy === "siteName" ? order : "asc"}
                              onClick={() => handleRequestSort("siteName")}
                            >
                              Site Name
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {equipmentsLoading && (
                          <TableCell align="center">
                            <TableSortLabel
                              // active={orderBy === 'siteName' ? order : 'desc'}
                              direction={
                                orderBy === "customerNaming" ? order : "asc"
                              }
                              onClick={() =>
                                handleRequestSort("customerNaming")
                              }
                            >
                              Equipments
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {parameterLoading && (
                          <TableCell align="center">
                            <TableSortLabel
                              // active={orderBy === 'siteName' ? order : 'desc'}
                              direction={
                                orderBy === "parameter" ? order : "asc"
                              }
                              onClick={() => handleRequestSort("parameter")}
                            >
                              Parameter
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {logicLoading && (
                          <TableCell align="center">
                            <TableSortLabel
                              // active={orderBy === 'siteName' ? order : 'desc'}
                              direction={orderBy === "logic" ? order : "asc"}
                              onClick={() => handleRequestSort("logic")}
                            >
                              Logic
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {valueLoading && (
                          <TableCell align="center">
                            <TableSortLabel
                              // active={orderBy === 'siteName' ? order : 'desc'}
                              direction={orderBy === "value" ? order : "asc"}
                              onClick={() => handleRequestSort("value")}
                            >
                              Value
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {valueLoading && (
                          <TableCell align="center">
                            <TableSortLabel
                              // active={orderBy === 'siteName' ? order : 'desc'}
                              direction={orderBy === "email" ? order : "asc"}
                              onClick={() => handleRequestSort("email")}
                            >
                              Email
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {errormessageloading && (
                          <TableCell align="center">
                            <TableSortLabel
                              direction={
                                orderBy === "errorMessage" ? order : "asc"
                              }
                              onClick={() => handleRequestSort("errorMessage")}
                            >
                              Error Message
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {fromTimeLoading && (
                          <TableCell align="center">
                            <TableSortLabel
                              direction={orderBy === "fromTime" ? order : "asc"}
                              onClick={() => handleRequestSort("fromTime")}
                            >
                              From Time
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {toTimeLoading && (
                          <TableCell align="center">
                            <TableSortLabel
                              direction={orderBy === "toTime" ? order : "asc"}
                              onClick={() => handleRequestSort("toTime")}
                            >
                              To Time
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {/* {statusLoading && (
                          <TableCell align="center">
                            <TableSortLabel
                              // active={orderBy === 'siteName' ? order : 'desc'}
                              direction={
                                orderBy === "activeFlag" ? order : "asc"
                              }
                              onClick={() => handleRequestSort("activeFlag")}
                            >
                              Status
                            </TableSortLabel>
                          </TableCell>
                        )} */}
                        {actionLoading && (
                          <TableCell align="center">
                            <TableSortLabel
                              // active={orderBy === 'siteName' ? order : 'desc'}
                              direction={orderBy === "action" ? order : "asc"}
                              onClick={() => handleRequestSort("action")}
                            >
                              Action
                            </TableSortLabel>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableValues
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow
                            key={row.SiteId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* {selectalertLoading && (<TableCell align="center">{row.alertId}</TableCell>)} */}
                            {selectsiteLoading && (
                              <TableCell align="left">{row.siteName}</TableCell>
                            )}
                            {equipmentsLoading && (
                              <TableCell align="left">
                                {row.customerNaming}
                              </TableCell>
                            )}
                            {parameterLoading && (
                              <TableCell align="left">
                                {row.parameter}
                              </TableCell>
                            )}
                            {logicLoading && (
                              <TableCell align="center">{row.logic}</TableCell>
                            )}
                            {valueLoading && (
                              <TableCell align="center">{row.value}</TableCell>
                            )}
                            {valueLoading && (
                              <TableCell align="left">{row.emailId}</TableCell>
                            )}
                            {errormessageloading && (
                              <TableCell align="left">
                                {row.errorMessage}
                              </TableCell>
                            )}
                            {fromTimeLoading && (
                              <TableCell align="left">{row.fromTime}</TableCell>
                            )}
                            {toTimeLoading && (
                              <TableCell align="left">{row.toTime}</TableCell>
                            )}
                            {/* {statusLoading && (
                              <TableCell align="center">
                                {row.activeFlag === 1
                                  ? "Active"
                                  : row.activeFlag === 0
                                    ? "Inactive"
                                    : null}
                              </TableCell>
                            )} */}
                            {actionLoading && (
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                              >
                                <EditIcon
                                  onClick={() => handleEditClick(row)}
                                />
                                {/* <DeleteIcon /> */}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  // count={siteEnergy?.length || 0}
                  count={tableValue?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </div>
          <div>
            <Modal
              open={modalopen}
              onClose={handlemodalClose}
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              sx={{ marginTop: "1.5%" }}
            >
              <div
                className="monitoring-points"
                style={{ marginLeft: "2%", marginTop: "1%" }}
              >
                <Box sx={stylemodal}>
                  <Paper elevation={0}>
                    <Grid item xs={6} md={2}>
                      <div className="analytics">
                        <div style={{ margin: "0 0 0 15px" }}>
                          <Typography
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            {/* <KeyboardBackspaceIcon style={{ verticalAlign: 'middle', fontSize: '1.7rem', marginRight: '8px' }} onClick={handleCancel} /> */}
                            Customize New Alert
                          </Typography>
                        </div>
                        <div style={{ marginRight: "0.5%" }}>
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="outlined"
                              style={buttonStyles}
                              onClick={handlemodalClose}
                            >
                              Cancel
                            </Button>
                            {editValue !== undefined ? (
                              <Button
                                variant="contained"
                                style={buttonStyles}
                                onClick={handleUpdate}
                              >
                                Update
                              </Button>
                            ) : (
                              // <Button variant="contained" style={buttonStyles} onClick={handleSubmit} >Save</Button>
                              <Button
                                variant="contained"
                                style={buttonStyles}
                                onClick={() => handleSubmit(equipmentName)}
                              >
                                Save
                              </Button>
                            )}
                          </Stack>
                        </div>
                      </div>
                      <div style={{ margin: "40px 0 0 15px" }}>
                        <Grid container item xs={3}>
                          <Grid item xs={12} md={6}>
                            <div style={{ minWidth: 200 }}>
                              <Typography style={typoStyle}>
                                {" "}
                                Site Name{" "}
                              </Typography>
                              <Autocomplete
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                defaultValue={
                                  editMode
                                    ? editValue
                                      ? editValue.siteName
                                      : null
                                    : null
                                }
                                disabled={editMode ? true : false}
                                options={siteIdName.map(
                                  (option) => option.siteName
                                )}
                                onChange={(data, event) =>
                                  handleSite(data, event)
                                }
                                sx={{ width: "10vw" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select Site ..."
                                    disabled={disabledSiteName}
                                  />
                                )}
                                classes={{ option: "autocomplete" }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div style={{ minWidth: 200 }}>
                              <Typography style={typoStyle}>Email</Typography>
                              {/* {editValue ? (
                                <TextField
                                  disablePortal
                                  id="combo-box-demo"
                                  size="small"
                                  name="emailId"
                                  defaultValue={
                                    editValue ? editValue.emailId : null
                                  }
                                  options={siteIdName.map(
                                    (option) => option.siteName
                                  )}
                                  // onChange={(event) => handleChange(event)}
                                  onChange={(event) => {
                                    handleEmailChange(event);
                                    handleChange(event);
                                  }}
                                  sx={{ width: "10vw" }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select  Site ..."
                                    />
                                  )}
                                  classes={{ option: "autocomplete" }}
                                />
                              ) : (
                                <TextField
                                  disablePortal
                                  id="combo-box-demo"
                                  size="small"
                                  name="emailId"
                                  options={siteIdName.map(
                                    (option) => option.siteName
                                  )}
                                  value={email}
                                  onChange={handleEmailChangevalue}
                                  // onChange={(event) => {
                                  //   handleEmailChange(event);
                                  //   handleChange(event);
                                  // }}
                                  sx={{ width: "10vw" }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select  Site ..."
                                    />
                                  )}
                                  classes={{ option: "autocomplete" }}
                                />
                              )} */}
                              {editValue ? (
                                <TextField
                                  id="outlined-basic"
                                  size="small"
                                  placeholder="Email"
                                  variant="outlined"
                                  error={!validEmail}
                                  helperText={
                                    !validEmail ? "Invalid email address" : ""
                                  }
                                  defaultValue={
                                    editValue ? editValue.emailId : null
                                  }
                                  onChange={handleEmailChange}
                                />
                              ) : (
                                <TextField
                                  id="outlined-basic"
                                  size="small"
                                  placeholder="Email"
                                  variant="outlined"
                                  error={!validEmail}
                                  helperText={
                                    !validEmail ? "Invalid email address" : ""
                                  }
                                  value={email}
                                  onChange={handleEmailChangevalue}
                                />
                              )}
                            </div>
                          </Grid>

                          {/* <div style={{ flex: 1, minWidth: 150 }}>
                            <Typography style={typoStyle}>
                              Email
                            </Typography>
                            <TextField
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              name="emailId"
                              defaultValue={
                                editValue ? editValue.emailId : null
                              }
                              options={siteIdName.map(
                                (option) => option.siteName
                              )}
                              // onChange={(event) => handleChange(event)}
                              onChange={(event) => {
                                handleEmailChange(event);
                                handleChange(event);
                              }}
                              sx={{ width: "10vw" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select  Site ..."
                                />
                              )}
                              classes={{ option: "autocomplete" }}
                            />
                          </div> */}
                        </Grid>
                        <div style={{ margin: "30px 0 0 0" }}>
                          <Stack spacing={0} direction="row"></Stack>
                        </div>
                      </div>

                      <Card
                        sx={{
                          width: "100%",
                          height: "25vh",
                          overflowY: "scroll",
                          border: "none",
                          boxShadow: "none",
                          // margin: "0 0 0 0",
                        }}
                      >
                        <CardContent sx={{ width: "100%", height: "5%" }}>
                          {[...Array(clickCount)].map((_, index) => (
                            <div style={{ margin: "10px 0 0 0" }}>
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Grid container spacing={2} columns={27}>
                                  <Grid
                                    item
                                    xs={3.5}
                                    style={{ marginTop: "8.5px" }}
                                  >
                                    <div style={{ flex: 1, minWidth: 150 }}>
                                      <Typography style={typoStyle}>
                                        Equipment
                                      </Typography>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size="small"
                                        //   value={editMode ? editValue ? editValue.customerNaming : null : null}
                                        defaultValue={
                                          editMode
                                            ? editValue
                                              ? editValue.customerNaming
                                              : null
                                            : null
                                        }
                                        disabled={editMode ? true : false}
                                        options={equipments.map(
                                          (option) => option.customernaming
                                        )}
                                        onChange={(data, event) =>
                                          handleEquipment(data, event)
                                        }
                                        sx={{ width: "10vw" }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Select Equipment..."
                                            disabled={disabledEquipment}
                                          />
                                        )}
                                        classes={{ option: "autocomplete" }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3.5}
                                    style={{ marginTop: "8.5px" }}
                                  >
                                    <div style={{ flex: 1, minWidth: 150 }}>
                                      <Typography style={typoStyle}>
                                        Parameter
                                      </Typography>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size="small"
                                        defaultValue={
                                          editValue ? editValue.parameter : null
                                        }
                                        options={equipmentParameter}
                                        onChange={(data, event) =>
                                          handleEquipmentParameter(data, event)
                                        }
                                        // sx={{ width: "86%" }}
                                        sx={{ width: "10vw" }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Select Equipment Parameter..."
                                          />
                                        )}
                                        classes={{ option: "autocomplete" }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3.5}
                                    style={{ marginTop: "8.5px" }}
                                  >
                                    <div style={{ flex: 1, minWidth: 150 }}>
                                      <FormControl sx={{ width: "86%" }}>
                                        <Typography style={typoStyle}>
                                          Logic
                                        </Typography>
                                        <Autocomplete
                                          disablePortal
                                          id="combo-box-demo"
                                          size="small"
                                          defaultValue={
                                            editValue ? editValue.logic : ""
                                          }
                                          onChange={(data, value) => {
                                            handleLogic(data, value);
                                          }}
                                          options={Logic}
                                          // sx={{ width: 300 }}
                                          sx={{ width: "10vw" }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              // label="Trigger"
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3.5}
                                    style={{ marginTop: "8.5px" }}
                                  >
                                    <div style={{ flex: 1, minWidth: 150 }}>
                                      <Typography style={typoStyle}>
                                        Value
                                      </Typography>
                                      <TextField
                                        disablePortal
                                        id="combo-box-demo"
                                        size="small"
                                        name="value"
                                        defaultValue={
                                          editValue
                                            ? editValue.value
                                            : fieldValue.values
                                        }
                                        options={siteIdName.map(
                                          (option) => option.siteName
                                        )}
                                        onChange={(event) => {
                                          handleChange(event);
                                          handleInput(event);
                                        }}
                                        // sx={{ width: "86%" }}
                                        sx={{ width: "10vw" }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Select  Site ..."
                                          />
                                        )}
                                        classes={{ option: "autocomplete" }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3.5}
                                    style={{ marginTop: "8.5px" }}
                                  >
                                    <div style={{ flex: 1, minWidth: 150 }}>
                                      <Typography style={typoStyle}>
                                        Error Message *
                                      </Typography>
                                      <TextField
                                        disablePortal
                                        id="combo-box-demo"
                                        size="small"
                                        name="errorMessage"
                                        defaultValue={
                                          editValue
                                            ? editValue.errorMessage
                                            : null
                                        }
                                        options={siteIdName.map(
                                          (option) => option.siteName
                                        )}
                                        onChange={(event) =>
                                          handleChange(event)
                                        }
                                        // sx={{ width: "86%" }}
                                        sx={{ width: "10vw" }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Select  Site ..."
                                          />
                                        )}
                                        classes={{ option: "autocomplete" }}
                                      />
                                    </div>
                                  </Grid>
                                  {/* <Grid container item xs={3.3}>
                                    <Grid item xs={12} md={6}>
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['TimePicker']}>
                                          <div style={{ overflow: 'hidden' }}>
                                            <Typography style={typoStyle}>
                                              From Time
                                            </Typography>
                                            <TimePicker
                                              ampm={false}
                                              defaultValue={editValue ? dayjs(editValue.fromTime, "HH:mm:ss") : null}
                                              onChange={(event) => {
                                                setFromTime(event);
                                              }}
                                              slotProps={{
                                                textField: {
                                                  size: 'small',
                                                  InputProps: {
                                                    style: {
                                                      overflow: "hidden",
                                                      width: '10vw',
                                                    },
                                                  },
                                                },
                                              }}
                                            />
                                          </div>
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['TimePicker']}>
                                          <div style={{ overflow: 'hidden', }}>
                                            <Typography style={typoStyle}>
                                              To Time
                                            </Typography>
                                            <TimePicker
                                              ampm={false}
                                              defaultValue={editValue ? dayjs(editValue.toTime, "HH:mm:ss") : null}
                                              onChange={(event) => setToTime(event)}
                                              slotProps={{
                                                textField: {
                                                  size: 'small',
                                                  InputProps: {
                                                    style: {
                                                      width: '10vw', // Adjusted width for responsiveness
                                                    },
                                                  },
                                                },
                                              }}
                                            />
                                          </div>
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    </Grid>
                                  </Grid> */}

                                  <Grid container item xs={3.3}>
                                    <Grid item xs={12} md={6}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={["TimePicker"]}
                                        >
                                          <div style={{ overflow: "hidden" }}>
                                            <Typography style={typoStyle}>
                                              From Time
                                            </Typography>
                                            <TimePicker
                                              ampm={false}
                                              defaultValue={
                                                editValue
                                                  ? editValue.fromTime !== null
                                                    ? dayjs(
                                                        editValue.fromTime,
                                                        "HH:mm:ss"
                                                      )
                                                    : earliestFromTime
                                                  : earliestFromTime
                                              }
                                             
                                              onChange={(event) => {
                                                handleChangeFromTime(event);
                                              }}
                                              minTime={dayjs()
                                                .startOf("day")
                                                .hour(5)}
                                              // maxTime={latestToTime}
                                              maxTime={dayjs()
                                                .startOf("day")
                                                .hour(19)}
                              
                                              slotProps={{
                                                textField: {
                                                  size: "small",
                                                  InputProps: {
                                                    style: {
                                                      overflow: "hidden",
                                                      width: "10vw",
                                                    },
                                                  },
                                                },
                                              }}
                                            />
                                          </div>
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={["TimePicker"]}
                                        >
                                          <div style={{ overflow: "hidden" }}>
                                            <Typography style={typoStyle}>
                                              To Time
                                            </Typography>
                                            <TimePicker
                                              ampm={false}
                                              defaultValue={
                                                editValue 
                                                ? (editValue.toTime !== null 
                                                   ? dayjs(editValue.toTime, "HH:mm:ss") 
                                                   : latestToTime) 
                                                : latestToTime
                                              }                                              
                                              
                                              onChange={(event) => {
                                                handleChangeToTime(event);
                                              }}
                                              minTime={fromTime}
                                              maxTime={latestToTime}
                                             
                                              slotProps={{
                                                textField: {
                                                  size: "small",
                                                  InputProps: {
                                                    style: {
                                                      width: "10vw", // Adjusted width for responsiveness
                                                    },
                                                  },
                                                },
                                              }}
                                            />
                                          </div>
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    </Grid>
                                  </Grid>

                                  {editState === true ? (
                                    <Grid item xs={8}>
                                      <div style={{ flex: 1, minWidth: 150 }}>
                                        <FormControl sx={{ width: "78%" }}>
                                          <Typography style={typoStyle}>
                                            Status
                                          </Typography>
                                          <Autocomplete
                                            // sx={{ width: "105%" }}
                                            sx={{ width: "10vw" }}
                                            size="small"
                                            disablePortal
                                            id="user-status-combo-box"
                                            //  defaultValue={editValue ? editValue.activeFlag : null}
                                            defaultValue={
                                              editValue &&
                                              editValue.activeFlag === 1
                                                ? "Active"
                                                : editValue &&
                                                  editValue.activeFlag === 0
                                                ? "Inactive"
                                                : null
                                            }
                                            options={Status}
                                            onChange={(data, event) =>
                                              handleStatus(data, event)
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                name="activeFlag"
                                              />
                                            )}
                                          />
                                        </FormControl>
                                      </div>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                                {editState === false ? (
                                  // <div style={{marginRight: "40px"}}>
                                  <div>
                                    <Typography
                                      style={{
                                        color: "#000440",
                                        margin: "20px 0 0 33px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {index === 0 ? (
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <AddIcon
                                              onClick={handleAddButtonClick}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <CloseIcon
                                          onClick={() =>
                                            handleRemoveButtonClick(index)
                                          }
                                        />
                                      )}
                                    </Typography>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ))}
                        </CardContent>
                      </Card>
                      <div style={{ margin: "35px 0 0 0" }}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ margin: "35px 0 0 0" }}
                        >
                          <Stack
                            sx={{
                              width: "100%", // Adjust width to fit the container
                              margin: "10px 0 0 0",
                              color: "DodgerBlue",
                              contain: "variant",
                            }}
                            spacing={2}
                          >
                            <Typography
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              User configured Error Messages will be sent to the
                              email address*
                            </Typography>
                          </Stack>
                        </Grid>
                      </div>
                    </Grid>
                  </Paper>
                </Box>
              </div>
            </Modal>
          </div>
          <div>
            <Modal
              open={isSettingsModalOpen}
              onClose={handleCloseSettingsModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Paper
                elevation={0}
                className="total-custom"
                sx={{
                  width: "18%",
                  height: "max-content",
                  overflow: "hidden",
                  backgroundColor: "aliceblue",
                  padding: "10px",
                  marginLeft: "81%",
                  marginTop: "9.5%",
                }}
              >
                <div>
                  <Grid container spacing={10}>
                    <Grid item xs={6} md={14}>
                      <Typography
                        style={{
                          color: "rgb(18, 127, 216)",
                          marginLeft: "70%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={handleCloseSettingsModal}
                      >
                        <CloseOutlinedIcon />
                      </Typography>
                      {selectalertLoading ? (
                        <FormControlLabel
                          sx={{
                            display: "block",
                          }}
                          control={
                            <Switch
                              checked={selectalertLoading}
                              onChange={() =>
                                setselectalertLoading(!selectalertLoading)
                              }
                              color="primary"
                              disabled
                            />
                          }
                          label=" Site Name"
                        />
                      ) : (
                        <FormControlLabel
                          sx={{
                            display: "block",
                          }}
                          control={<Switch color="primary" />}
                          label="Site Name"
                        />
                      )}
                      <FormControlLabel
                        sx={{
                          display: "block",
                        }}
                        control={
                          <Switch
                            checked={equipmentsLoading}
                            onChange={() =>
                              setequipmentsLoading(!equipmentsLoading)
                            }
                            color="primary"
                          />
                        }
                        label="Equipments"
                      />
                      <FormControlLabel
                        sx={{
                          display: "block",
                        }}
                        control={
                          <Switch
                            checked={parameterLoading}
                            onChange={() =>
                              setparameterLoading(!parameterLoading)
                            }
                            color="primary"
                          />
                        }
                        label="Parameter"
                      />
                      <FormControlLabel
                        sx={{
                          display: "block",
                        }}
                        control={
                          <Switch
                            checked={logicLoading}
                            onChange={() => setlogicLoading(!logicLoading)}
                            color="primary"
                          />
                        }
                        label="Logic "
                      />
                      <FormControlLabel
                        sx={{
                          display: "block",
                        }}
                        control={
                          <Switch
                            checked={valueLoading}
                            onChange={() => setvalueLoading(!valueLoading)}
                            color="primary"
                          />
                        }
                        label=" Value"
                      />
                      <FormControlLabel
                        sx={{
                          display: "block",
                        }}
                        control={
                          <Switch
                            checked={errormessageloading}
                            onChange={() =>
                              seterrormessageloading(!errormessageloading)
                            }
                            color="primary"
                          />
                        }
                        label="ErrorMessage "
                      />
                      <FormControlLabel
                        sx={{
                          display: "block",
                        }}
                        control={
                          <Switch
                            checked={fromTimeLoading}
                            onChange={() =>
                              setfromtimeLoading(!fromTimeLoading)
                            }
                            color="primary"
                          />
                        }
                        label=" From Time"
                      />
                      <FormControlLabel
                        sx={{
                          display: "block",
                        }}
                        control={
                          <Switch
                            checked={toTimeLoading}
                            onChange={() => settotimeLoading(!toTimeLoading)}
                            color="primary"
                          />
                        }
                        label=" To Time"
                      />
                      <FormControlLabel
                        sx={{
                          display: "block",
                        }}
                        control={
                          <Switch
                            checked={statusLoading}
                            onChange={() => setstatusLoading(!statusLoading)}
                            color="primary"
                          />
                        }
                        label=" status "
                      />
                      <FormControlLabel
                        sx={{
                          display: "block",
                        }}
                        control={
                          <Switch
                            checked={actionLoading}
                            onChange={() => setactionLoading(!actionLoading)}
                            color="primary"
                          />
                        }
                        label="Action"
                      />
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomizeAlerts;
