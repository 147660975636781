import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useIdleTimer } from 'react-idle-timer';
/*----------------------- Login Components ------------------------*/
import LoginPage from './UiComponents/Login/LoginPage';
import ForgotPass from './UiComponents/Login/ForgotPass';
import ResetPass from './UiComponents/Login/ResetPass';
import ChangePass from './UiComponents/Login/ChangePass';
/*------------------------Sidebar ---------------------------*/
import MenuBar from './UiComponents/SideMenu/MenuBar';
/*-------------------------- Components --------------------*/
import Analytics from './UiComponents/Components/AnalyticsDashboard/Analytics';
import AddAnalyst from './UiComponents/Components/AnalyticsDashboard/AddAnalyst';
import AddAnalystDemo from './UiComponents/Components/AnalyticsDashboard/ViewAnalyst';
import Site from './UiComponents/SiteList/Site';
import Dashboard from './UiComponents/Dashboard/Dashboard';
import SiteOverView from './UiComponents/SiteList/SiteOverView';
import Equipment from './UiComponents/SiteList/Equipment';
import NewAlert from './UiComponents/Components/AlertsComponents/NewAlert';
import ViewAlert from './UiComponents/Components/AlertsComponents/ViewAlert';
import Alerts from './UiComponents/Components/AlertsComponents/Alerts';
import CustomizeAlerts from './UiComponents/Components/AlertsComponents/CustomizeAlerts';
import ExceluploadClone from './UiComponents/Components/ExcelUploadClone';
import Signup from './UiComponents/Signup/Signup';
import ExcelUplaodUi from './UiComponents/Components/ExcelUploadUi';
import Report from './UiComponents/Report/Report';
import Alarms from './UiComponents/Alarms/Alarms';

function App() {
  const [showPrompt, setShowPrompt] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleClose = (event, reason) => {
    setShowPrompt(false);
  };

  const prompt = {
    showPrompt,
    handleClose,
  };

  const token = sessionStorage.getItem('jwtToken');

  if (isLoggedIn) {
    setTimeout(() => {
      handleLogout();
    }, 3600000);
  }

  const handleLogout = () => {
    setIsLoggedIn(false);
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('jwtToken');
    sessionStorage.removeItem('email');
    // window.location.href = '/';
    window.location.href = '/webdyn/';
    // console.log("logout");
  };

  const showIdlePrompt = () => {
    setShowPrompt(true);
  };

  const { reset, start, getRemainingTime } = useIdleTimer({
    timeout: 1800000,
    promptBeforeIdle: 10000,
    onIdle: handleLogout,
    onAction: (event) => {
      reset();
      setShowPrompt(false);
      // console.log(event.type, "reseted");
    },
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      // "visibilitychange",
      'focus',
    ],
    immediateEvents: [],
    onPrompt: showIdlePrompt,
    startManually: true,
    debounce: 200,
  });

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsLoggedIn(true);
      console.log('sstarted');
      reset();
      start();
    } else {
      axios.defaults.headers.common['Authorization'] = `Bearer`;
    }

    // const countdownTimeout = setInterval(() => {
    //   const remainingTime = getRemainingTime();
    //   const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    //   const minutes = Math.floor(
    //     (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
    //   );
    //   const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    //   console.log(
    //     `Time remaining: ${hours} hours, ${minutes} minutes, ${seconds} seconds`
    //   );
    // }, 5000);

    // return () => {
    //   clearInterval(countdownTimeout);
    // };
  }, [token, start, reset, getRemainingTime]);

  return (
    <div>
      <Router
      // basename="/webdyn/"
      // basename="/sauryouth/"
      >
        <Routes>
          <Route path="signup" element={<Signup />} />
          <Route path="/" element={<LoginPage />} />
          <Route path="forgot" element={<ForgotPass />} />
          <Route path="resetpass" element={<ResetPass />} />
          <Route path="changepass" element={<ChangePass />} />
          {sessionStorage.getItem('token') && (
            <Route path="/menu" element={<MenuBar showPrompt={prompt} />}>
              <Route path="" element={<Dashboard />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="add_analyst" element={<AddAnalyst />} />
              <Route path="view-analyst" element={<AddAnalystDemo />} />
              <Route path="sitelist" element={<Site />} />
              <Route path="SiteOverView" element={<SiteOverView />} />
              <Route path="equipment" element={<Equipment />} />
              {/* <Route path='alerts' element={<Alerts />} /> */}
              <Route path="customize-alerts" element={<CustomizeAlerts />} />
              <Route path="viewalert" element={<ViewAlert />} />
              <Route path="newalert" element={<NewAlert />} />
              <Route path="report" element={<Report />} />
              <Route path="alarms" element={<Alarms />} />
            </Route>
          )}
        </Routes>
      </Router>
    </div>
  );
}
export default App;
