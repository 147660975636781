
import React, { useMemo } from "react";
import { Paper, Grid, useMediaQuery, useTheme, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import { FaChartLine } from 'react-icons/fa';
import { HiOutlineBellAlert} from "react-icons/hi2";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
// HighchartsExporting(Highcharts);
// HighchartsExportData(Highcharts);

const Barchart = (props) => {
  const { EventStatistcs } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const chartData = useMemo(() => {
    if (!EventStatistcs || EventStatistcs.length === 0) {
      return [];
    }
    const redColor = "#FF1913";
    return EventStatistcs.map((item) => ({
      name: item.siteName,
      data: [Number(item.alarmCount)],
      color: redColor,
    }));
  }, [EventStatistcs]);
  const columnChartOptions = {
    chart: {
      type: "column",
      height: "70%",
      marginRight: 20
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      categories: chartData.map((item) => "Sites"),
      labels: {
        rotation: 0,
        style: {
          fontFamily: 'Inter',
        },
      },
    },
    yAxis: {
      title: {
        text: "Alarm Count",
        style: {
          'font-family': 'Inter',
          
        }
      },
      // tickAmount: 4,
    },
    title: {
      text: null,
      style: {
        fontSize: "18px",
        fontFamily: "'Inter','Inter','Roboto Slab','Fira Sans'",
        fontWeight: "normal",
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"],
        },
      },
      enabled: false,
      position: {
        align: "left",
        verticalAlign: "top",
        x: 40,
        y: 40,
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointWidth: 20,
        groupPadding: 0.1,
      },
    },
    series: chartData,
  };
  return (
    <Paper
      elevation={0}
      className="total-metrics"
      style={{
        height: "100%",
        padding: "20px",
        borderRadius: "20px",
        margin: "10px 0 0 0",
      }}
    >
      <Grid container>
     {/* <Grid item lg={10} md={10}>
          <Typography style={{ fontSize: "18px" }}>
            Top 5 Sites By Alarms 
          </Typography>
          <Grid item lg={2} md={2}>
          <CgPerformance style={{ fontSize: "35px", color: "#000440"}} />
        </Grid>
          <Typography
            style={{
              fontSize: "15px",
              opacity: "0.7",
              margin: "5px 0 0 0",
              visibility: "hidden",
            }}
          >
            Plant Load Factor
          </Typography>
          <Typography
            style={{
              fontSize: "15px",
              opacity: "0.7",
              margin: "5px 0 0 0",
              visibility: "hidden",
            }}
          >
            Plant Load Factor
          </Typography>
        </Grid>  */}
        <Grid item lg={10} md={10}>
          <Typography style={{ fontSize: "17px" }}>
            Top 5 Sites By Alarms 
          </Typography>
          <Typography
            style={{
              fontSize: "15px",
              opacity: "0.7",
              margin: "35px 0 0 0",
              visibility: "hidden",
            }}
          >
            Plant Load Factor
          </Typography>
        </Grid>
        <Grid item lg={2} md={2}>
          <NotificationsOutlinedIcon style={{ fontSize: "35px", color: "#000440"}} />
        </Grid>
        {/* <Grid item lg={2} md={2}>
          <FaChartLine style={{ fontSize: "35px", color: "#000440" }} />
        </Grid> */}
        {EventStatistcs && EventStatistcs.length > 0 ? (
          <Grid item xs={12}>
            <HighchartsReact
              highcharts={Highcharts}
              options={columnChartOptions}
              containerProps={{
                style: {
                  width: isSmallScreen ? "auto" : "auto"
                }
              }}
            />
          </Grid>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
              marginLeft:"14%", 
              textAlign: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "20px",
              }}
            >
              There are no Alarms to Show
            </Typography>
          </div>
        )}
      </Grid>
    </Paper>
  );
};

export default Barchart;
