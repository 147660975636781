import axios from 'axios';



/*------------------------------------------- Site Details ----------------------------*/

export const fetchSiteDetails = async (id) => {

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITELIST}=${id}`);
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

/*---------------------------------------------- EquipmentId------------------------------*/

export const fetchEquipmentIdDetails = async (data) => {
  try {

    const response = await axios.get(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTLIST}/${data}`);
 
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


/*-----------------------------------------------parameter Api -------------------------------*/


export const fetchParameterListDetails = async (data) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_PARAMETERLIST}=${data}`);
  
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


/*----------------------------- Standard Params ------------------------*/

export const fetchStandardParams = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DATA_LOGGER}/standardparams`);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};


/*-----------------------------------Post Data config -------------------------------*/ 

export const PostdataConfig = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DATA_LOGGER}/addDataConfig`,obj );
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};


