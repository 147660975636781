import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Box, Paper, Modal, Typography, Switch, FormControlLabel, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './SiteStyle.css';
function createData(name, calories, fat, carbs, protein) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
    };
}
const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3, 9),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];
const headCells = [
    {
        id: 'name',
        label: 'Site Name',
    },
    {
        id: 'name',
        label: 'Site Id',
    },
    {
        id: 'calories',
        label: 'Status',
    },
    {
        id: 'fat',
        label: 'Today Energy (kWh)',
    },
    {
        id: 'carbs',
        label: 'Equipment Type',
    },
    {
        id: 'protein',
        label: 'Status',
    },
    {
        id: 'protein',
        label: 'Today Energy',
    },
    {
        id: 'protein',
        label: 'Total Energy',
    },
    {
        id: 'protein',
        label: 'Last Data Received ',
    },
];
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead stickyHeader>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
export default function CustomAlert(props) {
    const { tableValue, isModalOpen, toggleModal } = props;
    const [page, setPage] = React.useState(0);
    const [tableValues, setTableValue] = useState([]);
    const [selected, setSelected] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [tableDatas, setTableDatas] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    //------------------Enable nd Disabled---------------//
    const [filteredTableValue, setFilteredTableValue] = useState();
    useEffect(() => {
        setFilteredTableValue(tableValue)
    }, [tableValue])
    console.log(tableValue, isModalOpen, toggleModal, "tableValue, isModalOpen, toggleModal");
    const defaultLoadingStates = {
        equipmentName: true,
        errormessage: true,
        createdDate: true,
        smsEnabled: true,
        parameter: true,
        logic: true,
        value: true,
    };
    const [equipmentNameLoading, setEquipmentNameLoading] = useState(defaultLoadingStates.errorCode);
    const [errorMessageLoading, setErrorMessageLoading] = useState(defaultLoadingStates.errormessage);
    const [createdDateLoading, setcreatedDateLoading] = useState(defaultLoadingStates.createdDate);
    const [smsEnabledLoading, setsmsEnabledLoading] = useState(defaultLoadingStates.smsEnabled);
    const [parameterLoading, setparameterLoading] = useState(defaultLoadingStates.parameter);
    const [logicLoading, setLogicLoading] = useState(defaultLoadingStates.logic);
    const [valueLoading, setValueLoading] = useState(defaultLoadingStates.value);
    const handleToggle = () => {
        const filteredValues = tableValue?.filter((row) => {
            return (
                (equipmentNameLoading && row.errorCode) ||
                (errorMessageLoading && row.errormessage) ||
                (createdDateLoading && row.createdDate) ||
                (smsEnabledLoading && row.smsEnabled) ||
                (parameterLoading && row.parameter) ||
                (logicLoading && row.logic) ||
                (valueLoading && row.value)
            );
        });
        setFilteredTableValue(filteredValues);
    };
    useEffect(() => {
        resetToDefaultColumns();
    }, []);
    const resetToDefaultColumns = useCallback(() => {
        setEquipmentNameLoading(defaultLoadingStates.equipmentName);
        setErrorMessageLoading(defaultLoadingStates.errormessage);
        setcreatedDateLoading(defaultLoadingStates.createdDate);
        setsmsEnabledLoading(defaultLoadingStates.smsEnabled);
        setparameterLoading(defaultLoadingStates.parameter);
        setLogicLoading(defaultLoadingStates.logic);
        setValueLoading(defaultLoadingStates.value);
        handleToggle();
    }, [defaultLoadingStates]);
    /*-------------sorting--------------*/
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);
        sortData(tableValue, newOrder, property);
    };
    const sortData = (data, sortOrder, field) => {
        const sortedData = [...data];
        sortedData.sort((a, b) => {
            const aValue = a[field];
            const bValue = b[field];
            if (sortOrder === 'asc') {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else if (sortOrder === 'desc') {
                return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
            }
            return 0;
        });
        setFilteredTableValue(sortedData);
    };
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.alerts);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, (filteredTableValue?.length || 0) - page * rowsPerPage);
    function getStatusIcon(status) {
        switch (status) {
            case 'Active':
                return '#0bdf2a';
            case 'Offline':
                return 'grey';
            case 'Warning':
                return 'orange';
            case 'Down':
                return 'red';
            default:
                return 'black';
        }
    }
    console.log(tableValue, "tableValue")
    return (
        <div>
            <div>
                <div>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                mr: 0,
                                mb: 2,
                                mt: 2,
                                width: '100%',
                                height: "7vh",
                            },
                        }}
                    >
                    </Box>
                </div>
            </div>
            <div>
                <div>
                    {filteredTableValue && filteredTableValue.length > 0 && (
                        <Paper sx={{ width: '100%', mb: 2 }} elevation={0}>
                            <TableContainer sx={{ height: '50vh' }}>
                                <Table
                                    aria-labelledby="tableTitle"
                                    stickyHeader aria-label="sticky table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            {equipmentNameLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'equipmentName' ? order : 'desc'}
                                                    direction={orderBy === 'equipmentName' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('equipmentName')}
                                                >
                                                    Equipment Name
                                                </TableSortLabel>
                                            </TableCell>}
                                            {createdDateLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'errormessage' ? order : 'desc'}
                                                    direction={orderBy === 'errormessage' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('errormessage')}
                                                >Error Message
                                                </TableSortLabel></TableCell>}
                                            {createdDateLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'createdDate' ? order : 'desc'}
                                                    direction={orderBy === 'createdDate' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('createdDate')}
                                                >Event Time
                                                </TableSortLabel></TableCell>}
                                            {/* {parameterLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'parameter' ? order : 'desc'}
                                                   direction={orderBy === 'parameter' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('parameter')}
                                                >Conditional Logic</TableSortLabel></TableCell>}
                                           
                                                 {smsEnabledLoading && <TableCell align="center">
                                                <TableSortLabel
                                                     // active={orderBy === 'smsEnabled' ? order : 'desc'}
                                                   direction={orderBy === 'smsEnabled' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('smsEnabled')}
                                                >SMS Enabled
                                                </TableSortLabel></TableCell>} */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredTableValue !== undefined
                                            ? filteredTableValue
                                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => handleClick(event, row.siteName)}
                                                        tabIndex={-1}
                                                        key={row.siteName}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        {equipmentNameLoading && <TableCell align="center">{row.equipmentName}</TableCell>}
                                                        {errorMessageLoading && <TableCell align="center">{row.errormessage}</TableCell>}
                                                        {createdDateLoading && <TableCell align="center">{row.createdDate}</TableCell>}
                                                        {/* {parameterLoading && <TableCell align="center">{ (row.parameter && row.logic && row.value !== null)?`${row.parameter} - ${row.logic} - ${row.value}`:""  }</TableCell>}
                                                            {smsEnabledLoading && <TableCell align="center">{row.smsEnabled}</TableCell>} */}
                                                    </TableRow>
                                                )) :
                                            ""}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={filteredTableValue?.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </Paper>
                    )}
                </div>
            </div>
            <div>
                <Modal
                    open={isModalOpen}
                    onClose={toggleModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Paper elevation={0} className='total-equip' sx={{
                        width: '19%', maxWidth: '250px', height: 'max-content', overflow: "hidden",
                        backgroundColor: 'aliceblue', padding: '10px', position: 'relative', marginLeft: '77%', marginTop: "20.5%"
                    }}>
                        <div>
                            <Grid container spacing={10}>
                                <Grid item xs={6} md={14}>
                                    <Typography style={{ color: "rgb(18, 127, 216)", marginLeft: "80%", marginTop: "4%", cursor: "pointer" }} onClick={toggleModal}>
                                        <CloseOutlinedIcon />
                                    </Typography>
                                    {equipmentNameLoading ? (
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={equipmentNameLoading}
                                                    onChange={() => setEquipmentNameLoading(!equipmentNameLoading)}
                                                    color="primary"
                                                    disabled
                                                />
                                            }
                                            label="Equipment Name "
                                        />
                                    ) : (
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={<Switch color="primary" />}
                                            label="Equipment Name"
                                        />
                                    )}
                                    <FormControlLabel
                                        sx={{
                                            display: 'block',
                                        }}
                                        control={
                                            <Switch
                                                checked={errorMessageLoading}
                                                onChange={() => setErrorMessageLoading(!errorMessageLoading)}
                                                color="primary"
                                            />
                                        }
                                        label="Error Message"
                                    />
                                    <FormControlLabel
                                        sx={{
                                            display: 'block',
                                        }}
                                        control={
                                            <Switch
                                                checked={createdDateLoading}
                                                onChange={() => setcreatedDateLoading(!createdDateLoading)}
                                                color="primary"
                                            />
                                        }
                                        label="Event Time"
                                    />
                                    {/* <FormControlLabel
                                        sx={{
                                            display: 'block',
                                        }}
                                        control={
                                            <Switch
                                                checked={smsEnabledLoading}
                                                onChange={() => setsmsEnabledLoading(!smsEnabledLoading)}
                                                color="primary"
                                            />
                                        }
                                        label="Event Time"
                                    />
                                    <FormControlLabel
                                        sx={{
                                            display: 'block',
                                        }}
                                        control={
                                            <Switch
                                                checked={parameterLoading}
                                                onChange={() => setparameterLoading(!parameterLoading)}
                                                color="primary"
                                            />
                                        }
                                        label="Parameter Name"
                                    />
                                    <FormControlLabel
                                        sx={{
                                            display: 'block',
                                        }}
                                        control={
                                            <Switch
                                                checked={logicLoading}
                                                onChange={() => setLogicLoading(!logicLoading)}
                                                color="primary"
                                            />
                                        }
                                        label="Logic "
                                    />
                                    <FormControlLabel
                                        sx={{
                                            display: 'block',
                                        }}
                                        control={
                                            <Switch
                                                checked={valueLoading}
                                                onChange={() => setValueLoading(!valueLoading)}
                                                color="primary"
                                            />
                                        }
                                        label=" Value"
                                    /> */}
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </Modal>
            </div>
        </div>
    );
}