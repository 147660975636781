import axios from "axios";
/*---------------------------------------------- Get call------------------------------*/
let userId = sessionStorage.getItem("id");

export const fetchReportTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_REPORT_TABLE}/${userId}`
    );
    console.log(response, "response");
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
/*---------------------------------------------- Report Name list------------------------------*/
export const fetchReportnamelist = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_REPORTLIST}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
/*---------------------------------------------- Site Name list------------------------------*/
export const fetchSiteDetails = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITELIST}=${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
/*---------------------------------------------- update call------------------------------*/

export const putReportupdateEdit = async (data, id) => {
  try {
    console.log(data, "data");
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_REPORT_UPDATE}/${id}`,
      data
    );
    console.log(response, "response");
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
/*---------------------------------------------- Save ------------------------------*/

export const postreportdata = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_REPORT_SAVE}`,
      obj
    );
    return response;
  } catch (error) {
    console.error("Error posting data:", error.message);
    throw error;
  }
};

/*------------------------------------------------ Excel Download -------------------------------*/

export const DownloadReport = async (data) => {
  const { fromDate, toDate, siteId, domainId } = data;
  try {
    window.open(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_REPORT_DOWNLOAD}/siteid=${siteId}&fromDate=${fromDate}&todate=${toDate}&domainId=${domainId}`
    );
    // const response = await axios.get(`${process.env.REACT_APP_API_REPORT_PATH}/${process.env.REACT_APP_REPORT_DOWNLOAD}/siteid=${siteId}&fromDate=${fromDate}&todate=${toDate}`);
    // return response.data
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
