import React from 'react';
import './Loginpage.css';
// import Eira from '../../images/eiralogo.jpg';
import { Paper, Box, Typography, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
//-------------- Icons -------------//
import { AiOutlineMail } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io';
import webdyn  from '../../images/webdyn.png';
const ResetPass = () => {
    const navigate = useNavigate()
    // const [showPassword, setShowPassword] = React.useState(false);
    // const [userName, setEmail] = React.useState('')
    // const [Password, setPassword] = React.useState('')
    // const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleNavigate = () => {
        console.log("navigate");
        navigate('/changepass')
    }
    const handleBackToLogin= () => {
        console.log("navigate");
        navigate('/')
    }
    const handleChangeEmail = () => {
        console.log("navigate");
        navigate('/')
    }
    return (
        <div className='main-reset'>
            <Box
                sx={{
                    display: 'flex',
                    m: 1,
                    width: 470,
                    height: 500,
                    marginLeft: "32%",
                    marginTop: "5.5%",
                    backgroundColor: "white",
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
                }}s
            >
                <Paper elevation={20} style={{ borderRadius: "10px", }}>
                    <div className='main-reset-sub'>
                        <div className='eira-logo'>
                        <img src={ webdyn} alt='Webdyn' className='image' style={{width:"10vw",marginLeft:"5vw",marginTop:"-6vh"}}/> 
                      
                        </div>
                        <Typography style={{ marginLeft: "10%", fontSize: "26px", fontWeight: "550", marginTop: "10%", color: "#5b5e5c" }}>
                            We sent a code to your mail <AiOutlineMail />
                        </Typography>
                        <Typography style={{ marginLeft: "10%", color: "#6c756f" }}>
                            Enter the 6-digit verification code sent to a******@inspire.com. If you don't see email in your inbox, check your spam. <span style={{ color: "blue", cursor: "pointer" }} onClick={handleChangeEmail}>Change email</span>
                        </Typography>
                    </div>
                    <div style={{ width: "330px", marginLeft: "15px", marginTop: "-1%" }}>
                        <TextField id="outlined-basic"
                            placeholder="Enter Code"
                            variant="outlined" style={{ borderRadius: "9PX", hight: "10px", width: "37ch", marginLeft: "10%", marginTop: "5%" }} />
                        <Typography style={{ marginTop: "4%", marginLeft: "10%", color: "blue" }}>Resend Code</Typography>
                    </div>
                    <div >
                        <Button variant="contained" style={{ width: "44ch",  textTransform: "capitalize", backgroundColor: "#383bfc", marginLeft: "10%", marginTop: "4%" }}
                            onClick={handleNavigate}> Submit </Button>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: "32%", marginTop: "5%" }} onClick={handleBackToLogin}>
                        <IoIosArrowBack style={{ color: 'blue', marginRight: '5px' }} />
                        <Typography style={{ color: 'blue', fontSize: '20px' }}>Back to login</Typography>
                    </div>
                </Paper>
            </Box>
        </div>
    )
}
export default ResetPass