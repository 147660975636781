import React, { useEffect, useState, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";

// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const AreaChart = ({ tableData, WidgetName, chartName }) => {
  const [seriesData, setSeriesData] = useState([]);
  // console.log(seriesData, "seriesData");
  // console.log(tableData, "tableData");
  // console.log(chartName);

  useEffect(() => {
    let tabledata = tableData;
    if (
      tabledata.dgmeter &&
      tabledata.dgmeter.length > 1 &&
      chartName === "DG PV Grid Management"
    ) {
      for (let i = 1; i < tabledata.dgmeter.length; i++) {
        const currentTimestamp = new Date(tabledata.dgmeter[i].timestamp);
        const prevTimestamp = new Date(tabledata.dgmeter[i - 1].timestamp);
        const timeDiffMinutes =
          (currentTimestamp - prevTimestamp) / (1000 * 60);

        // Check for timestamp gaps greater than 5 minutes
        if (timeDiffMinutes > 5) {
          // Add missing timestamps every 5 minutes with todayEnergy set to 0
          for (let j = 1; j < Math.floor(timeDiffMinutes / 5); j++) {
            const missingTimestamp = new Date(
              prevTimestamp.getTime() + j * 5 * 60 * 1000
            );
            tabledata.dgmeter.splice(i + j - 1, 0, {
              todayEnergy: 0,
              timestamp: missingTimestamp.toISOString(),
            });
          }
        }
      }
      // Check if todayEnergy is null and set it to 0 for all meters
      ["dgmeter", "gridmeter", "solarmeter"].forEach((meter) => {
        if (tabledata[meter]) {
          tabledata[meter].forEach((entry) => {
            if (entry.todayEnergy === null) {
              entry.todayEnergy = 0;
            }
          });
        }
      });
    }
    const formattedData = Object?.keys(tabledata).map((meterName) => {
      return {
        name:
          meterName.charAt(0).toUpperCase() +
          meterName.slice(1).replace("meter", ""),
        data: tableData[meterName].map((entry) => ({
          x: new Date(entry.timestamp).getTime(),
          y: parseFloat(entry.todayEnergy),
        })),
      };
    });

    setSeriesData(formattedData);
    // console.log(formattedData, "formattedData");
  }, [tableData]);

  const minMaxTimeStamp = useMemo(() => {
    if (!tableData) return {};

    const timestamps = Object.values(tableData).flatMap((entries) =>
      entries.map((entry) => new Date(entry.timestamp).getTime())
    );

    const minTimestamp = Math.min(...timestamps) - 30 * 60 * 1000;
    const maxTimestamp = Math.max(...timestamps);

    return { minTimestamp, maxTimestamp };
  }, [tableData]);

  // console.log(minMaxTimeStamp, "minMaxTimeStamp");

  const columnChartOptions = {
    chart: {
      type: "area",
    },
    title: {
      text: WidgetName,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        hour: "%H:%M",
        day: "%Y-%m-%d",
      },
      min: minMaxTimeStamp.minTimestamp - 60 * 60 * 1000,
      max: minMaxTimeStamp.maxTimestamp + 60 * 60 * 1000,
      tickInterval: 60 * 60 * 1000,
      labels: {
        rotation: -45,
        formatter: function () {
          const localDate = new Date(this.value);
          const options = {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
            //   day: "numeric",
            // month: "short",
          };
          return new Intl.DateTimeFormat("en-US", options).format(localDate);
        },
      },
      title: {
        text: "Time (HH:MM)",
        style: {
          color: "#666666",
          // fontSize: "0.8em",
          fontFamily: "Inter",
        },
      },
    },

    yAxis: [
      {
        title: {
          text: "Energy Generation (kWh)",
        },
        style: {
          color: "#666666",
          fontSize: "0.8em",
          fontFamily: "Inter",
        },
      },
    ],
    plotOptions: {
      area: {
        pointStart: minMaxTimeStamp.minTimestamp,
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        const localDate = new Date(this.point.x);
        const options = {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
          day: "numeric",
          month: "short",
        };
        const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
          localDate
        );

        let tooltipContent = `<div class="custom-tooltip">
                                <b>Energy Generation</b>
                                <br/>
                                <b>${formattedDate}</b>`;

        this.series.chart.series.forEach((series) => {
          const seriesName = series.name;
          const pointIndex = series.xData.indexOf(this.point.x);
          if (pointIndex !== -1) {
            const yValue = series.yData[pointIndex];
            tooltipContent += `<p>${seriesName}: ${yValue} (kWh)</p>`;
          }
        });

        tooltipContent += `</div>`;
        return tooltipContent;
      },
    },
    series: seriesData?.map((series) => {
      if (series?.name?.includes("Grid") || series?.name?.includes("grid")) {
        series.name = "Grid ";
        series.color = "rgba(128, 128, 128, 0.7)"; // grey color
      } else if (
        series?.name?.includes("Solar") ||
        series?.name?.includes("solar")
      ) {
        series.name = "Solar ";
        series.color = "rgba(255, 165, 0, 0.5)"; // orange color
        // if (!tableData.dgmeter || tableData.dgmeter.length === 0) {
        //   series.lineColor = "#FE5000";
        // }
      } else if (series.name.includes("dg") || series.name.includes("Dg")) {
        series.name = "DG ";
        series.color = "rgba(30, 144, 255, 0.7)"; // blue color
      }
      return series;
    }),
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={columnChartOptions} />
    </div>
  );
};

export default AreaChart;
