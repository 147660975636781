

import React from "react";
import Chart from "react-apexcharts";
import { Paper, Typography, Grid, useMediaQuery, useTheme } from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import CellTowerIcon from "@mui/icons-material/CellTower";
import TimelineIcon from "@mui/icons-material/Timeline";
import { GiHeartBottle } from "react-icons/gi";

const SiteStatusChart = ({
  totalSites,
  activeSite,
  downSite,
  warningsite,
  offlinesite,
  options,
 series,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  options.legend.show = false;

  return (
    <Paper
      elevation={0}
      className="total-status"
      style={{
        padding: "18px",
        borderRadius: "20px",
        height:"100%",
        margin: "10px 0 0 0",
        width: isSmallScreen ? "100%" : "auto",
      }}
    >
      <Grid container spacing={1}>
        <Grid item lg={10} md={10}>
          <Typography style={{ fontSize: "17px" }}>Site Status</Typography>
        </Grid>
        <Grid item lg={2} md={2}>
          <GiHeartBottle
            style={{
              fontSize: "35px",
              // color: "#000440",
              margin: isSmallScreen ? "0 0 10px 0" : "0 0 0 30px",
            }}
          />
        </Grid>
      </Grid>
      {totalSites !== 0 ? (
        <div >
          <Chart
            options={options}
             series={series}
             type="donut"
            width="100%"
            height="100%"
          />
          <Grid container spacing={2}>
            <Grid item xs={6} md={5}>
              <div
                style={{
                  display: "flex",
                  // marginTop:"20%",
                  flexDirection: isSmallScreen ? "column" : "row",
                  justifyContent: "space-between",
                  margin: isSmallScreen ? "10px 0" : "0 0 0 0px",
                }}
              >
                <div>
                  {" "}
                  <VerifiedUserIcon
                    style={{
                      color: "#228B22",
                      verticalAlign: "bottom",
                    }}
                  />{" "}
                  Active{" "}
                </div>
                <div style={{ margin: isSmallScreen ? "5px 0 0 0" : "0 0 0 60px" }}>
                  {" "}
                  <Typography>{activeSite}</Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={5}>
              <div
                style={{
                  display: "flex",
                  // marginTop:"15%",
                  flexDirection: isSmallScreen ? "column" : "row",
                  justifyContent: "space-between",
                  margin: isSmallScreen ? "10px 0" : "0 0 0 40px",
                }}
              >
                <div>
                  <CellTowerIcon
                    style={{
                      color: "#B22222",
                      verticalAlign: "bottom",
                    }}
                  />{" "}
                  Down{" "}
                </div>
                <div>
                  {" "}
                  <Typography>{downSite}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <div style={{ margin: "10px 0 0 0 " }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={5}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {" "}
                    <TimelineIcon
                      style={{
                        color: "#FFB10E",
                        verticalAlign: "bottom",
                      }}
                    />{" "}
                    Warning
                  </div>
                  <div>
                    {" "}
                    <Typography>{warningsite}</Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} md={5}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    justifyContent: "space-between",
                    margin: isSmallScreen ? "10px 0" : "0 0 0 40px",
                  }}
                >
                  <div>
                    <SportsSoccerIcon
                      style={{
                        color: "#98A3B2",
                        verticalAlign: "bottom",
                      }}
                    />
                    {" "}
                    Offline{" "}
                  </div>
                  <div>
                    {" "}
                    <Typography>{offlinesite}</Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <div>
          {" "}
          <Typography
            style={{
              fontSize: "20px",
              display: "flex",
              justifyContent: "center",
              marginTop: "25%",
            }}
          >
            There are no Sites to Show
          </Typography>{" "}
        </div>
      )}
    </Paper>
  );
};

export default SiteStatusChart;

