import axios from 'axios';

export const SiteList = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_API_SITEOVERVIEW_SITE
      }=${sessionStorage.getItem('id')}`
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const CustomizeAlarms = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_SITELIST_CUSTOMALERTS}/${id}`
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const EnergymeterTables = async (id = 1198) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_ENERGYMETERTABLE}/${id}`
    );
    console.log(response, 'responsejfdosjids');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const SensorTables = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_SENSORTABLE}/${id}`
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const ScbTables = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_SCBTABLE}/${id}`
    );
    console.log(response, 'responsetesting for SCB Tables');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const ScbParams = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_SCB_PARAMETERS}/${id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    return error;
  }
};
