import React, { useEffect, useState } from "react";

/*---------------------- Import Cpmponents ----------------------*/
import PerformanceMetricsChart from "./SitesChart/PerformanceMetricsChart"; 
import SiteDataGrid from "./SitesData/SitesDataGrid";
import SiteChartGrid from "./SitesChart/SitesChartGrid";
import { SiteDetails } from "../../Api/DashboardApi";
import { useMediaQuery, useTheme, Typography, Grid } from "@mui/material";
import FadeLoader from "react-spinners/FadeLoader";


const Dashboard = () => {
  const [SiteEnergy, setSiteEnergy] = React.useState();
  const [SiteStatistics, setSiteStatistics] = React.useState();
  const [EventStatistcs, setEventStatistcs] = React.useState();
  const [SiteEnergyStatistics, setSiteEnergyStatistics] = React.useState();
  const [loading, setloading] = useState(true)

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await SiteDetails();
      setSiteEnergy(data?.SiteEnergyDetails);
      setSiteStatistics(data?.SiteStatistics);
      setEventStatistcs(data?.EventStatistcs);
      setSiteEnergyStatistics(data?.SiteEnergyStatistics);
      setloading(false)

    } catch (e) {
      console.error(e);
    }
  };


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (

    <div
      style={{
        height: '100%',
        width: '100%',
        margin: 0,
        backgroundColor: 'aliceblue',
      }}
    >
      {loading ?

        (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "20%" }}>
            <FadeLoader color="#000440" loading={true} />
          </div>
        ) : (

          <Grid container spacing={isSmallScreen ? 2 : 1}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{
                  fontSize: '1.2rem',
                  fontFamily: 'Inter, Ubuntu, Roboto Slab, Fira Sans',
                  fontWeight: 'bold',
                }}
              >
                Welcome {sessionStorage.getItem('token')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                direction={isSmallScreen ? 'column' : 'row'}
                spacing={isSmallScreen ? 2 : 1}
                style={{ padding: '15px' }}
              >
                <Grid item xs={12}>
                  <SiteDataGrid SiteStatistics={SiteStatistics} SiteEnergyStatistics={SiteEnergyStatistics} SiteEnergy={SiteEnergy} EventStatistcs={EventStatistcs} />
                </Grid>
                <Grid item xs={12}>
                  <SiteChartGrid SiteStatistics={SiteStatistics} SiteEnergyStatistics={SiteEnergyStatistics} SiteEnergy={SiteEnergy} EventStatistcs={EventStatistcs} />
                </Grid>

              </Grid>
            </Grid>
          </Grid>)}
    </div>
  );
};

export default Dashboard;