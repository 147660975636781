import React, { useState, useEffect } from "react";
import "./alarmsPage.css";
// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Menu, MenuItem, Switch } from "@mui/material";
import { Box, Grid, Paper, InputBase, IconButton, Button } from "@mui/material";
// MUI Icons
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import WestIcon from "@mui/icons-material/West";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CloseIcon from "@mui/icons-material/Close";
// Axios
import axios from "axios";
import { fetchAlertsTable } from "../../Api/AlertsComponentApi";

//Numeric extractor
function extractNumericPart(siteName) {
  if (typeof siteName === "string") {
    const match = siteName.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  } else {
    return 0;
  }
}

//Edit icon and its funcationality
const MatEdit = ({ index }) => {
  const handleEditClick = () => {
    console.log("works fine");
    // write the edit logic here please
  };

  return (
    <FormControlLabel
      control={
        <IconButton color="black" aria-label="Edit" onClick={handleEditClick}>
          <EditIcon style={{ color: "black" }} />
        </IconButton>
      }
    />
  );
};

//Table Headings
const columns = [
  { field: "id", headerName: "Id" },
  {
    field: "siteName",
    headerName: "Site Name",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "left",
    sortable: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      extractNumericPart(v1) - extractNumericPart(v2) || v1.localeCompare(v2),
  },
  {
    field: "equipments",
    headerName: "Equipments",
    flex: 0.5,
    minWidth: 130,
    headerAlign: "center",
    align: "left",
    sortable: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      extractNumericPart(v1) - extractNumericPart(v2) || v1.localeCompare(v2),
  },
  {
    field: "parameters",
    headerName: "Parameter",
    minWidth: 120,
    flex: 0.5,
    headerAlign: "center",
    align: "left",
    sortable: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      extractNumericPart(v1) - extractNumericPart(v2) || v1.localeCompare(v2),
  },
  {
    field: "logic",
    headerName: "Logic",
    minWidth: 100,
    flex: 0.5,
    headerAlign: "center",
    align: "center",
    sortable: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      extractNumericPart(v1) - extractNumericPart(v2) || v1.localeCompare(v2),
  },
  {
    field: "value",
    minWidth: 100,
    headerName: "Value",
    flex: 0.5,
    headerAlign: "center",
    align: "center",
    sortable: true,
    sortComparator: (v1, v2) => {
      if (typeof v1 === "string" && typeof v2 === "string") {
        return v1.localeCompare(v2);
      } else {
        // Use a default comparison for non-string values
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
      }
    },
  },
  {
    field: "email",
    headerName: "Email",
    minWidth: 130,
    flex: 1,
    headerAlign: "center",
    align: "left",
    sortable: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      extractNumericPart(v1) - extractNumericPart(v2) || v1.localeCompare(v2),
  },
  {
    field: "errorMessage",
    headerName: "Error Message",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "left",
    sortable: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      extractNumericPart(v1) - extractNumericPart(v2) || v1.localeCompare(v2),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.5,
    minWidth: 130,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      if (params.field === "status") {
        switch (params.value) {
          case "0":
            return <div>Inactive</div>;
          default:
            return <div>Active</div>;
        }
      }
    },
  },
  {
    field: "action",
    headerName: "Action",
    minWidth: 80,
    sortable: false,
    flex: 0.5,
    headerAlign: "center",
    align: "right",
    alignItem: "right",
    justifyContent: "right",
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <MatEdit index={params.row.id} />
        </div>
      );
    },
  },
];

//Main table function
function Alarms() {
  //Row data and column&row Filter states
  const [filteredData, setFilteredData] = useState([]);

  const [loading, setLoading] = useState([false]);
  const [Data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [columnVisibility, setColumnVisibility] = useState({
    siteName: true,
    equipments: true,
    parameters: true,
    logic: true,
    value: true,
    email: true,
    errorMessage: true,
    status: true,
    action: true,
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true); // Set loading to true before making the request

      const response = await fetchAlertsTable();

      setData(response);
      console.log(response);
      const filteredAlerts = response.map((alert) => ({
        id: alert.alertId,
        siteName: alert.siteName,
        equipments: alert.customerNaming,
        parameters: alert.parameter,
        logic: alert.logic,
        value: alert.value,
        email: alert.emailId,
        errorMessage: alert.errorMessage,
        status: alert.status,
      }));

      setFilteredData(filteredAlerts);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      // Set loading to false whether the request is successful or not
      setLoading(false);
    }
  };

  //API alerts data fetch call
  useEffect(() => {
    fetchData();
  }, []);

  //Handler functions
  const handleBack = () => {
    //write the backward page movement logic here
  };

  const handleNewAlert = () => {
    //write the New Alert creation logic here
  };

  const handleColumnFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleToggleColumnVisibility = (columnName, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (columnName !== "id" && columnName !== "action") {
      setColumnVisibility((prevVisibility) => ({
        ...prevVisibility,
        [columnName]: !prevVisibility[columnName],
      }));
    }
  };

  //Final filtered data's to table
  const dataRow =
    filteredData.length > 0
      ? filteredData.filter((item) =>
          item.siteName.toLowerCase().includes(searchValue.toLowerCase())
        )
      : [];

  const dataColumns = columns.filter(
    (column) => columnVisibility[column.field]
  );

  return (
    <>
      <div style={{ position: "relative", top: "1rem", left: "1rem" }}>
        <Box sx={{ flexGrow: 1 }} className="root">
          <Grid container spacing={1}>
            <Grid xs={12} style={{ width: "99%" }}>
              <div elevation={0} className="toolbar">
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box style={{ cursor: "pointer", width: "100px" }}>
                    <IconButton onClick={handleBack}>
                      <WestIcon />
                    </IconButton>
                    <span> Alarms</span>
                  </Box>
                  <Box>
                    <Paper
                      component="form"
                      elevation={0}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: 230,
                        height: 40,
                        marginLeft: 8,
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                    >
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                      >
                        <SearchIcon style={{ color: "dodgerblue" }} />
                      </IconButton>
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Site Name"
                        value={searchValue}
                        onChange={handleSearchChange}
                      />
                    </Paper>
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: 180,
                      height: 30,
                    }}
                    className="alarmButton"
                  >
                    <Button
                      style={{
                        width: "240px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                      onClick={handleNewAlert}
                      variant="contained"
                    >
                      Create New Alarms
                    </Button>
                  </div>
                  <Box
                    sx={{
                      marginLeft: 6,
                    }}
                  >
                    <IconButton onClick={handleColumnFilter}>
                      <SettingsOutlinedIcon style={{ color: "black" }} />
                    </IconButton>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                      onSelect={(event) => event.preventDefault()}
                      // PaperProps={{
                      //   elevation: 2,
                      //   sx: {
                      //     backgroundColor: "#98eeffea",
                      //   },
                      // }}
                    >
                      <MenuItem disableRipple={true}>
                        <IconButton
                          color="inherit"
                          onClick={handleCloseMenu}
                          disableRipple={true}
                          sx={{ marginLeft: "auto", height: 20 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </MenuItem>

                      {columns
                        .filter(
                          (column) =>
                            column.field !== "id" && column.field !== "action"
                        )
                        .map((column) => (
                          <MenuItem key={column.field}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={columnVisibility[column.field]}
                                  onMouseDown={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    handleToggleColumnVisibility(column.field);
                                  }}
                                  color="primary"
                                />
                              }
                              label={column.headerName}
                            />
                          </MenuItem>
                        ))}
                    </Menu>
                  </Box>
                </Box>
              </div>
            </Grid>
            <Grid
              xs={12}
              style={{
                position: "absolute",
                top: "10%",
                width: "100%",
                height: "100%",
              }}
            >
              <Box className="table">
                <DataGrid
                  rows={dataRow}
                  columns={dataColumns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                  }}
                  getEstimatedRowHeight={() => 500}
                  getRowHeight={() => "auto"}
                  pageSizeOptions={[10, 20, 30]}
                  disableRowSelectionOnClick
                  disableColumnMenu
                  disableColumnSelector
                  cellClassName={(params) => "disable-cursor"}
                  onCellClick={(params, event) => {
                    event.preventDefault();
                  }}
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      { py: "22px" },
                    "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .custom-render-cell:focus":
                      {
                        outline: "none",
                      },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

export default Alarms;
