
import React from "react";
import { Paper, Grid, Typography } from "@mui/material";
import { MdAccessAlarms } from "react-icons/md";

const Alarms = ({ SiteEnergy }) => {
  return (
    <Paper
      elevation={0}
      className="total-alert"
      sx={{
        width: "100%",
        height: "92%",
        padding: "23px",
        borderRadius: "13px",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={10} md={8} lg={10} >
          <Typography style={{ fontSize: "16px", opacity: "0.7" }}>
            Alarms
          </Typography>
          <Typography style={{ fontSize: "16px", opacity: "0.7",  visibility: "hidden", }}>
            Alarms
          </Typography>
        </Grid>
        <Grid item md={2} xs={2} lg={2}>
          <MdAccessAlarms
            style={{
              fontSize: "35px",
              color: "#000440",
              backgroundColor: "",
            }}
          />
        </Grid>
      </Grid>
      <div style={{ padding: "25px 0 0 0", border: ""}}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <Typography style={{ fontSize: "16px", opacity: "0.7" }}>
              Today
            </Typography>
            <Typography style={{ fontSize: "1.2rem" }}>
              {SiteEnergy?.todayEvents}
            </Typography>
          
          </Grid>
          <Grid item md={2}>
            <Typography style={{ fontSize: "16px", opacity: "0.7" }}>
              Total
            </Typography>
            <Typography style={{ fontSize: "1.2rem" }}>
              {SiteEnergy?.totalEvents}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default Alarms;

