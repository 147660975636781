import axios from "axios";

//------------- TableValues--------------//

export const fetchAlertsTable = async () => {
  try {
    // const response = await axios.get(`${process.env.REACT_APP_ALERTS_TABLE}`);
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_ALERTS_TABLE
      }/${sessionStorage.getItem("id")}`
    );
    //console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

/*--------------------- Edit Api Call ------------------*/
export const FetchEditValue = async (id) => {
  console.log(id, "editapi");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ALERTS_TABLE_EDIT}/findBySiteId/${id}`
    );

    // console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

/*-------------------- Update (PUT) Api Call ----------------------*/
export const updateEditCall = async (data, alertId) => {
  try {
    console.log(data, "data");
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ALERTS_TABLE_UPDATE}/${alertId}`,
      data
    );
    console.log(response, "response");
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

/*---------------------- POST Alert Save ---------------------*/
export const postAlertsSave = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ALERTS_TABLE_SAVE}`,
      obj
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error.message);
    throw error;
  }
};
