import React, { useState, useEffect } from 'react';
import './AnalyticStyle.css';
import moment from 'moment';
import CombinedChart from '../../Charts/CombinedChart';
/*---------------------------Mui Components ----------------------------------*/
import {
  Button,
  Stack,
  Box,
  Paper,
  TextField,
  Autocomplete,
  OutlinedInput,
  Typography,
  Divider,
  CardContent,
  Card,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Modal,
  Grid,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
/*---------------------------Mui Icons --------------------------*/
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
/*------------------------- External compoenents ---------------------------*/
import Apexchart from './ApexexChart';
import BarChart from '../../Charts/BarChart';
import AreaChart from '../../Charts/AreaChart';
import FadeLoader from 'react-spinners/FadeLoader';
import {
  fetchSiteDetails,
  fetchEquipmentIdDetails,
  fetchParameterListDetails,
} from '../../../Api/ExcelUploadAPi';
import {
  DataAnalystSubmit,
  SubmitDataAnalyst,
  equipmentApplyChanges,
  parameterComparisonApplyChanges,
  SpecificYeildChanges,
  SpecificYeildForDaily,
  ExpectedActual,
  DieselGenrator,
} from '../../../Api/DataAnalystApi';
const alertStyle = {
  color: 'white', // Text color
  '& .MuiSvgIcon-root': {
    color: 'white', // Icon color
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const optionTime = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday ', value: 'yesterday' },
  { label: 'Last 7 days', value: 'last 7 days' },
  { label: 'This Month', value: 'this month' },
  { label: 'Last Month', value: 'last month' },
  { label: 'Custom Range', value: 'custom' },
];
const chartType = [
  { label: 'Daily Generation', value: 'daily_generation' },
  { label: 'Energy Performance', value: 'energy_performance' },
  { label: 'Parameter Comparision', value: 'parameter_comparison' },
  { label: 'Specific Yield', value: 'specific yield' },
  { label: 'Actual Vs Expected', value: 'ActualVsExcepted' },
  { label: 'DG PV Grid Management', value: 'dgpv' },
];
const buttonStyles = {
  textTransform: 'capitalize',
  boxShadow: '4px 4px 17px rgba(0, 0, 0.50, 0.50)',
  fontSize: '1.1rem',
  border: 'none',
  width: '7vw',
};
const typoStyle = {
  textTransform: 'capitalize',
  opacity: '0.8',
};
const monitor_head_text = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginTop: '1%',
  marginLeft: '2%',
};
const filterAlign = {
  marginTop: '5.5%',
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '25vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const AddAnalyst = () => {
  const navigate = useNavigate();
  const [site, setSite] = React.useState('');
  const [equipment, setEquipmentName] = React.useState([]);
  const [equipmentParam, setEquipmentParamName] = React.useState([]);
  const [datalog, setDatalogValue] = React.useState([]);
  const [siteIdName, setSiteId] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [equipmentParameter, setParameterSite] = useState([]);
  const [chartName, setChartName] = useState('');
  const [timevalue, setTimeValue] = useState('today');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [range, setRange] = useState('');
  const [open, setOpen] = useState(false);
  const [widgetName, setWidgetName] = useState('');
  const [variantText, setVarient] = useState('');
  const [textValue, setTextValue] = useState('');
  const [barchartdata, setBarchartData] = useState([]);
  const [multiChartData, setMultichartData] = useState([]);
  const [tableData, setTableData] = useState();
  const [openToast, setOpenToast] = React.useState(false);
  const [selectAllEquipments, setSelectAllEquipments] = useState(false);
  const [selectAllEquipmentParam, setSelectAllEquipmentParam] = useState(false);
  const [chartFromDate, setChartFromDate] = useState('');
  const [chartToDate, setChartToDate] = useState('');
  const [apexChartType, setChartType] = useState('');
  const [customView, setCustomView] = useState();
  const [minToDate, setMinToDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  useEffect(() => {
    getSiteCall();
  }, []);
  const handleOpen = () => {
    if (chartName === '' && site === '') {
      setOpenToast(true);
      setVarient('error');
      setTextValue('Kindly Select Chart Type And Site Name');
    } else if (timevalue === 'custom') {
      setOpenToast(true);
      setOpen(false);
      setVarient('warning');
      setTextValue(
        'Custom Range Datas are Restricted to Store in Wizard Format'
      );
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };
  /*----------------------------- Paramater Get call ------------------------------*/
  const fetchParams = async (id) => {
    try {
      let data = await fetchParameterListDetails(id);
      setParameterSite(data);
    } catch (e) {
      console.error(e);
    }
  };
  const handleSite = (data, val) => {
    if (val === null) {
      setSite('');
    } else {
      setSite(val);
      setEquipmentName([]);
      fetchEquipmentDetails(val);
    }
  };
  const handleChange = (event) => {
    const { value } = event.target;
    if (value.includes('Select All')) {
      if (selectAllEquipments) {
        setSelectAllEquipments(false);
        setEquipmentName([]);
      } else {
        setSelectAllEquipments(true);
        setEquipmentName([...equipmentData]);
      }
    } else {
      const updatedEquipmentName = value.filter(
        (item) => item !== 'Select All'
      );
      setEquipmentName(updatedEquipmentName);
      setSelectAllEquipments(
        updatedEquipmentName.length === equipmentData.length
      );
    }
  };
  const handleChangeEquipment = (event) => {
    const { value } = event.target;
    if (value.includes('Select All')) {
      if (selectAllEquipmentParam) {
        setSelectAllEquipmentParam(false);
        setEquipmentParamName([]);
      } else {
        setSelectAllEquipmentParam(true);
        setEquipmentParamName([...equipmentParameter]);
      }
    } else {
      const updatedEquipmentParam = value.filter(
        (item) => item !== 'Select All'
      );
      setEquipmentParamName(updatedEquipmentParam);
      setSelectAllEquipmentParam(
        updatedEquipmentParam.length === equipmentParameter.length
      );
    }
  };
  /*--------------------------------Equipment Get  ----------------------------*/
  const fetchEquipmentDetails = async (event) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === event;
    });
    try {
      let data = await fetchEquipmentIdDetails(siteIdFilter[0]?.siteId);
      console.log(data, 'data');
      setEquipments(data);
      let equipmentName = data.map((datav) => {
        return datav.customernaming;
      });
      setEquipmentData(equipmentName);
      fetchParams(siteIdFilter[0]?.siteId);
    } catch (e) {
      console.error(e);
    }
  };
  const handleCancel = () => {
    navigate('/menu/analytics');
  };
  /*-------------------------- Site Get ---------------------*/
  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem('id');
      let data = await fetchSiteDetails(id);
      setSiteId([...new Set(data)]);
    } catch (e) {
      console.error(e);
    }
  };
  const handleChart = (data, event) => {
    setChartName(event);
    setSite('');
    setEquipmentName([]);
    setEquipmentParamName([]);
    setDatalogValue([]);
  };
  // console.log(site, 'site')
  const handleTimeChange = (data, event) => {
    if (event === null) {
      setTimeValue('');
    } else {
      setTimeValue(event.value);
    }
  };
  /*------------------------- Custom Range Date -------------------------------*/
  const handleChangeFromDate = (data, value) => {
    if (data !== null) {
      const formattedDateTime = data.format('YYYY-MM-DD');
      setFromDate(formattedDateTime);
    }
  };

  const handleChangeToDate = (data, value) => {
    if (data !== null) {
      const formattedDateTime = data.format('YYYY-MM-DD');
      setToDate(formattedDateTime);
    }
  };

  /*--------------------- Time range ---------------------*/
  useEffect(() => {
    let date = new Date();
    let fromDate = new Date();
    let responseDate = '';
    let toDate = '';
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };
    switch (timevalue) {
      case 'today':
        responseDate = moment(date).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        setFromDate(responseDate);
        setToDate(toDate);
        setRange('daily');
        break;
      case 'yesterday':
        date.setDate(date.getDate() - 1);
        responseDate = moment(date).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD  ');
        setFromDate(responseDate);
        setToDate(responseDate);
        setRange('daily');
        break;
      case 'last 7 days':
        fromDate.setDate(date.getDate() - 7);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD  ');
        setFromDate(responseDate);
        setToDate(toDate);
        setRange('custom');
        break;
      case 'this month':
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date);
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        setFromDate(formatDate(startDate));
        setToDate(formatDate(endDate));
        setRange('custom');
        break;
      case 'last month':
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        setFromDate(formatDate(firstDayOfLastMonth));
        setToDate(formatDate(lastDayOfLastMonth));
        setRange('custom');
        break;
      case 'custom':
        setRange('custom');
        break;
      default:
        break;
    }
  }, [timevalue]);

  /*------------------------------------------  Apply changes ----------------------*/
  const handleApplyChanges = async () => {
    setLoading(true);
    setDatalogValue([site, equipment, equipmentParam]);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });

    let filteredObjects = equipments?.filter((obj) =>
      equipment.includes(obj.customernaming)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentid;
    });
    let equipmentParameter = equipmentParam?.map((data) => {
      return data;
    });
    let fromDatehandle = fromDate;
    let toDateHandle = toDate;

    let dataModel = {
      SiteId: siteIdFilter[0]?.siteId,
      EquipmentIds: equipmentIds,
      Parameters: equipmentParameter,
      Range:
        chartName === 'Specific Yield' &&
        (timevalue === 'today' || timevalue === 'yesterday')
          ? 'daily'
          : fromDate === toDate
          ? 'daily'
          : range,
      timeperiod: timevalue,
      FromDate: fromDate,
      ToDate: toDate,
      GraphType: chartName,
    };

    // console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setChartFromDate(fromDate);
    setChartToDate(toDate);
    setChartType(chartName);
    if (chartName === 'Daily Generation') {
      delete dataModel.Parameters;
      delete dataModel.EquipmentIds;
      let responseData = await DataAnalystSubmit(dataModel);
      setLoading(false);
      setTableData(responseData);

      let seriesData = [];
      const irradiationSeries = {
        name: 'irradiation',
        data: [],
      };
      const todayEnergySeries = {
        name: 'todayenergy',
        data: [],
      };
      responseData.forEach((dataPoint) => {
        const timestamp = new Date(dataPoint.timestamp).getTime();
        irradiationSeries.data.push([timestamp, dataPoint.irradiation || 0]);
        todayEnergySeries.data.push([timestamp, dataPoint.todayEnergy || 0]);
      });
      seriesData.push(irradiationSeries);
      seriesData.push(todayEnergySeries);
      setMultichartData(seriesData);
      // console.log(seriesData, "seriesData");
    } else if (chartName === 'Energy Performance') {
      delete dataModel.Parameters;
      try {
        let responseData = await equipmentApplyChanges(dataModel);
        setLoading(false);
        let barChartData = responseData;
        setTableData(responseData);
        if (range === 'daily' || range === 'yesterday') {
          const equipmentMap = {};
          equipments.forEach((equipment) => {
            equipmentMap[equipment.equipmentid] = equipment.customernaming;
          });
          const seriesData = [];
          equipments.forEach((equipment) => {
            const series = {
              name: equipment.customernaming,
              data: [],
            };
            const equipmentData = responseData.filter(
              (data) => data.equipmentId === equipment.equipmentid
            );
            equipmentData.forEach((data) => {
              const timestamp = new Date(data.timestamp).getTime();
              const value = data.todayEnergy !== null ? data.todayEnergy : 0;
              series.data.push([timestamp, value]);
            });
            seriesData.push(series);
          });
          let finalArray = seriesData?.filter((value) => {
            return value.data.length > 0;
          });
          setMultichartData(finalArray);
        } else {
          const convertedData = barChartData.reduce((result, item) => {
            const found = result.find(
              (x) => x.name === item.equipmentId.toString()
            );
            if (found) {
              found.data.push(item.todayEnergy);
            } else {
              result.push({
                name: item.equipmentId.toString(),
                data: [item.todayEnergy],
              });
            }
            return result;
          }, []);
          let converteddata = convertedData;
          let dataMap = new Map(
            equipments.map((item) => [
              String(item.equipmentid),
              item.customernaming,
            ])
          );
          converteddata.forEach((item) => {
            if (dataMap.has(item.name)) {
              item.name = dataMap.get(item.name);
            }
          });
          setBarchartData(convertedData);
          setTableData(responseData);
        }
      } catch (e) {
        console.error(e);
      }
    } else if (chartName === 'Parameter Comparision') {
      try {
        let responseData = await parameterComparisonApplyChanges(dataModel);
        setLoading(false);
        setTableData(responseData);

        const equipmentMap = {};
        const dataFields = equipmentParameter;
        const seriesData = [];
        dataFields.forEach((field) => {
          responseData.forEach((data) => {
            let equipmentName = equipments?.filter((dataValue) => {
              return dataValue.equipmentid === Number(data.equipmentid);
            });
            const timestamp = new Date(data.timestamp).getTime();
            const value = data[field] !== null ? Number(data[field]) : 0;
            const seriesName = `${equipmentName[0]?.customernaming} - ${field}`;
            const existingSeries = seriesData.find(
              (series) => series.name === seriesName
            );
            if (existingSeries) {
              existingSeries.data.push([timestamp, value]);
            } else {
              const newSeries = {
                name: seriesName,
                data: [[timestamp, value]],
              };
              seriesData.push(newSeries);
              console.log(seriesData, 'seriesData');
              let filteredArray = seriesData.map((obj) => {
                obj.data = obj.data.map(([timestamp, value]) => [
                  timestamp,
                  isNaN(value) ? 0 : value,
                ]);
                return obj;
              });
              setMultichartData(filteredArray);
              // console.log(filteredArray, "filteredArray");
            }
          });
        });
      } catch (e) {
        console.error(e);
      }
    } else if (
      chartName === 'Specific Yield' &&
      (timevalue === 'today' || timevalue === 'yesterday')
    ) {
      delete dataModel.Parameters;
      delete dataModel.EquipmentIds;
      dataModel.range = 'daily';
      let responseData = await SpecificYeildForDaily(dataModel);
      setLoading(false);

      setTableData(responseData);
      setChartName(dataModel?.GraphType);
    } else if (chartName === 'Specific Yield') {
      delete dataModel.Parameters;
      delete dataModel.EquipmentIds;
      dataModel.range = 'custom';
      let responseData = await SpecificYeildChanges(dataModel);
      setLoading(false);

      setTableData(responseData);
      setChartName(dataModel?.GraphType);
    } else if (chartName === 'Actual Vs Expected') {
      delete dataModel.Parameters;
      delete dataModel.EquipmentIds;
      dataModel.Range = 'custom';
      // dataModel.GraphType="ActualVsExcepted"
      let responseData = await ExpectedActual(dataModel);
      setLoading(false);
      setTableData(responseData);
      setChartName(dataModel?.GraphType);
    } else if (chartName === 'DG PV Grid Management') {
      delete dataModel.Parameters;
      delete dataModel.EquipmentIds;
      // dataModel.Range="daily"

      let responseData = await DieselGenrator(dataModel);

      setLoading(false);
      // console.log(responseData, "responseData");
      setTableData(responseData);
      setChartName(dataModel?.GraphType);
    }
  };
  /*----------------------------- Submit Call ------------------------------*/
  const handleSubmit = async () => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });
    let filteredObjects = equipments?.filter((obj) =>
      equipment.includes(obj.customernaming)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentid;
    });
    let equipmentParameter = equipmentParam?.map((data) => {
      return data;
    });
    let obj = {
      SiteId: siteIdFilter[0]?.siteId,
      GraphType: chartName,
      UserId: sessionStorage.getItem('id'),
      EquipmentIds: [],
      Parameters: [],
      Range:
        chartName === 'Parameter Comparision'
          ? 'daily'
          : chartName === 'Specific Yield' &&
            (timevalue === 'today' || timevalue === 'yesterday')
          ? 'daily'
          : range,
      WidgetName: `${widgetName} - ${site}`,
      TimePeriod: timevalue,
      ActiveFlag: 1,
    };
    try {
      if (chartName === 'Daily Generation') {
      } else if (chartName === 'Energy Performance') {
        obj.EquipmentIds = equipmentIds;
      } else {
        obj.EquipmentIds = equipmentIds;
        obj.Parameters = equipmentParameter;
      }

      let responsedata = await SubmitDataAnalyst(obj);
      if (responsedata == 'CREATED') {
        setOpenToast(true);
        setOpen(false);
        setWidgetName('');
        setChartName('');
        setVarient('success');
        setDatalogValue([]);
        navigate('/menu/analytics?success=true');
        setTextValue('Your Wizard Saved Successfully');
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="">
      <div className="analytics">
        <div style={{ marginLeft: '2.5%' }}>
          <Typography style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            Add Widget
          </Typography>
        </div>
        <div style={{ marginRight: '0.5%' }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              style={buttonStyles}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={buttonStyles}
              onClick={handleOpen}
              disabled={timevalue === 'custom' ? true : false}
            >
              Save
            </Button>
          </Stack>
        </div>
      </div>
      <div
        className="monitoring-points"
        style={{ marginLeft: '2%', marginTop: '1%' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 1,
              width: '100%',
              height: '20vh',
            },
          }}
        >
          <Paper elevation={0}>
            <div className="">
              <div className="monitor-head">
                <Typography style={monitor_head_text}>
                  Monitoring Points
                </Typography>
              </div>
              <div className="monitor-text" style={{ marginLeft: '2%' }}>
                <Stack spacing={3} direction="row">
                  <div>
                    <Typography>Chart Type </Typography>
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={chartType.map((option) => option.label)}
                      onChange={(data, event) => handleChart(data, event)}
                      sx={{ width: '13vw' }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Chart Type ..." />
                      )}
                      classes={{ option: 'autocomplete' }}
                    />
                  </div>
                  <div>
                    <Typography style={typoStyle}>Select Site</Typography>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={site}
                      disabled={chartName !== '' ? false : true}
                      options={siteIdName.map((option) => option.siteName)}
                      onChange={(data, event) => handleSite(data, event)}
                      sx={{ width: '12vw' }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select  Site ..." />
                      )}
                      classes={{ option: 'autocomplete' }}
                    />
                  </div>
                  <div>
                    {/* <FormControl sx={{ width: 250 }}>
                      <Typography style={typoStyle}>Equipments</Typography>
                      <Select
                        id="demo-multiple-checkbox"
                        multiple
                        disabled={
                          chartName === "Daily Generation" ? true : false
                        }
                        size="small"
                        value={equipment}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => selected.join(",")}
                        placeholder="Select Equipments ..."
                      >
                        <MenuItem value="Select All">
                          <Checkbox
                            checked={equipment.length === equipmentData.length}
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                        {equipmentData.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className="autocomplete"
                          >
                            <Checkbox checked={equipment.includes(name)} />
                            <ListItemText
                              primary={name}
                              className="autocomplete"
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <FormControl sx={{ width: '250px' }}>
                      <Typography style={typoStyle}>Equipments</Typography>
                      <Select
                        id="demo-multiple-checkbox"
                        multiple
                        disabled={
                          chartName === 'Daily Generation' ||
                          chartName === 'Specific Yield' ||
                          chartName === 'Actual Vs Expected' ||
                          chartName === 'DG PV Grid Management'
                        }
                        size="small"
                        value={equipment}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => selected.join(', ')}
                        placeholder="Select Equipments ..."
                        className="custom-select" // Apply the custom CSS class
                      >
                        <MenuItem value="Select All" className="custom-option">
                          <Checkbox
                            checked={equipment.length === equipmentData.length}
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                        {equipmentData.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className="autocomplete custom-option"
                          >
                            <Checkbox checked={equipment.includes(name)} />
                            <ListItemText
                              primary={name}
                              className="autocomplete custom-option"
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl sx={{ width: 300 }}>
                      <Typography style={typoStyle}>
                        Equipment Measurement Parameter
                      </Typography>
                      <Select
                        id="demo-multiple-checkbox"
                        multiple
                        size="small"
                        disabled={
                          chartName === 'Parameter Comparision' ? false : true
                        }
                        value={equipmentParam}
                        onChange={handleChangeEquipment}
                        input={<OutlinedInput />}
                        renderValue={(selected) => selected.join(',')}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="Select All">
                          <Checkbox
                            checked={equipment.length === equipmentData.length}
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                        {equipmentParameter.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className="autocomplete"
                          >
                            <Checkbox
                              checked={equipmentParam.indexOf(name) > -1}
                            />
                            <ListItemText
                              primary={name}
                              className="autocomplete"
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    className="apply-changes"
                    style={{
                      marginTop: '1%',
                      marginRight: '0.5%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        textTransform: 'capitalize',
                        marginTop: '4%',
                        fontSize: '1.1rem',
                      }}
                      onClick={() => {
                        handleApplyChanges();
                      }}
                      disabled={
                        (chartName === 'Energy Performance' &&
                          site !== '' &&
                          equipment.length > 0) ||
                        (chartName === 'Parameter Comparision' &&
                          site !== '' &&
                          equipment.length > 0 &&
                          equipmentParam.length > 0) ||
                        ((chartName === 'Daily Generation' ||
                          chartName === 'Specific Yield' ||
                          chartName === 'Actual Vs Expected' ||
                          chartName === 'DG PV Grid Management') &&
                          site !== '')
                          ? false
                          : true
                      }
                    >
                      Apply changes
                    </Button>
                  </div>
                </Stack>
              </div>
            </div>
          </Paper>
        </Box>
      </div>
      <div className="monitoring-graph">
        <div style={{ width: '80%' }}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                width: '100%',
                height: '55vh',
              },
            }}
          >
            <Paper elevation={0}>
              <div style={{ width: '97%' }}>
                <div>
                  <Typography
                    style={{
                      fontSize: '1.2rem',
                      fontWeight: '600',
                      marginTop: '3%',
                      marginLeft: '5%',
                    }}
                  >
                    Preview
                  </Typography>
                </div>
                <div>
                  <div className="graph">
                    <Paper elevation={0} style={{ backgroundColor: 'white' }}>
                      <div className="allignText">
                        <Card
                          sx={{
                            width: '100%',
                            height: '43vh',
                            overflowY: 'scroll',
                          }}
                        >
                          {loading ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '15%',
                              }}
                            >
                              <FadeLoader color="#000440" loading={true} />
                            </div>
                          ) : datalog?.length > 0 ? (
                            <CardContent sx={{ width: '100%', height: '5%' }}>
                              {chartName === 'Parameter Comparision' ? (
                                <Apexchart
                                  chartName={chartName}
                                  tableData={tableData}
                                  multiChartData={multiChartData}
                                  name={'paramter'}
                                  timeValue={timevalue}
                                />
                              ) : chartName === 'Specific Yield' ||
                                chartName === 'Actual Vs Expected' ? (
                                <CombinedChart
                                  tableData={tableData}
                                  customView={customView}
                                  chartName={`${chartName}`}
                                  WidgetName={`${chartName}`}
                                />
                              ) : chartName === 'DG PV Grid Management' &&
                                chartFromDate === chartToDate ? (
                                <AreaChart
                                  tableData={tableData}
                                  WidgetName={`${chartName}`}
                                  chartName={`${chartName}`}
                                />
                              ) : chartFromDate === chartToDate ? (
                                <Apexchart
                                  chartName={chartName}
                                  tableData={tableData}
                                  multiChartData={multiChartData}
                                  key="1"
                                  name="chartData"
                                />
                              ) : (
                                <BarChart
                                  tableData={tableData}
                                  chartName={chartName}
                                  barchartdata={barchartdata}
                                  WidgetName={`${chartName}`}
                                />
                              )}
                            </CardContent>
                          ) : (
                            <CardContent
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                              }}
                            >
                              <Typography style={{ fontSize: '1.5rem' }}>
                                Graph will Preview here
                              </Typography>
                            </CardContent>
                          )}
                        </Card>
                      </div>
                    </Paper>
                  </div>
                </div>
              </div>
            </Paper>
          </Box>
        </div>
        <div style={{ width: '20%' }}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                width: '100%',
                height: '55vh',
              },
            }}
          >
            <Paper elevation={0}>
              <div>
                <div style={{}}>
                  <Typography
                    style={{
                      textAlign: 'center',
                      fontSize: '1.2rem',
                      fontWeight: '600',
                      marginTop: '5%',
                    }}
                  >
                    Control Panel
                  </Typography>
                </div>
                <Divider style={{ marginTop: '2%', fontWeight: 'bold' }} />
                <div
                  style={{
                    marginLeft: '7%',
                    marginRight: '5%',
                    marginTop: '5%',
                  }}
                >
                  <div style={filterAlign}>
                    <Typography>Custom Range </Typography>
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      defaultValue="Today"
                      options={optionTime}
                      onChange={(data, value) => handleTimeChange(data, value)}
                      sx={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select ..." />
                      )}
                      classes={{ option: 'autocomplete' }}
                    />
                  </div>
                  <div style={filterAlign}>
                    <Typography>Date Range </Typography>
                    <Stack direction="row" spacing={1.5}>
                      <div>
                        <Typography> From</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="From"
                            disabled={timevalue !== 'custom' ? true : false}
                            onChange={(data, value) =>
                              handleChangeFromDate(data, value)
                            }
                          />
                        </LocalizationProvider>
                      </div>
                      <div>
                        <Typography> To</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="To"
                            disabled={timevalue !== 'custom' ? true : false}
                            disableFuture
                            shouldDisableDate={(date) =>
                              dayjs(date).isBefore(dayjs(fromDate), 'day')
                            }
                            onChange={(data, value) =>
                              handleChangeToDate(data, value)
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    </Stack>
                  </div>
                </div>
              </div>
            </Paper>
          </Box>
        </div>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    WidgetName :
                  </Typography>
                  <TextField
                    id="standard-basic"
                    placeholder=" Enter your widgetName"
                    variant="standard"
                    onChange={(e) => {
                      setWidgetName(e.target.value);
                    }}
                    required
                    style={{ textTransform: 'capitalize' }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Chart Type :
                  </Typography>
                  <Typography>{chartName} </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Custom Range :
                  </Typography>
                  <Typography style={{ textTransform: 'capitalize' }}>
                    {timevalue}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                marginTop: '3%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                onClick={handleSubmit}
                style={{ textTransform: 'capitalize' }}
                disabled={widgetName !== '' ? false : true}
              >
                Submit
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        <div>
          <Snackbar
            open={openToast}
            autoHideDuration={3000}
            onClose={handleCloseToast}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseToast}
              severity={variantText}
              sx={{ width: '100%', ...alertStyle }}
            >
              {textValue}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
};
export default AddAnalyst;
